import React, { useState, useContext, useRef, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import AppContext from '../context/AppContext';
import useAuthentication from '../hooks/backend/useAuth';

import ModalComponent from './ModalComponent';
import useCompanyPreferences from '../hooks/backend/useCompanyPreferences';

import '../styles/components/lateralMenu.scss'


function LateralMenu({ closeLateralNavbar }) {

    // Context
    const { role } = useContext(AppContext);

    // ========== References ============ //
    const modalRef = useRef();

    // ========== Routing ============ //
    const navigate = useNavigate();


    // ========== Custom hooks ============ //
    const { logoutRequest } = useAuthentication();
    const { getCompanyCategoryPreferencesRequest } = useCompanyPreferences();

    // ========== States ============ //
    const [userInformation, setUserInformation] = useState(JSON.parse(localStorage.getItem('userInformation')));
    const [categoryList, setCategoryList] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ======== Functions ========= //
    useEffect(() => {
        async function fetchData() {
            try {
                const categoryPreferencesResponse = await getCompanyCategoryPreferencesRequest(userInformation.user.uuid);
                setCategoryList(categoryPreferencesResponse);
            } catch (error) {
                console.error(error.message);
            }
        }

        fetchData();

    }, []);

    const handleLogOut = async () => {
        await logoutRequest(userInformation.user.uuid)
            .then(window.location.href = '/login')
            .catch(() => {
                openModal();
            });
    }

    const handleSearchNewApplicants = (event) => {
        // event.preventDefault();
        if (!categoryList.length) {
            setModalInformation({
                title: 'Actualiza tu perfil',
                content: 'Sólo podrás buscar posibles candidatos si buscas directamente en un empleo publicado o actualizas tu perfil y añades el sector en el que está tu compañía',
                button3Content: 'Ir a mis empleos',
                button3Redirect: '/my-jobs',
                button2Content: 'Actualizar perfil',
                button2Redirect: '/my-profile',
            });
            openModal()
        } else {
            setShowDropdown(!showDropdown);
        }
    };

    const handleCategoryChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
        window.location.href = `/talent-availability/${selectedCategory}`;
    };

    // Change the list in the navbar if the user is logged
    const renderList = () => {
        if (role === 'company') {
            return (
                <ul>
                    <li>
                        <a href="/my-jobs">Mis empleos</a>
                    </li>
                    <li>
                        <Dropdown show={showDropdown && categoryList.length > 0} onToggle={handleSearchNewApplicants}>
                            <Dropdown.Toggle className='dropdown-title-lateral-menu mt4' >
                                Buscar aplicantes
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dropdown-menu-header' >
                                {categoryList.map((category, index) => (
                                    <Dropdown.Item key={index} eventKey={category.category.uuid} onClick={() => handleCategoryChange(category.category.uuid)}>
                                        <p>{category.category.name}</p>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            );
        } else {
            return (
                <ul>
                    <li>
                        <a href="/jobs">Empleos</a>
                    </li>
                    <li>
                        <a href="/my-applications">Mis aplicaciones</a>
                    </li>
                </ul>
            );
        }
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null
    }

    function handleRedirectToButtonThree() {
        window.location.href = modalInformation.button3Redirect ? modalInformation.button3Redirect : null
    }

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton3={modalInformation.button3Content}
                redirectButton3={modalInformation.button3Redirect && handleRedirectToButtonThree}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <div className="mobile-menu">
                <i
                    className="fa-sharp fa-solid fa-xmark fa-2xl lateral-navbar-go-out"
                    onClick={() => closeLateralNavbar(false)}
                />
                <ul className='lateral-navbar'>
                    <li className="lateral-navbar-img">
                        <img src={userInformation.user.profileImageUrl} alt="profile" />
                    </li>
                    <li className="lateral-navbar-username">
                        <h4>
                            {
                                userInformation.role === 'company' ?
                                    userInformation.user.name
                                    :
                                    `${userInformation.user.firstName} ${userInformation.user.firstName && userInformation.user.middleName} ${userInformation.user.lastName}`
                            }
                        </h4>
                    </li>
                    <li className="lateral-navbar-view-profile">
                        <a href="/my-profile">
                            View profile
                        </a>
                    </li>
                </ul>

                <div className="mobile-menu-list">
                    {renderList()}
                </div>

                <ul>
                    <li className="mobile-menu-sign-out">
                        <a onClick={handleLogOut}>
                            Cerrar sesión
                        </a>
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}

export default LateralMenu