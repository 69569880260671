import ReactGA from "react-ga";

const useAnalytics = (category = 'web') => {

    const eventTracker = ({ action, label, value }) => {
        const eventToSave = { category };

        if (action) eventToSave.action = action;
        if (label) eventToSave.label = label;
        if (value) eventToSave.value = value;

        try {
            ReactGA.event(eventToSave);
        } catch (error) {
            console.error(error)
        }
    };

    return eventTracker;

};

export default useAnalytics;