import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useEmailsRequests from '../../hooks/backend/useEmails';

// Components
import ModalComponent from '../ModalComponent';

const JobApplication = forwardRef((props, ref) => {

    const {
        handleClose,
        title,
        jobUuid,
        applicantName,
        applicantUuid,
        applicationId
    } = props;

    // ========== Custom hooks ============
    const { createApplicantRequest, updateStatusRequest } = useApplicantsRequests();
    const { sendContactedByCompanyRequest } = useEmailsRequests();

    // ========== States ============
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState('');
    const [request, setRequest] = useState({});

    // ========== References ============
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));
    const form = useRef(null);
    const modalRef = useRef();

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor recarga la página',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ========== Backend request ============
    // Create an application
    useEffect(() => {

        if (request.jobUuid) {
            async function jobApplicationFunction() {
                await createApplicantRequest(request)
                    .then(() => {
                        handleClose();
                        setModalInformation({
                            title: 'Aplicación creada correctamente',
                            content: `Tu aplicación al empleo <strong>${title}</strong> ha sido creada correctamente. ¡Puedes ver el estado de tu aplicación en la sección "Mis aplicaciones"!`,
                            button1Content: 'Volver',
                            button1Redirect: `/jobs/${jobUuid}`,
                            button2Content: 'Ver mis aplicaciones',
                            button2Redirect: `/my-applications`,
                        });
                        openModal();
                    })
                    .catch(error => {
                        console.error(error)
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal();
                    })

            }
            jobApplicationFunction()
        } else {
            return;
        }
    }, [request]);


    // ========== Functions ============
    const handleSubmitApplication = (event) => {
        event.preventDefault();
        // Get user uuid
        const userInformation = JSON.parse(localStorage.getItem('userInformation'));
        const userUuid = userInformation.user.uuid;

        // Build request object
        // User apply for a job job
        let request = { jobUuid, userUuid }

        if (description) request.description = description;

        // Company ask to a user
        if (applicantUuid) {
            request.userUuid = applicantUuid;
            request.status = 'requested_by_company';
        };

        // Send request
        setRequest(request);
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        if ((!/[\])}>[<{(]/.test(inputValue))) {
            setDescription(inputValue);
        }
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
    }

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {
                        applicantName
                            ?
                            <Modal.Title>Solicitud de contacto</Modal.Title>
                            :
                            <Modal.Title>Aplicar a {title}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="aboutYourself"
                        ref={form}
                    >
                        {
                            applicantName
                                ?
                                <Form.Label>
                                    <p>
                                        Al hacer clic en el botón 'contactar', le estarías manifestando tu interés a <strong>{applicantName}</strong> en trabajar contigo en el puesto de <strong>{title}</strong>. Si <strong>{applicantName}</strong> acepta, te proporcionaremos sus datos de contacto para que puedan finalizar los detalles de la entrevista.
                                    </p>
                                </Form.Label>
                                :
                                <Form.Label>Cuéntale a la empresa por qué estás interesado en este puesto</Form.Label>
                        }
                        <Form.Control
                            as="textarea"
                            name="description"
                            placeholder={applicantName && '¡Déjale saber por qué lo quieres contratar acá!'}
                            rows={3}
                            onChange={handleChange}
                            value={description}
                            autoFocus />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-cancel' variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={(event) => handleSubmitApplication(event)}
                    >
                        {
                            applicantName
                                ?
                                'Contactar'
                                :
                                'Aplicar'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    )
})

export default JobApplication