import api from '../../libs/API'
import useAuthentication from './useAuth';

const useHistoryRequests = () => {

    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Get user job experience
    const getUserJobsHistoryRequest = async (userUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.get(`/history/user/${userUuid}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getUserJobsHistoryRequest(userUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Validate that an user and a company have worked together
    const validateUserCompanyHistoryRequest = async (userUuid, companyUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        const params = { userUuid, companyUuid, limit: 1 }

        try {
            const response = await api.get(`/history/`, { headers, params });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await validateUserCompanyHistoryRequest(userUuid, companyUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create a job experience
    const createJobHistoryRecord = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/history', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createJobHistoryRecord(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create a job experience
    const createBatchJobHistoryRecords = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/history/batch', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createBatchJobHistoryRecords(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create a job experience
    const updateJobHistoryRecord = async (historyId, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/history/${historyId}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateJobHistoryRecord(historyId, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    return { getUserJobsHistoryRequest, validateUserCompanyHistoryRequest, createJobHistoryRecord, createBatchJobHistoryRecords, updateJobHistoryRecord }

};

export default useHistoryRequests;