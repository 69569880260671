import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Custom
import useJobs from '../../hooks/backend/useJobs';

const DisableJobModal = forwardRef((props, ref) => {

    // ========== Props ============
    const {
        handleClose,
        jobInfo
    } = props;

    // ========== States ============
    const [show, setShow] = useState(false);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [request, setRequest] = useState({});

    // ========== References ============ //
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));

    // ========== Custom hooks ============ // 
    const { updateJobRequest } = useJobs();

    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        if (request.status) {
            async function updateJobStatusFunction() {
                // Make request to backend
                await updateJobRequest(jobInfo.uuid, request)
                    .then(window.location.reload())
                    .catch(console.error())
            }
            updateJobStatusFunction()
        } else {
            return;
        }

    }, [request]);


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Deshablitar empleo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        jobInfo && jobInfo.title
                            ?
                            <p>
                               El empleo <strong>{jobInfo.title}</strong> {jobInfo.applicantsNumber === 0 ? 'no tiene aplicaciones' : jobInfo.applicantsNumber === 1 ? `tiene ${jobInfo.applicantsNumber} aplicación` : `tiene ${jobInfo.applicantsNumber} aplicaciones`}.
                                ¿Estás seguro que deseas desahibilitarlo y dejar de recibir aplicaciones para este puesto?
                            </p>
                            :
                            ''
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-cancel' variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={() => setRequest({ status: 'disabled' })}
                    >
                        Deshabilitar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
})

export default DisableJobModal