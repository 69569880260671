import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

// ----- Components ----- //
import ApplicationFilters from '../../components/applications/ApplicationFilters';
import UpdateApplicationStatusModal from '../../components/applications/UpdateApplicationStatus';
import ApplicationCart from '../../components/applications/ApplicationCart';
import PaginationComponent from '../../components/PaginationComponent';
import LateralFilters from '../../components/LateralFilters';
import FilterButtonComponent from '../../components/FilterButtonComponent';
import LoadingComponent from '../../components/LoadingComponent';
import ModalComponent from '../../components/ModalComponent';

// ----- Custom ----- //
import useApplicantsRequests from '../../hooks/backend/useApplicants';

import '../../styles/pages/my-applications.scss';

// ----- Constants ----- //
const INITIAL_PAGE = 1;
const APPLICANTS_PER_PAGE = 10;

function ApplicantsList() {

    // ========== Routing and params ============
    const { jobUuid } = useParams();
    const navigate = useNavigate();

    // ========== States ============
    const [toggleLateralFilters, setToggleLateralFilters] = useState(false);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [applicantsList, setApplicantsList] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // ----- Filter states ----- //
    const [searchValue, setSearchValue] = useState('');
    const [sortByOldest, setSortByOldest] = useState(1);
    const [statusValue, setStatusValue] = useState('Todas');

    // Pagination
    const [page, setPage] = useState(INITIAL_PAGE);
    const [totalApplications, setTotalApplications] = useState(0);

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ========== References ============
    const modalRef = useRef();
    const updateStatusModalRef = useRef();

    // ----- Name filter logic ----- //
    let searchedApplicants = []; // Variable to save matches
    if (!searchValue.length >= 1) {
        searchedApplicants = applicantsList;
    } else {
        searchedApplicants = applicantsList.filter(applicant => {
            const fullName = `${applicant.user.firstName} ${applicant.user.middleName ? applicant.user.middleName : ""} ${applicant.user.lastName}`
            const applicantText = fullName.toLowerCase();
            const searchText = searchValue.toLowerCase();
            return applicantText.includes(searchText);
        });
    }

    // ========== Custom ============
    const { getApplicantsByJobRequest } = useApplicantsRequests();

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function applicationsFunction() {

            // Build filters object
            let filters = {
                limit: APPLICANTS_PER_PAGE,
                page,
                oldest: sortByOldest
            }

            if (statusValue) {
                if (statusValue === 'Todas') {
                    delete filters.status;
                } else {
                    filters.status = statusValue;
                }
            };

            await getApplicantsByJobRequest(filters, jobUuid)
                .then(response => {
                    setApplicantsList(response.applications);
                    setTotalApplications(response.totalApplications)
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
                    openModal();
                })
        };
        applicationsFunction();

    }, [page, sortByOldest, statusValue, isInitialMount]);

    // ========== Functions ==========  //

    // Pagination handler
    const handlePageChange = (currentPage) => {
        setPage(currentPage);
    };

    const handleUpdateApplicationStatus = ({ event, status, applicationId }) => {
        event.preventDefault();
        const application = applicantsList.find(application => application.id === applicationId);
        setSelectedApplication({
            id: event.target.id,
            status,
            application
        });
        openUpdateStatusModal();
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();
    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null
    };

    const openUpdateStatusModal = () => updateStatusModalRef.current.handleShow();
    const closeUpdateStatusModal = () => updateStatusModalRef.current.handleClose();

    return (
        <Fragment>

            {isLoading && <LoadingComponent />}

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <UpdateApplicationStatusModal
                ref={updateStatusModalRef}
                handleClose={closeUpdateStatusModal}
                selectedApplication={selectedApplication}
                jobUuid={jobUuid}
            />

            <section id="applicant-grid-area" className="py40">
                <div className="container">
                    <div className="row">
                        {/* <!-- Applicant Filters --> */}
                        <div className="applicant-filters col-lg-3 col-md-12 col-sm-12 col-12">
                            <ApplicationFilters
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                sortByOldest={sortByOldest}
                                setSortByOldest={setSortByOldest}
                                statusValue={statusValue}
                                setStatusValue={setStatusValue}
                                statusRequest={{ jobUuid }}
                            />
                        </div>

                        {/* <!-- Applicants information --> */}
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                {/* <div className="row"> */}
                                <div className="row my-applicants-header">
                                    <h4 className="col-lg-6 col-md-6 col-sm-5 col-5">Aplicantes</h4>
                                    <div className="col-lg-6 col-md-6 col-sm-7 col-7 d-flex justify-content-end">
                                        <button
                                            className="btn btn-theme btn-search-talents center-section"
                                            onClick={event => {
                                                event.preventDefault();
                                                navigate(`/talent-availability/${applicantsList[0].job?.job_category[0].categoryUuid}`)
                                            }}
                                        >
                                            <i style={{ marginRight: '8px' }} className="fas fa-solid fa-plus"></i>
                                            Buscar
                                        </button>
                                    </div>
                                </div>
                                {
                                    applicantsList.length !== 0 &&
                                    searchedApplicants.map(application => {
                                        const fullName = `${application.user.firstName} ${application.user.middleName ? application.user.middleName : ""} ${application.user.lastName}`
                                        return (
                                            <div
                                                key={application.id}
                                                className="col-lg-6 col-md-6 col-sm-6 col-6"
                                            >
                                                <ApplicationCart
                                                    applicationId={application.id}
                                                    applicantUuid={application.user.uuid}
                                                    image={application.user.profile_image_url}
                                                    status={application.status}
                                                    date={application.updatedAt}
                                                    title={fullName}
                                                    description={application.description}
                                                    type={'jobApplicants'}
                                                    handleUpdateApplicationStatus={handleUpdateApplicationStatus}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {/* </div> */}
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <PaginationComponent
                                totalPages={Math.ceil(totalApplications / APPLICANTS_PER_PAGE)}
                                currentPage={page}
                                handlePageChange={handlePageChange}
                            />
                        </div>

                        {
                            toggleLateralFilters ?
                                <LateralFilters
                                    type={'jobApplicants'}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    sortByOldest={sortByOldest}
                                    setSortByOldest={setSortByOldest}
                                    statusValue={statusValue}
                                    setStatusValue={setStatusValue}
                                    setToggleLateralFilters={setToggleLateralFilters}
                                    statusRequest={{ jobUuid }}
                                />
                                :
                                ''
                        }

                        <FilterButtonComponent
                            setToggleLateralFilters={setToggleLateralFilters}
                        />

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ApplicantsList