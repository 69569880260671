import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';

import TooltipComponent from '../components/TooltipComponent';
import '../styles/pages/contact-us.scss'
import useEmailsRequests from '../hooks/backend/useEmails';

const validateInput = (type, value) => {
  switch (type) {
    case 'text':
      return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
      case 'message-text':
      return (!/[^A-Za-zÀ-ÿ\s.,\d]+/.test(value));
    case 'email':
      return (!/[\])}>[<{(]/.test(value));
    default:
      break;
  }
};
function ContactUs() {

  //States
  const form = useRef(null);

  // Input content
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Tooltips
  const [TooltipContactName, setTooltipContactName] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [TooltipContactEmail, setTooltipContactEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [TooltipContactMessage, setTooltipContactMessage] = useState({ status: false, content: 'Este campo no debe estar vacío' });

  const { sendEmailToSupportRequest } = useEmailsRequests();
  //Functions

  //------- Validations -----------------
  const formValidation = () => {

    let flag = true;

    // First name
    if (!name && name === '') {
      setTooltipContactName({ ...TooltipContactName, status: true });
      flag = false;
    };

    // Email
    if (!email && email === '') {
      setTooltipContactEmail({ ...TooltipContactEmail, status: true });
      flag = false;
    };

    // // Message  
    if (!message && message === '') {
      setTooltipContactMessage({ ...TooltipContactMessage, status: true });
      flag = false;
    };

    return flag;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formValidationFlag = formValidation();

    if (formValidationFlag) {
      const data = {
        name: name,
        sent_email: email,
        message: message
      };
      sendEmailToSupportRequest(data);
    }
  }

  const handleChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'contact-name':
        setTooltipContactName({ ...TooltipContactName, status: false });
        validateInput('text', inputValue) && setName(inputValue);
      break;
      case 'contact-email':
        setTooltipContactEmail({ ...TooltipContactEmail, status: false });
        validateInput('email', inputValue) && setEmail(inputValue);
      break;
      case 'contact-message':
        setTooltipContactMessage({ ...TooltipContactMessage, status: false });
        validateInput('message-text', inputValue) && setMessage(inputValue);
      break;
      default:
      break;
    }
  };
  return (
    <>
    <div className='contact-us-container'>
      <section className="contact-us">
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <h2>Ponte en contacto con nosotros</h2>
          <p>Estamos listos para resolver tus dudas e inquietudes.</p>
          <div className="footer-contact-area footer-list pt20">
            <ul>
              <li>
                <i className="fa-solid fa-location-dot" /> Manizales, Caldas, Colombia
              </li>
              <li>
                <i className="fa-solid fa-phone" /><a href="tel:573106224833">(+57) 3106224833</a>
              </li>
              <li>
                <i className="fa-solid fa-envelope" /><a href="mailto:soporte@datastorecolombia.co">soporte@datastorecolombia.co</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className='contact-us'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <TextField
                    required
                    fullWidth
                    id="name"
                    size="small"
                    name="contact-name"
                    label="Nombre"
                    autoComplete='given-name'
                    variant="outlined"
                    onChange={handleChange}
                    value={name}
                  />
                </div>
                <TooltipComponent
                  attributeName={'contact-name'}
                  showTrigger={TooltipContactName.status}
                  tooltipText={TooltipContactName.content}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <TextField
                    required
                    fullWidth
                    id="email"
                    size="small"
                    name="contact-email"
                    label="Correo electrónico"
                    autoComplete='email'
                    variant="outlined"
                    onChange={handleChange}
                    value={email}
                  />
                </div>
                <TooltipComponent
                  attributeName={'contact-email'}
                  showTrigger={TooltipContactEmail.status}
                  tooltipText={TooltipContactEmail.content}
                />
              </div>
            </div>
            <div>
              <Form.Control
                required
                as="textarea"
                id="message"
                name="contact-message"
                placeholder={'Escribe tu mensaje*'}
                className="form-control"
                onChange={handleChange}
                value={message}
                style={{ height: '80px' }}
              ></Form.Control>
              <TooltipComponent
                attributeName={'contact-message'}
                showTrigger={TooltipContactMessage.status}
                tooltipText={TooltipContactMessage.content}
              />
            </div>
            <div className="login-button">
              <button
                type="submit"
                className="btn btn-theme"
                onClick={handleSubmit}
              >
               Enviar
             </button>
            </div>
          </form>
        </div>
      </section>
    </div>
    </>
  );
}

export default ContactUs;
