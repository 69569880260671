import React, { useState, useRef } from 'react';

import {
    TextField,
    InputAdornment,
    IconButton,
    Checkbox,
    FormControlLabel,
    FormHelperText
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

// import errorComponent from '../errorComponent';

import '../../styles/components/auth.scss';

const validateInput = (type, value) => {
    switch (type) {
        case 'text':
            return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
        case 'email':
            return (!/[\])}>[<{(]/.test(value));
        default:
            break;
    }
};


function RegisterStep2Company({ stepCompleted, signUp }) {

    // States
    const form = useRef(null);

    // Input content
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    // errors
    const [errorCompanyName, setErrorCompanyName] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [errorEmail, setErrorEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [errorPassword, setErrorPassword] = useState({ status: false, content: 'Las contraseñas no coinciden' });
    const [errorCheckbox, setErrorCheckbox] = useState({ status: false, content: 'Este campo debe estar seleccionado' });

    const [error, setError] = useState({ status: false, content: '' });


    // -------------- Functions ----------------

    // Validation
    const formValidation = (data, formData) => {

        let flag = true;

        // Company name
        if (!data.companyName && data.companyName === '') {
            setErrorCompanyName({ ...errorCompanyName, status: true });
            flag = false;
        };

        // Email
        if (!data.email && data.email === '') {
            setErrorEmail({ ...errorEmail, status: true });
            flag = false;
        };

        // Checkbox
        if (!data.privacyPolicy) {
            setErrorCheckbox({ ...errorCheckbox, status: true });
            flag = false;
        };

        // Password
        if (data.password === '' || formData.get('confirm-password') === '') {
            setErrorPassword({ content: 'Este campo no debe ir vacío', status: true });
            flag = false;
        } else if (data.password !== formData.get('confirm-password')) {
            setErrorPassword({ content: 'Las contraseñas no conciden', status: true });
            flag = false;
        } else if (data.password.length < 8) {
            setErrorPassword({ content: 'La contraseña debe tener mínimo 8 caracteres', status: true });
            flag = false;
        };

        return flag;
    };

    const signUpGoogle = async () => {
        await signUp('google', {}, 'company');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const data = {
            companyName: formData.get('companyName'),
            email: formData.get('email'),
            password: formData.get('password'),
            privacyPolicy: formData.get('privacy-policy') === "on" && true,
        };

        const validateForm = formValidation(data, formData);

        if (validateForm) {
            // Make the petition to the backend
            await signUp('email', {
                email: data.email,
                password: data.password,
                companyName: data.companyName,
            }, 'company').catch(error => {
                setError({
                    status: true,
                    content: error
                });
            });
        }
    };

    const handleChange = (event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;

        switch (inputName) {
            case 'companyName':
                setErrorCompanyName({ ...errorCompanyName, status: false });
                validateInput('text', inputValue) && setCompanyName(inputValue);
                break;
            case 'email':
                setErrorEmail({ ...errorEmail, status: false });
                validateInput('email', inputValue) && setEmail(inputValue);
                break;
            case 'password':
                setErrorPassword({ ...errorPassword, status: false });
                validateInput('email', inputValue) && setPassword(inputValue);
                break;
            default:
                break;
        }
    };


    return (
        <section id="login-area" className="py100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="login-area">
                            <div className="login-form-area">
                                <div className="form-area-heading text-center" style={{paddingBottom: '20px'}}>
                                    <h3>¡Registra tu empresa con nosotros!</h3>
                                </div>
                                {/* <div className="social-login-area">
                                    <button
                                        type="submit"
                                        onClick={signUpGoogle}
                                    >
                                        <i className="fa-brands fa-google" />
                                        Google
                                    </button>
                                </div> */}
                                <form action="#" ref={form}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="companyName"
                                                    size="small"
                                                    name="companyName"
                                                    label="Nombre de la empresa"
                                                    autoComplete='given-name'
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    value={companyName}
                                                    helperText={errorCompanyName.status && errorCompanyName.content}
                                                    error={errorCompanyName.status}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    size="small"
                                                    name="email"
                                                    label="Correo electrónico"
                                                    autoComplete='email'
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    value={email}
                                                    helperText={errorEmail.status && errorEmail.content}
                                                    error={errorEmail.status}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">

                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="new-password"
                                                    size="small"
                                                    name="password"
                                                    label="Contraseña"
                                                    autoComplete="new-password"
                                                    onChange={handleChange}
                                                    value={password}
                                                    helperText={errorPassword.status && errorPassword.content}
                                                    error={errorPassword.status}
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endadornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    onMouseDown={(event) => event.preventDefault()}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="confirm-password"
                                                    size="small"
                                                    name="confirm-password"
                                                    label="Confirmar contraseña"
                                                    autoComplete="new-password"
                                                    onChange={() => setErrorPassword({ ...errorPassword, status: false })}
                                                    helperText={errorPassword.status && errorPassword.content}
                                                    error={errorPassword.status}
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endadornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    onMouseDown={(event) => event.preventDefault()}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                                            <div className="password-remember center-section">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name='privacy-policy'
                                                            onChange={() => setErrorCheckbox({ ...errorCheckbox, status: false })}
                                                            required
                                                            error
                                                        />
                                                    }
                                                    label={
                                                        <p>
                                                            Estoy de acuerdo con la <a href="#!">política de privacidad</a>
                                                        </p>
                                                    }
                                                />
                                            </div>
                                            {
                                                errorCheckbox.status && <FormHelperText className='center-section' error>Este recuadro debe ser seleccionado</FormHelperText>
                                            }
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="login-button">
                                                <button
                                                    type="submit"
                                                    className="btn btn-theme"
                                                    onClick={handleSubmit}
                                                >
                                                    Registrarme
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="login-desc text-center">
                                                <p>¿Ya tienes una cuenta?
                                                </p>
                                                <a href="/login"> Ingresa</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RegisterStep2Company