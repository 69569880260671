import { useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({ handleInputChange }) {

  const [error, setError] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    // console.log(file);
    if (file && /\bpdf\b/i.test(file.type)) {
      setError(false);
      handleInputChange(file)
    } else {
      setError(true);
    }
  }

  return (
    <Fragment>
      <div className='center-section' style={{ margin: '8px' }}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput type="file" onChange={handleFileChange} />
        </Button>
      </div>
      {
        error &&
        <p className='text-danger justify-center'>
          Sólo aceptamos el formato PDF
        </p>
      }
    </Fragment>
  );
}