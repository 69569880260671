import React, { Fragment } from 'react';

function TalentExperienceProfile(props) {

    const {
        componentType,
        visitor,
        uuid,
        title,
        profileImageUrl,
        name,
        location,
        description,
        footer,
        handleUpdate
    } = props;

    const colNumber = profileImageUrl ? 9 : 12;

    return (
        <Fragment>
            <div className="row job-info">
                {
                    profileImageUrl &&
                    <div
                        className="col-lg-3 col-md-3 col-sm-3 col-3 profile-image center-section pb24">
                        <img
                            src={profileImageUrl}
                            alt="company"
                        />
                    </div>
                }
                <div className={`col-lg-${colNumber} col-md-${colNumber} col-sm-${colNumber} col-${colNumber} pb12`}>
                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-10 align-center">
                            <div className="profile-name">
                                <h4>{title}</h4>
                                {
                                    name &&
                                    <div className="profile-location">
                                        {
                                            componentType === 'education' ?
                                                <i className="fa-solid fa-school"></i>
                                                :
                                                <i className="fa-solid fa-building"></i>
                                        }
                                        <h6>{name}</h6>
                                    </div>
                                }
                                {
                                    location &&
                                    <div className="profile-location">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h6>{location}</h6>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 edit-job-experience">
                            <div>
                                {/* <button type="submit" className="btn btn-theme">
                                    Editar
                                </button> */}
                                {
                                    visitor ?
                                        ''
                                        :
                                        <div>
                                            <a onClick={(event) => {
                                                event.preventDefault();
                                                handleUpdate(uuid)
                                            }}>
                                                <i className="fa-solid fa-pen" />
                                            </a>
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                    {
                        description
                            ?
                            description.length >= 200 ?
                                <p className="job-description">
                                    {description.slice(0, 200)}
                                    <a className='text-primary' href={`/jobs`}> ...más</a>
                                </p>
                                :
                                <p className="job-description">
                                    {description}
                                </p>
                            :
                            ''
                    }
                    {
                        description &&
                        <p className='job-description-2'>
                            {description.slice(0, 100)}
                            <a className='text-primary' href={`/jobs`}> ...más</a>
                        </p>
                    }
                </div>
                <div className="row py10 job-footer">
                    <div className="col-4 center-section">
                        {
                            componentType === 'education' ?
                                <i className="fa-solid fa-user-graduate"></i>
                                :
                                <i className="fa-solid fa-suitcase" />
                        }
                        <p>{footer.type}</p>
                    </div>
                    <div className="col-4 center-section">
                        <i className="fa-solid fa-calendar-days"></i>
                        <p>{footer.date}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentExperienceProfile