import React from 'react';

import '../styles/components/backToTop.scss'

function BackToTopButton({ handleClick }) {
    return (
        <div
            className="go-top-button"
            onClick={handleClick}
        >
            <i className="fa-solid fa-chevron-up"></i>
        </div>
    )
}

export default BackToTopButton