import api from '../../libs/API';
import USER_FIELDS from '../../constants/encryptFields';
import COMPANY_FIELDS from '../../constants/companyEncriptFields';
import refreshFunction from './refresh';
import useCryptoService from './../useCryptoService';

const useAuthentication = () => {
    const { encryptSelectedFields } = useCryptoService();
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    // Login
    const loginRequest = async (firebaseId, firebaseToken) => {
        try {
            headers.Authorization = `Bearer ${firebaseToken}`
            const response = await api.post('/auth/login', { firebaseId }, { headers });
            const { accessToken, refreshToken, user } = response.data;

            // Decode token and save in local storage
            const payload = JSON.parse(atob(accessToken.split('.')[1]));
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            delete user.phoneNumber;
            delete user.firebaseId;
            localStorage.setItem('userInformation', JSON.stringify({
                role: payload.role,
                user
            }));
            return payload.role;
        } catch (error) {
            console.error(error.response.data.message);
            throw new Error(error.response.data.message);
        }
    };

    // Sign up company
    const signUpCompanyRequest = async (data, firebaseToken) => {
        try {
            headers.Authorization = `Bearer ${firebaseToken}`;
            data = await encryptSelectedFields(data, COMPANY_FIELDS);
            const response = await api.post('/companies', data, { headers });
            const { accessToken, refreshToken, company } = response.data;

            // Decode token and save in local storage
            const payload = JSON.parse(atob(accessToken.split('.')[1]));
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            delete company.firebaseId;
            localStorage.setItem('userInformation', JSON.stringify({
                role: payload.role,
                user: company
            }));

            return company;
        } catch (error) {
            console.error(error.response.data.message);
            throw new Error(error.response.data.message);
        }
    };

    // Sign up talent
    const signUpTalentRequest = async (data, firebaseToken) => {
        try {
            headers.Authorization = `Bearer ${firebaseToken}`;
            data = await encryptSelectedFields(data, USER_FIELDS);
            const response = await api.post('/users', data, { headers });
            const { accessToken, refreshToken, user } = response.data;

            // Decode token and save in local storage
            const payload = JSON.parse(atob(accessToken.split('.')[1]));
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            delete user.phoneNumber;
            delete user.firebaseId;
            localStorage.setItem('userInformation', JSON.stringify({
                role: payload.role,
                user
            }));

            return user;
        } catch (error) {
            console.error(error.response.data);
            throw new Error(JSON.stringify(error.response.data));
        }
    };

    // Logout request
    const logoutRequest = async (uuid, allDevices = false, alreadyCalled) => {
        try {

            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`

            const refresh = localStorage.getItem('refreshToken');

            // Remove from local storage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userInformation');

            const response = await api.post('/auth/logout', { uuid, token: refresh, allDevices }, { headers });

            return true;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        // Refresh token
                        await refreshToken();
                        await logoutRequest(uuid, allDevices, true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Refresh token
    const refreshToken = async () => {

        return new Promise(async (resolve, reject) => {
            await refreshFunction()
                .then(response => resolve(response))
                .catch(error => reject(error))

        })
    };

    return {
        loginRequest,
        signUpCompanyRequest,
        signUpTalentRequest,
        logoutRequest,
        refreshToken
    };
};

export default useAuthentication;