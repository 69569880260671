import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingComponent() {
  return (
    <div className="loading-overlay">
      <Box sx={{ height: '100vh' }} className='center-section'>
        <CircularProgress size={50} />
      </Box>
    </div>
  );
}
