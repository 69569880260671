import React, { Fragment, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';

// ----- Custom ----- //
import useApplicantsRequests from '../../hooks/backend/useApplicants';

const getStatus = {
    'Todas': { className: '', status: 'Todas' },
    'requested_by_user': { className: '', status: 'Aplicado' },
    'requested_by_company': { className: 'text-info', status: 'Requerido' },
    'in_review': { className: 'text-warning', status: "En revisión" },
    'rejected_by_user': { className: 'text-danger', status: "Rechazado" },
    'rejected_by_company': { className: 'text-danger', status: "Rechazado" },
    'in_contact': { className: 'text-primary', status: "En contacto" },
    'hired': { className: 'text-success', status: "Contratado" },
};

function ApplicationFilters(props) {

    const {
        searchValue,
        setSearchValue,
        sortByOldest,
        setSortByOldest,
        statusValue,
        setStatusValue,
        statusRequest,
        myApplicationsAnalyticsTracker
    } = props;

    // ========== States ============
    const [statusList, setStatusList] = useState([{
        'id': 0,
        'status': 'Todas'
    }]);

    // ========== Custom ============
    const { getStatusRequest } = useApplicantsRequests();

    // ========== Backend request ==========  //
    useEffect(() => {
        return async function () {

            let type;
            let uuid;
            if (statusRequest?.jobUuid) {
                type = 'jobUuid';
                uuid = statusRequest.jobUuid;
            } else {
                type = 'userUuid';
                // Get user uuid
                const userInformation = JSON.parse(localStorage.getItem('userInformation'));
                uuid = userInformation.user.uuid;
            }

            await getStatusRequest(type, uuid)
                .then(response => setStatusList(statusList.concat(response)))
                .catch(console.error)
        };
    }, []);

    return (
        <Fragment>
            {/* <!-- Search by applicant title start --> */}
            <div className="left-applicant-page box-shadow">
                <div className="left-card">
                    <h4>Título</h4>
                    <form action="#" className="mt4">
                        <div className="applicant-search-option">
                            <input
                                type="text"
                                placeholder="Título..."
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <button className="button" type="submit">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <!-- Search by applicant title end --> */}

            {/* <!-- Search by date --> */}
            <div className="left-applicant-page box-shadow">
                <div className="left-card mt4">
                    <h4>Filtrar por</h4>
                    <select
                        className="form-select mt4"
                        aria-label="Filter by date"
                        onChange={(event) => setSortByOldest(event.target.value)}
                        defaultChecked={sortByOldest}
                    >
                        <option value="1" >Más reciente</option>
                        <option value="0">Más antiguo</option>
                    </select>
                </div>
            </div>

            {/* <!-- Status --> */}
            <div className="left-applicant-page box-shadow">
                <Form className="left-card">
                    <h4>Estado</h4>
                    {
                        statusList && statusList.map(statusInfo => {
                            const statusName = getStatus[statusInfo.status];
                            return (
                                <div
                                    className="form-check mt4"
                                    key={statusInfo.id}
                                >
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="radio"
                                        id={statusInfo["id"]}
                                        name={`statusInfo-${statusInfo.status}`}
                                        label={statusName.status}
                                        value={statusInfo.status}
                                        checked={statusValue === statusInfo.status}
                                        onChange={event => {
                                            myApplicationsAnalyticsTracker({ action: 'talent_filter_application_by_status', label: event.target.value });
                                            setStatusValue(event.target.value)
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </Form>
            </div>
        </Fragment>
    )
}

export default ApplicationFilters