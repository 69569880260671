import api from '../../libs/API'
import useAuthentication from './useAuth';


const useOptions = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Complete information
    const getLocationInformation = async (cityId, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const completeInformation = await api.get(`/location/${cityId}`, { headers });
            return completeInformation.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getLocationInformation(cityId, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Country
    const getCountries = async (alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`
            const countries = await api.get('/location/countries', { headers });
            return countries.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCountries(true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // States
    const getStates = async (countryId, alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`
            const states = await api.get(`/location/states/${Number(countryId)}`, { headers });
            return states.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getStates(countryId, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Cities
    const getCities = async (stateId, alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`
            const cities = await api.get(`/location/cities/${Number(stateId)}`, { headers });
            return cities.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCities(stateId, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Company size
    const getCompanySizeOptions = async (alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`
            const companySizeOptions = await api.get(`/options/companySize`, { headers });
            return companySizeOptions.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCompanySizeOptions(true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Currency types
    const getCurrencyOptions = async (alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`
            const currencyOptions = await api.get(`/options/currency`, { headers });
            return currencyOptions.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCurrencyOptions(true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Categories
    const getCategoryOptions = async (alreadyCalled) => {
        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const categoryOptions = await api.get(`/options/categories`, { headers });
            return categoryOptions.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCategoryOptions(true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };


    return { getLocationInformation, getCountries, getStates, getCities, getCompanySizeOptions, getCurrencyOptions, getCategoryOptions };
};

export default useOptions;