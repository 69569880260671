import { auth } from "../firebaseConfig";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail,
    deleteUser
} from "firebase/auth";

const useFirebaseAuth = () => {
    
    const createUserWithEmail = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signInWithEmail = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };


    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    };

    const signOutFunction = () => {
        return signOut();
    };

    const passwordReset = (email) => {
        return sendPasswordResetEmail(auth, email);
    };

    const deleteAccount = () => {
        const user = auth.currentUser;
        return deleteUser(user);
    };

    return {
        createUserWithEmail,
        signInWithEmail,
        signInWithGoogle,
        signOutFunction,
        passwordReset,
        deleteAccount
    };
};

export default useFirebaseAuth;