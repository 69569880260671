import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';

import AppContext from '../context/AppContext';

// Custom hooks
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useAuthentication from '../hooks/backend/useAuth';
import useAnalytics from '../hooks/useAnalytics';

import LoginComponent from '../components/auth/LoginComponent';
import ModalComponent from '../components/ModalComponent';
import LoadingComponent from '../components/LoadingComponent';

function Login() {

    const { changeHeaderLayout } = useContext(AppContext);

    const { signInWithEmail, signInWithGoogle, deleteAccount } = useFirebaseAuth();
    const { loginRequest } = useAuthentication();
    const loginAnalyticsTracker = useAnalytics('Login');

    const modalRef = useRef();

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Usuario no encontrado',
        content: 'La cuenta que ingresaste no fue encontrada en nuestro sistema, te recomendamos crear una cuenta o intentar con otra',
        button1Content: 'Cancelar',
        button2Content: 'Crear cuenta',
        button1Redirect: '/login',
        button2Redirect: '/register',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        changeHeaderLayout(true);
    }, []);

    const loginUser = async (type, data) => {
        switch (type) {
            case 'google':
                signInWithGoogle()
                    .then(async response => {
                        // New user
                        if (response._tokenResponse.isNewUser) {
                            openModal();
                            await deleteAccount(response.user.uid)
                                .catch(error => console.error(error));
                        } else {
                            setIsLoading(true);
                            // Backend request
                            await loginRequest(response.user.uid, response.user.accessToken)
                                .then(response => {
                                    if (response === 'talent') {
                                        loginAnalyticsTracker({ action: 'talent_google_login' });
                                        window.location.href = 'jobs';
                                    } else {
                                        loginAnalyticsTracker({ action: 'company_google_login' });
                                        window.location.href = 'my-jobs';
                                    }
                                })
                                .catch(() => {
                                    setIsLoading(false);
                                    setModalInformation({
                                        title: 'Error interno',
                                        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
                                        button2Content: 'Volver',
                                        button2Redirect: '/login',
                                    });
                                    openModal();
                                });
                        }
                    })
                    .catch(error => {
                        console.error(error.message)
                    });
                break;
            case 'email':
                signInWithEmail(data.email, data.password)
                    .then(async (response) => {
                        setIsLoading(true);
                        // Backend request
                        await loginRequest(response.user.uid, response.user.accessToken)
                            .then(response => {
                                setIsLoading(false);
                                if (response === 'talent') {
                                    loginAnalyticsTracker({ action: 'talent_email_login' });
                                    window.location.href = 'jobs';
                                } else {
                                    loginAnalyticsTracker({ action: 'company_email_login' });
                                    window.location.href = 'my-jobs';
                                }
                            })
                            .catch(() => {
                                setIsLoading(false);
                                setModalInformation({
                                    title: 'Error interno',
                                    content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
                                    button2Content: 'Volver',
                                    button2Redirect: '/login',
                                });
                                openModal();
                            });
                    })
                    .catch(error => {
                        console.error(error.message);
                        if (error.message === "Firebase: Error (auth/user-not-found).") {
                            openModal()
                        } else if (error.message === "Firebase: Error (auth/wrong-password).") {
                            setModalInformation({
                                title: 'Contraseña incorrecta',
                                content: 'La contraseña ingresada es incorrecta, por favor intenta de nuevo',
                                button1Content: 'Cancelar',
                                button2Content: 'Cambiar contraseña',
                                button1Redirect: '/login',
                                button2Redirect: '/recover-password',
                            })
                            openModal();
                        } else if (error.message === "Firebase: Error (auth/invalid-login-credentials).") {
                            setModalInformation({
                                title: 'Correo o contraseña incorrectos',
                                content: 'El correo y la contraseña ingresada no coinciden, por favor intenta de nuevo',
                                button1Content: 'Cancelar',
                                button2Content: 'Cambiar contraseña',
                                button1Redirect: '/login',
                                button2Redirect: '/recover-password',
                            })
                            openModal();
                        }

                    });
                break;

            default:
                break;
        }
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonTwo() { window.location.href = modalInformation.button2Redirect }

    return (
        <Fragment>
            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={handleRedirectToButtonTwo}
            />
            {isLoading && <LoadingComponent />}
            <LoginComponent loginUser={loginUser} />
        </Fragment>
    )
};

export default Login;