import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";

import '../styles/pages/jobs-list.scss';

// Components
import FilterButtonComponent from '../components/FilterButtonComponent';
import FreeJobsFilter from '../components/jobs/FreeJobsFilter';


// Custom
import useJobsRequests from '../hooks/backend/useJobs';
import useAnalytics from '../hooks/useAnalytics';
import useDate from '../hooks/useDates';

const INITIAL_CATEGORY = 'e207272d-d465-4327-a80c-b76440cf7e03';

function FreeJobs() {

    // ========== Custom hooks ============
    const { getFreeJobs, getJobType } = useJobsRequests();
    const { convertIsoToDMYFormat } = useDate();

    // ========== States ============
    const [jobsList, setJobsList] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [toggleLateralFilters, setToggleLateralFilters] = useState(false);


    // ----- Filter states ----- //
    const [searchValue, setSearchValue] = useState('');
    const [categoryValue, setCategoryValue] = useState(INITIAL_CATEGORY);
    const [cityValue, setCityValue] = useState(0);
    const [remoteValue, setRemoteValue] = useState(0);

    const [categoryList, setCategoryList] = useState([{
        uuid: INITIAL_CATEGORY,
        name: 'Todas',
    }]);
    const [citiesList, setCitiesList] = useState([{
        id: 0,
        name: "Todas",
        company_uuid: "0",
        remote: 0
    }]);

    // Navigation router
    const navigate = useNavigate();

    const categoryRef = useRef(null);


    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function jobListFunction() {
            try {
                const response = await getFreeJobs();
                setJobsList(response);

                let categories = [];
                let cities = [];
                let categoriesName = [];

                response.forEach((job) => {
                    if (job.job_category && job.job_category.length !== 0) {
                        const category = job.job_category[0].category;
                        if (!categoriesName.includes(category.name)) {
                            categories.push(category);
                            categoriesName.push(category.name);
                        }
                    }
                    if (!job.remote && job.city) {
                        const city = { id: job.city.id, name: job.city.name, remote: 0 };
                        if (!cities.some(c => c.id === city.id && c.name === city.name)) {
                            cities.push(city);
                        }
                    }
                });

                setCategoryList(prevCategories => [...prevCategories, ...categories.filter(category => !prevCategories.includes(category))]);
                setCitiesList(prevCities => [...prevCities, ...cities.filter(city => !prevCities.some(c => c.id === city.id && c.name === city.name))]);

                // Close lateral filters if its open
                setToggleLateralFilters(false);
            } catch (error) {
                console.error(error);
            }
        }

        if (jobsList.length === 0) {
            jobListFunction();
        }
    }, [isInitialMount]);

    useEffect(() => {
        let filtered = jobsList;

        // Filtro por búsqueda
        if (searchValue.length >= 1) {
            filtered = filtered.filter(job => {
                const jobText = job.title.toLowerCase();
                const searchText = searchValue.toLowerCase();
                return jobText.includes(searchText);
            });
        }

        if (categoryValue !== INITIAL_CATEGORY) {
            filtered = filtered.filter(job => job.job_category.some(cat => cat.category.uuid === categoryValue));
        }

        if (cityValue != 0) {
            filtered = filtered.filter(job => job.city && job.city.id == cityValue);
        }
        setFilteredJobs(filtered);
    }, [searchValue, categoryValue, cityValue, jobsList]);


    // ========== Functions ==========  //
    // Close the lateral filter if the page width is more than 992px
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992)
                setToggleLateralFilters(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Pagination handler
    const handleRegister = () => {
        navigate('/register');
    };

    return (
        <Fragment>
            <section id="blog-grid-area" className="py40">
                <div className="container">
                    <div className="row">
                        {/* Job filters */}
                        <div className="job-filters col-lg-4 col-md-12 col-sm-12 col-12">
                            <FreeJobsFilter
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                categoryRef={categoryRef}
                                categoryList={categoryList}
                                categoryValue={categoryValue}
                                setCategoryValue={setCategoryValue}
                                citiesList={citiesList}
                                setCityValue={setCityValue}
                                setRemoteValue={setRemoteValue}
                            />
                        </div>

                        {/* Job cart */}
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    {
                                        jobsList && filteredJobs.map((job, index) => {
                                            return (
                                                <div className="job-cart free-jobs-cart box-shadow mb4" key={index} onClick={handleRegister}>
                                                    <div className="container">
                                                        <div className="row job-header">
                                                            <div className="col-lg-8 col-md-8 col-sm-10 col-10">
                                                                <div className="row">
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        {
                                                                            job.company?.profile_image_url
                                                                                ?
                                                                                <img
                                                                                    src={job.company?.profile_image_url}
                                                                                    alt="company-profile-photo"
                                                                                    className='blur20'
                                                                                />
                                                                                :
                                                                                <img
                                                                                    src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                                                                                    alt="Profile Picture Default"
                                                                                    style={{ objectFit: 'contain' }}
                                                                                    className='blur20'
                                                                                />
                                                                        }
                                                                        <div className=" job-title">
                                                                            <h4>{job.title}</h4>
                                                                            <h6 className='blur4'>{job.company.name}</h6>
                                                                            <h6>{job.remote === true ? 'Remoto' : `${job.city?.name}, ${job.city?.state?.name}`}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-2 col-2 align-center">
                                                                <div
                                                                    onClick={handleRegister}
                                                                    className="more-information"
                                                                >
                                                                    <button type="submit" className="btn btn-theme">Más información</button>
                                                                    <div>
                                                                        <i className="fa-solid fa-chevron-right"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row job-content">
                                                            {
                                                                job.description.length >= 200 ?
                                                                    <p className='blur4'>
                                                                        {job.description.slice(0, 200)}
                                                                        <a className='text-primary' href={`/login`}> ...más</a>
                                                                    </p>
                                                                    :
                                                                    <p className='blur4'>
                                                                        {job.description}
                                                                    </p>
                                                            }

                                                            <ul>
                                                                {job.job_category.map((category) => {
                                                                    return (
                                                                        <li key={category.id}>
                                                                            {category.category.name}
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="row py10 job-footer">
                                                            <div className="col-4 align-center justify-content-center">
                                                                <i className="fa-solid fa-briefcase" />
                                                                <p>{getJobType[job.type]}</p>
                                                            </div>
                                                            <p className="col-4 align-center justify-content-center">{job.applicantsNumber === 1 ? `${job.applicantsNumber} Aplicante` : `${job.applicantsNumber} Aplicantes`}</p>
                                                            <div className="col-4 align-center justify-content-center">
                                                                <i className="fa-regular fa-calendar" />
                                                                <p >{convertIsoToDMYFormat(job.startDate)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>


                        {
                            toggleLateralFilters ?

                                <Container fluid id="fix-left">
                                    <Row>
                                        <Col id='lateral-filter-container' className='box-shadow' xs={10} >
                                            <Row id='lateral-filter-header'>
                                                <h4>Filtrar empleos</h4>
                                                <i
                                                    className="fa-sharp fa-solid fa-xmark fa-2xl lateral-filter-go-out"
                                                    onClick={() => setToggleLateralFilters(false)}
                                                />
                                            </Row>
                                            <Row>
                                                {/* Job filters */}
                                                <FreeJobsFilter
                                                    searchValue={searchValue}
                                                    setSearchValue={setSearchValue}
                                                    categoryRef={categoryRef}
                                                    categoryList={categoryList}
                                                    categoryValue={categoryValue}
                                                    setCategoryValue={setCategoryValue}
                                                    citiesList={citiesList}
                                                    setCityValue={setCityValue}
                                                    setRemoteValue={setRemoteValue}
                                                />

                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                ''
                        }

                        <FilterButtonComponent
                            setToggleLateralFilters={setToggleLateralFilters}
                        />

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default FreeJobs;