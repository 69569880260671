import React, { useEffect } from "react";
import ReactGA from 'react-ga';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from "../components/auth/PrivateRoute";

import Layout from '../containers/Layout';
import Landing from "../pages/Landing";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import MyProfile from "../pages/MyProfile";
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import FreeJobs from "../pages/FreeJobs";

import JobsList from "../pages/talent/JobsList";
import JobDetails from "../pages/talent/JobDetails";
import MyApplications from "../pages/talent/MyApplications";
import ApplicationDetails from "../pages/talent/ApplicationDetails";
import SpecificCompanyProfile from "../pages/talent/SpecificCompanyProfile";

import MyJobs from "../pages/company/MyJobs";
import ApplicantsList from "../pages/company/ApplicantsList";
import SpecificTalentProfile from "../pages/company/SpecificTalentProfile";
import TalentAvailability from "../pages/company/TalentAvailability";

import AppContext from '../context/AppContext';

import "../styles/global.css";
import "../styles/responsive.css";

// Hooks
import useInitialState from '../hooks/useInitialState';

function App() {

  const initialState = useInitialState();
  const { isLogged, role } = initialState;

  const TRACKING_ID = "G-6HV4PTMKDQ"; // OUR_TRACKING_ID
  // const TRACKING_ID = "G-Z2S03RXECD"; // TEST
  ReactGA.initialize(TRACKING_ID);

  // Track the pages that are visited
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route element={<PrivateRoute isAllowed={!isLogged} redirectTo={'my-profile'} />}>
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/register" element={<SignUp />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/recover-password" element={<ResetPassword />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/free-jobs" element={<FreeJobs />} />
            </Route>

            {/* Logged routes */}
            <Route element={<PrivateRoute isAllowed={isLogged} />}>
              <Route exact path="/my-profile" element={<MyProfile />} />
              <Route exact path="/settings" element={<h2>Settings</h2>} />
            </Route>


            {/* Talent routes only */}
            <Route element={<PrivateRoute isAllowed={isLogged && role === 'talent'} redirectTo={'my-profile'} />}>
              <Route exact path="/jobs" element={<JobsList />} />
              <Route exact path="/jobs/:jobUuid" element={<JobDetails />} />
              <Route exact path="/company/:companyUuid" element={<SpecificCompanyProfile />} />
              <Route exact path="/my-applications" element={<MyApplications />} />
              <Route exact path="/application/:applicationId" element={<ApplicationDetails />} />
            </Route>

            {/* Company routes only */}
            <Route element={<PrivateRoute isAllowed={isLogged && role === 'company'} redirectTo={'my-profile'} />}>
              <Route exact path="/my-jobs" element={<MyJobs />} />
              <Route exact path="/applicants/:jobUuid" element={<ApplicantsList />} />
              <Route exact path="/talent/:talentUuid" element={<SpecificTalentProfile />} />
              <Route exact path="/talent-availability/:categoryUuid" element={<TalentAvailability />} />
            </Route>

            <Route path="*" element={<h2>Not found</h2>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
