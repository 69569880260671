import api from '../../libs/API'
import useAuthentication from './useAuth';


const useJobsRequests = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    //----- Get list of jobs -----//
    const getJobs = async (filters, alreadyCalled) => {
        // Get token
        const token = localStorage.getItem('accessToken');
        headers.Authorization = `Bearer ${token}`;

        // Get filters
        const { limit, page, active, oldest, category, country, state, city, company, companySize, remote } = filters;

        // Filters
        let queryParams = { limit }

        // Offset
        if (page) queryParams.offset = (page - 1) * limit;

        // Category
        if (category) queryParams.category = category;

        // Company
        if (company) queryParams.company = company;

        // Location
        if (country) queryParams.country = country;
        if (state) queryParams.state = state;
        if (city) queryParams.city = city;

        // Return only active by default
        if (active) {
            queryParams.active = 0;
        } else {
            queryParams.active = 1;
        }

        // Remote
        if (remote) queryParams.remote = 1;

        // Order by newest by default
        if (oldest) queryParams.newest = oldest;

        if (companySize) queryParams.companySize = Number(companySize);

        try {
            const jobList = await api.get('/jobs', { params: queryParams, headers });
            return jobList.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getJobs(filters, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    //----- Get list of free jobs -----//
    const getFreeJobs = async (alreadyCalled) => {
        // Get token
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvaCEgd293IHlvdSBhcnJpdmUgaGVyZSEgQ29uZ3JhdHVsYXRpb25zIiwicm9sZSI6ImZyZWUiLCJpYXQiOjE3MTk0Mjg1MzZ9.96V8vp2Z39jjgArsah0e75Nith4OLlgGsDybrJQm5dM';
        headers.Authorization = `Bearer ${token}`;

        try {
            const jobList = await api.get('/jobs/free', { headers });
            return jobList.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getFreeJobs(true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    //----- Get job details -----//
    const getJob = async (uuid, alreadyCalled) => {
        // Get token
        const token = localStorage.getItem('accessToken');
        headers.Authorization = `Bearer ${token}`;

        try {
            const jobDetails = await api.get(`/jobs/${uuid}`, { headers });
            return jobDetails.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getJob(uuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };

    };

    // Create job
    const createJobRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/jobs', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createJobRequest(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update job
    const updateJobRequest = async (jobUuid, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/jobs/${jobUuid}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateJobRequest(jobUuid, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update job category
    const updateJobCategoryRequest = async (id, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/jobs/category/${id}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateJobCategoryRequest(id, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };


    // ----- Filter options ----- //
    //----- Get list of categories -----//
    const getCategoryFilters = async (companyUuid, alreadyCalled) => {

        // 
        let queryParams = {};
        if (companyUuid) queryParams.uuid = companyUuid;

        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;
            const categories = await api.get('/jobs/categories', { params: queryParams, headers });
            return categories.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getCategoryFilters(companyUuid, true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    //----- Get list of companies -----//
    const getCompaniesFilters = async (alreadyCalled) => {

        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;
            const companies = await api.get('/jobs/companies', { headers });
            return companies.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getCompaniesFilters(true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    //----- Get list of company sizes -----//
    const getCompanySizeFilters = async (alreadyCalled) => {

        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const companySizes = await api.get('/jobs/companySizes', { headers });
            return companySizes.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getCompanySizeFilters(true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    //----- Get list of cities -----//
    const getCitiesFilters = async (alreadyCalled) => {

        try {
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const cities = await api.get('/jobs/cities', { headers });
            return cities.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getCitiesFilters(true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    const getJobType = {
        occasional: 'Ocasional',
        seasonal: 'Por temporada',
        internships: 'Práctica',
        part: 'Medio tiempo',
        full: 'Tiempo completo',
    };

    return {
        createJobRequest,
        getJobs,
        getJob,
        getCategoryFilters,
        getCompaniesFilters,
        getCompanySizeFilters,
        getCitiesFilters,
        getJobType,
        getFreeJobs,
        updateJobRequest,
        updateJobCategoryRequest
    };
};

export default useJobsRequests;