import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import GoBack from '../components/GoBackButton';
import Footer from '../components/Footer'

const Layout = ({ children }) => {
    const location = useLocation();
    const noGoBackPaths = ['/'];
    const shouldShowGoBack = !noGoBackPaths.includes(location.pathname);

    return (
        <div className="Layout">
            <Header />
            {shouldShowGoBack && <GoBack />}
            {children}
            <Footer />
        </div>
    );
}

export default Layout;
