import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import TooltipComponent from '../TooltipComponent';

const CompleteNameSignUpModal = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const {
        handleClose,
        objectToComplete,
        handleInputChange,
        handleSubmit,
        TooltipFirstName,
        TooltipLastName
    } = props;

    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Información adicional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt4">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                                <input
                                    id="firstName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Primer nombre*"
                                    name='modal-firstName'
                                    onChange={handleInputChange}
                                    value={objectToComplete.firstName}
                                    required
                                />
                                <TooltipComponent
                                    attributeName={'modal-firstName'}
                                    showTrigger={TooltipFirstName.status}
                                    tooltipText={TooltipFirstName.content}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="form-group">
                                <input
                                    id="middleName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Segundo nombre"
                                    name='middleName'
                                    onChange={handleInputChange}
                                    value={objectToComplete.middleName}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <input
                                    id="lastName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Apellidos*"
                                    name='modal-lastName'
                                    onChange={handleInputChange}
                                    value={objectToComplete.lastName}
                                />
                                <TooltipComponent
                                    attributeName={'modal-lastName'}
                                    showTrigger={TooltipLastName.status}
                                    tooltipText={TooltipLastName.content}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={() => handleSubmit()}
                    >
                        Actualizar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default CompleteNameSignUpModal;