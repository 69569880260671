import React from 'react';
import { useNavigate } from 'react-router-dom';

const GoBack = () => {
  const navigate = useNavigate();

  return (
    <div className="go-back" onClick={() => navigate(-1)}>
      <i className="fa-solid fa-arrow-left"></i>
    </div>
  );
};

export default GoBack;
