import React, { Fragment, useEffect, useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';

function MyJobsFilters(props) {

    const {
        searchValue,
        setSearchValue,
        sortByOldest,
        setSortByOldest,
        categoryValue,
        setCategoryValue,
        disabledJobs,
        setDisabledJobs,
    } = props;

    const categoryRef = useRef(null);

    // ========== Custom hooks ============
    const { getCategoryFilters } = useJobsRequests();

    // ========== States ============
    const [categoryList, setCategoryList] = useState([{
        'category.name': 'Todas',
        'category.uuid': 'e207272d-d465-4327-a80c-b76440cf7e03'
    }]);

    // ========== Backend request ==========  //
    // ----- Job Filters ----- //
    useEffect(() => {
        return async function jobFiltersFunction() {

            const userInformation = JSON.parse(localStorage.getItem('userInformation'));
            const uuid = userInformation.user.uuid;

            await getCategoryFilters(uuid)
                .then(response => {
                    setCategoryList(categoryList.concat(response));
                })
                .catch(console.error);
        };
    }, []);


    return (
        <Fragment>
            <div className="left-blog-page box-shadow">
                {/* <!-- Search by job title start --> */}
                <div className="left-blog">
                    <h4>Título del empleo</h4>
                    <form action="#" className="mt4">
                        <div className="blog-search-option">
                            <input
                                type="text"
                                placeholder="Título..."
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <button className="button" type="submit">
                                <i className="fa fa-search" />
                            </button>
                        </div>
                    </form>
                </div>
                {/* <!-- Search by job title end --> */}
            </div>

            {/* <!-- Search by date --> */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Filtrar por</h4>
                    <select
                        className="form-select mt4"
                        aria-label="Filter by date"
                        onChange={(event) => setSortByOldest(event.target.value)}
                        defaultChecked={sortByOldest}
                    >
                        <option value="1" >Más reciente</option>
                        <option value="0">Más antiguo</option>
                    </select>
                </div>
            </div>

            {/* <!-- Categories --> */}
            <div className="left-blog-page box-shadow">
                <Form
                    className="left-blog"
                    ref={categoryRef}
                >
                    <h4>Categorías</h4>
                    {
                        categoryList && categoryList.map(category => {
                            return (
                                <div
                                    className="form-check mt4"
                                    key={category["category.uuid"]}
                                >
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="radio"
                                        id={category["category.uuid"]}
                                        name={`category-${category["category.name"]}`}
                                        label={category["category.name"]}
                                        value={category["category.uuid"]}
                                        checked={categoryValue === category["category.uuid"]}
                                        onChange={event => setCategoryValue(event.target.value)}
                                    />
                                </div>
                            );
                        })
                    }
                </Form>
            </div>

            {/* <!-- Disabled jobs --> */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Empleos deshabilitados</h4>
                    <div className="form-check mt4">
                        <Form.Check
                            style={{ paddingLeft: '0.1em' }}
                            type="checkbox"
                            id={1}
                            name='Disabled jobs'
                            label='Incluir empleos deshabilitados'
                            value={disabledJobs}
                            checked={disabledJobs}
                            onChange={() => setDisabledJobs(!disabledJobs)}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MyJobsFilters