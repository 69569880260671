import React from "react";
import "../styles/pages/privacy-policy.scss";

const PrivacyPolicy = () => {
  return (
    <>
      {/* <h1>Privacy Policy</h1> */}
      <div className="container privacy-policy-container">
        <h2>POLÍTICA DE PRIVACIDAD PARA TALENTOS</h2>
        <p>
          En la presente Política de Privacidad se detalla quién tiene la
          responsabilidad de los datos personales de los usuarios que buscan
          empleo o servicios relacionados y utilizan la plataforma web Talento
          Rápido. Además, se explica cómo se recopilan, comparten y tratan estos
          datos personales (conocidos como “Datos Personales” o “Datos
          Personales de los Talentos”), así como los derechos que tienen los
          usuarios y cómo ejercerlos. Fintech Software Solutions S.A.S considera
          de gran importancia la protección de la privacidad y seguridad de los
          Datos Personales de los Talentos.
        </p>
        <h3>RESPONSABLE</h3>
        <p>¿Quién es el Responsable del tratamiento de tus datos?</p>
        <p>
          <b>Nombre:</b> Fintech Software Solutions S.A.S. (en adelante “Fintech
          Software Solutions S.A.S”)
        </p>
        <p>
          <b>Contacto:</b> Formulario de contacto.
        </p>
        <h3>DATOS QUE RECOPILAMOS</h3>
        <p>
          A fin de crear una cuenta en el portal web como Talento o utilizar
          otros de los servicios de nuestros sitios web, como la recepción de
          alertas, puede ser necesario proporcionarnos los siguientes datos:
          nombre y apellidos, dirección de correo electrónico, así como, en su
          caso, una contraseña.
        </p>
        <p>
          También, podrás incluir en tu perfil tus datos académicos, experiencia
          laboral y aptitudes, preferencias, intereses, una fotografía, tu
          ciudad o ubicación. También podrás subir tu hoja de vida en nuestro
          portal web en formato Pdf
        </p>
        <p>
          Además, obtenemos información de tus dispositivos y redes, como la
          ubicación, la dirección IP, el servidor proxy, el sistema operativo,
          el navegador web y los complementos instalados. En paralelo,
          recopilamos información mediante cookies, cuyo funcionamiento se
          describe más detalladamente en nuestra Política de Cookies.
        </p>
        <h3>FINALIDADES Y LEGITIMACIÓN</h3>
        <p>
          El propósito de Fintech Software Solutions S.A.S con los servicios
          disponibles en la plataforma web de Talento Rápido en Colombia es
          establecer contacto entre los Talentos o Demandantes de empleo del
          país y los empleadores o compañías proveedoras de trabajo en Colombia
          (Usuarios Profesionales u Ofertantes de empleo), a través de
          herramientas y servicios que asisten en los procesos de búsqueda de
          empleo en el territorio colombiano.
        </p>
        <p>
          En concreto, los Datos Personales que nos facilitas al registrarte y/o
          utilizar nuestro portal web y/o aplicación móvil serán destinados para
          las siguientes finalidades:
        </p>
        <p>
          3.1. Realizar una correcta gestión de nuestros servicios consistentes
          en:
        </p>
        <p>Creación de una cuenta en el portal web de Talento Rápido.</p>
        <p>
          Posibilitar el contacto con los Ofertantes de empleo (Usuarios
          Profesionales) mediante la aplicación a ofertas de empleo publicadas
          por los mismos en el portal web. En este sentido, en función del nivel
          de privacidad que hayas elegido, los ofertantes de empleo podrán
          acceder a tus datos de contacto y a tu C.V. aunque no hayas aplicado a
          ninguna de las vacantes publicadas.
        </p>
        <p>
          Permitir la búsqueda de tu perfil como talento (según el nivel de
          privacidad que hayas elegido) a través de la base de datos de Talentos
          o Usuarios Particulares registrados en nuestro portal web.
        </p>
        <p>Edición y gestión de tu C.V. a través del área privada.</p>
        <p>
          Recepción automática y gratuita de ofertas por vía electrónica. En
          caso de estar disponible en el portal web, podrás solicitar la
          recepción de ofertas a través de otros servicios o aplicaciones de
          terceros que sean integradas en el mismo. Dicho uso estará sujeto a
          las propias condiciones y políticas de privacidad de los terceros
          titulares, de las cuales no somos responsables.
        </p>
        <p>
          Servicio de mensajería instantánea con los Ofertantes de empleo. Cabe
          mencionar que al usar el formulario de contacto o el chat virtual del
          portal web, estarás utilizando nuestro servicio de mensajería. En este
          caso, los mensajes y anuncios son revisados para prevenir el fraude o
          un uso abusivo o inadecuado de nuestros servicios. En caso de estar
          disponible en el portal web, podrá tener lugar la comunicación con los
          Ofertantes de empleo mediante otros servicios de mensajería
          instantánea de terceros que sean integrados en el portal web y que tú
          tengas disponibles en tus dispositivos. Dicho uso estará sujeto a sus
          propias condiciones y políticas de privacidad de los terceros
          titulares, de las cuales no somos responsables.
        </p>
        <p>
          Gestión de nuestros servicios y tareas comerciales diarias, incluyendo
          recordatorios, avisos técnicos, actualizaciones, alertas de seguridad,
          y mensajes de soporte, entre otros. En el caso concreto de que
          utilices nuestro formulario de contacto de atención al usuario, tus
          datos serán utilizados para atender las consultas o reclamaciones que
          nos plantees a través de este medio.
        </p>
        <p>
          Envío de comunicaciones comerciales, encuestas, boletines,
          invitaciones a webinars, para informarte de nuestros productos,
          servicios y eventos, siempre que contemos con tu consentimiento para
          ello.
        </p>
        <p>
          Para moderar y mostrar, de forma anónima, las evaluaciones de las
          empresas que hayas proporcionado, las revisiones de las entrevistas,
          los informes de salarios y otros contenidos que hayas enviado, ello
          para el beneficio de nuestros otros usuarios del portal web.
        </p>
        <p>
          3.2. Gestionar la suscripción y moderar, de forma anónima, los
          comentarios facilitados, en su caso, en nuestro Blog de contenidos
          relacionados con los servicios, actividades y productos de nuestro
          grupo empresarial.
        </p>
        <p>
          3.3. Mejorar nuestros servicios mediante el estudio de tu
          comportamiento como usuario a través de cookies para adaptarlo a tus
          necesidades y gustos. Puedes gestionar tus cookies siguiendo las
          instrucciones indicadas en nuestra Política de cookies.
        </p>
        <p>
          3.4. Mostrarte publicidad inteligente. En base a los datos de tu
          navegación que recojamos mediante el uso de cookies, te mostraremos
          publicidad personalizada adaptada a tus gustos y preferencias, siempre
          que contemos con tu consentimiento para ello. No se tomarán decisiones
          automatizadas en base a dicho perfil que produzcan efectos jurídicos o
          significativos para ti. El perfil comercial que se elabore a través de
          las cookies podrá ser utilizado por sitio de terceros para mostrarte
          publicidad personalizada. Puedes configurar tus preferencias según lo
          indicado en nuestra Política de Cookies.
        </p>
        <p>
          3.5. Enviar boletines y promociones con publicidad de otros sitios web
          de Fintech Software Solutions S.A.S y de socios o partners
          colaboradores.
        </p>
        <p>
          3.6. Utilizar tus datos para generar y compartir información agregada
          que no te identifica para fines analíticos y estadísticos, que nos da
          un mejor conocimiento de los usuarios de nuestra web en su conjunto y
          nos ayuda a ofrecerles un mejor servicio.
        </p>
        <p>
          3.7. Prevención de abusos y fraudes en el uso de nuestros servicios
          (por ejemplo, actividades fraudulentas, ataques de denegación de
          servicios, envío de spam, entre otros).
        </p>
        <p>
          3.8. Transferencia de datos a organismos y autoridades públicas,
          siempre y cuando sean requeridos de conformidad con las disposiciones
          legales y reglamentarias.
        </p>
        <p>
          La base legal para el tratamiento de tus Datos Personales es la
          ejecución de un contrato con Fintech Software Solutions S.A.S en
          relación con las finalidades indicadas en el apartado 3.1 y 3.2 para
          realizar una correcta gestión y prestación de nuestros servicios.
        </p>
        <p>
          La base legal correspondiente a las finalidades indicadas en los
          apartados 3.3, 3.4 y 3.5 anteriores radica en el consentimiento del
          usuario.
        </p>{" "}
        <p>
          Asimismo, el tratamiento de tus Datos Personales para la finalidad
          indicada en el apartado 3.6. y 3.7 anterior radica en el interés
          legítimo de Fintech Software Solutions S.A.S como el responsable del
          tratamiento.
        </p>
        <p>
          Adicionalmente, el tratamiento de tus Datos Personales para la
          finalidad indicada en el apartado 3.8 es necesario para el
          cumplimiento de obligaciones legales aplicables a Fintech Software
          Solutions S.A.S.
        </p>
        <h3>PLAZO DE CONSERVACIÓN DE LOS DATOS</h3>
        <p>
          Los Datos Personales que nos facilites serán conservados mientras tu
          cuenta en nuestro portal web siga activa o en la medida en que sea
          necesario para proporcionarte nuestros servicios, hasta que solicites
          su supresión, así como durante el tiempo necesario para cumplir las
          obligaciones legales.
        </p>
        <h3>DESTINATARIOS</h3>
        <p>
          Los Datos Personales que nos proporciones serán comunicados a la
          empresa ofertante o reclutadora de empleo (Usuarios Profesionales)
          cuando hayas aplicado a una oferta para posibilitar el contacto con
          esta última o cuando seas buscado en la base de datos del portal web
          (según el nivel de privacidad elegido).
        </p>
        <p>
          Por otro lado, durante el transcurso de nuestras actividades y para
          los mismos propósitos que los descritos en esta política de
          privacidad, tus Datos Personales podrán ser tratados por parte de
          nuestros proveedores de servicios especializados, incluidas empresas
          de nuestro grupo empresarial, que nos ayudan a proporcionar nuestros
          servicios y sólo cuando sea necesario, tales como servicios de
          hosting, infraestructura de sistemas, desarrollo de software, servicio
          de envío de notificaciones, soporte, comercialización, marketing,
          entre otros.
        </p>
        <p>
          Estos proveedores pueden estar ubicados en jurisdicciones que no
          proporcionan los mismos niveles de seguridad que la jurisdicción en
          donde te encuentras, en cuyo caso, Fintech Software Solutions S.A.S
          implementará los mecanismos jurídicos necesarios para proteger los
          datos personales. A estos efectos, Fintech Software Solutions S.A.S ha
          suscrito los correspondientes contratos de encargo de tratamiento con
          cada uno de los proveedores que nos prestan servicios para asegurar
          que los Datos Personales se traten con la debida confidencialidad,
          seguridad y únicamente para la finalidad para la que han sido
          compartidos, teniendo un acceso limitado a dicha información y
          actuando siguiendo las instrucciones de Fintech Software Solutions
          S.A.S.
        </p>
        <p>
          El tratamiento de los Datos Personales por parte de Fintech Software
          Solutions S.A.S implicará que los mismos sean transferidos a nivel
          internacional. Fintech Software Solutions S.A.S brindará los servicios
          en la nube a través de Amazon Web Services, empresa que se encuentra
          certificada por el Escudo de privacidad de la Unión Europea y Estados
          Unidos de América (UE-EE.UU).
        </p>
        <p>
          Tus Datos Personales también podrán ser comunicados en los casos que
          exista una obligación legal para ello.
        </p>
        <h3>BAJA DE COMUNICACIONES COMERCIALES Y ALERTAS</h3>
        <p>
          Para dejar de recibir alertas o comunicaciones comerciales debes
          realizar la solicitud haciendo click en el enlace correspondiente que
          aparece en el pie del email recibido. En algunos supuestos será
          necesario indicar el motivo de dicha baja haciendo click en la opción
          aplicable. Asimismo, ten en cuenta que la solicitud de baja deberás
          realizarla por cada categoría de email que recibas.
        </p>
        <h3>DERECHOS</h3>
        <p>
          Como titular de los Datos Personales que tratamos de conformidad con
          lo especificado en la presente Política, tienes los siguientes
          derechos:
        </p>
        <p>
          Acceder o recopilar tus Datos: puedes solicitarnos una copia de tus
          Datos Personales. Cambiar o rectificar Datos: puedes editar parte de
          tus Datos Personales a través de tu área privada. Asimismo, podrás
          solicitarnos la modificación, actualización o corrección de tus Datos
          si éstos no son precisos. Rechazar, limitar, oponerte o restringir el
          uso de Datos: tienes derecho a solicitarnos que dejemos de usar la
          totalidad o parte de tus Datos Personales, salvo por motivos legítimos
          imperiosos o el ejercicio o defensa de posibles reclamaciones.
          Suprimir tus Datos: puedes requerir borrar o eliminar todos o parte de
          tus Datos Personales. Solicitar la portabilidad de tus Datos: cuando
          sea técnicamente posible puedes pedir la transmisión de tus Datos a
          otro responsable de tratamiento, sin que ello afecte a la licitud del
          tratamiento basado en el consentimiento previo a su retirada Debes
          tener en cuenta que los referidos derechos están sujetos a
          determinadas limitaciones, tal y como establece la legislación
          aplicable. Las solicitudes individuales correspondientes al ejercicio
          de los anteriores derechos terminarán de tratarse dentro del plazo
          asignado por las regulaciones legales pertinentes, que comienza a
          partir del momento en que Fintech Software Solutions S.A.S confirme la
          solicitud. Debes tener en cuenta que Fintech Software Solutions S.A.S
          podrá cobrar un cargo por la gestión de solicitudes posteriores que
          provengan de la misma persona, siempre que la legislación aplicable lo
          permita.
        </p>
        <p>
          Si deseas hacer uso de cualquiera de tus derechos puedes dirigirte a
          nosotros utilizando la información de contacto que aparece al inicio
          de la presente política y en el apartado 11; gestionaremos tu
          solicitud conforme a la legislación aplicable.
        </p>
        <p>
          Si tras contactarnos no se resuelve tu consulta o queja, puedes
          también contactar con la autoridad de protección de datos y demás
          organismos públicos competentes para cualquier reclamación derivada
          del tratamiento de tus Datos Personales.
        </p>
        <p>
          En relación a los Datos Personales facilitados a las empresas
          ofertantes de empleo o reclutadoras (Usuarios Profesionales) con los
          que hayas contactado, puedes ejercer tus derechos frente a los mismos.
        </p>
        <h3>SEGURIDAD</h3>
        <p>
          Fintech Software Solutions S.A.S se preocupa por garantizar la
          seguridad y confidencialidad de los Datos Personales. Por eso, se han
          adoptado medidas de seguridad y medios técnicos adecuados para evitar
          su pérdida, mal uso o su acceso sin autorización de los usuarios.
          También Fintech Software Solutions S.A.S dispone de procedimientos de
          actuación ante cualquier sospecha de violación de la seguridad de los
          Datos Personales que tratamos. Fintech Software Solutions S.A.S
          realizará la pertinente notificación al usuario y a la autoridad
          aplicable en caso de una violación de la seguridad de los Datos
          Personales, cuando así lo exija la ley. Una vez Fintech Software
          Solutions S.A.S haya recibido los Datos Personales, Fintech Software
          Solutions S.A.S utilizará procedimientos estrictos y medidas de
          seguridad para evitar el acceso no autorizado.
        </p>
        <p>
          Sin perjuicio de lo anterior, cabe mencionar que lamentablemente, la
          transmisión de información a través de Internet no es completamente
          segura. Aunque Fintech Software Solutions S.A.S ha adoptado medidas de
          seguridad y medios técnicos para proteger los Datos Personales, no
          puede garantizar la seguridad de los datos transmitidos a través de
          Internet, por lo tanto, cualquier transmisión queda bajo tu propio
          riesgo.
        </p>
        <h3>POLÍTICA DE COOKIES</h3>
        <h4>¿Qué son las cookies?</h4>
        <p>
          Una cookie es un pequeño archivo colocado en tu computadora,
          smartphone u otro dispositivo electrónico que habilita las
          funcionalidades de nuestros sitios web. Por ejemplo, las cookies nos
          permiten identificar tu dispositivo, ofrecerte acceso seguro a
          nuestros sitios webs, e incluso nos ayudan a saber si alguien intenta
          acceder a tu cuenta desde otro dispositivo. Las cookies también hacen
          seguimiento de la sesión de usuario, mejoran el tiempo de carga de una
          web, evitan mostrar información reiterativa y nos ayudan a mostrarte
          anuncios relevantes para ti.
        </p>
        <h4>¿Por qué utilizamos cookies?</h4>
        <p>
          Para mejorar tu experiencia de navegación como usuario, siendo la
          mejor manera de ofrecerte contenido personalizado y de interés a
          través del portal web y aplicación móvil. Asimismo, nos permiten
          ofrecer publicidad relevante y basada en tus intereses que generan
          ingresos que nos permiten mantener la gratuidad de otros servicios.
        </p>
        <h4>¿Qué cookies utilizamos?</h4>
        <p>
          Las cookies que utilizamos en esta página web/aplicación móvil son las
          siguientes:
        </p>
        <p>
          Autenticación: permiten mostrar la información adecuada y a
          personalizar tu experiencia y nos ayudan a determinar si el usuario ha
          accedido o no a la cuenta
        </p>
        <p>
          Funcionalidades y servicios : son esenciales para el correcto
          funcionamiento de los servicios. Proporcionan funcionalidades y
          contenidos personalizados.
        </p>
        <p>
          Analíticas : Permiten monitorizar el rendimiento de nuestros sitios
          webs y herramienta online. Por ejemplo: analizar patrones de
          comportamiento, número de usuarios que acceden, secciones visitadas,
          duración de la navegación, crear informes estadísticos sobre el
          tráfico web, audiencia global.
        </p>
        <h4>¿Qué cookies de terceros utilizamos?</h4>
        <p>
          Las cookies de terceros que alojamos en nuestro portal web/aplicación
          móvil son las siguientes:
        </p>
        <p>
          Analíticas:Permiten monitorizar el rendimiento de nuestros sitios webs
          y/o herramienta, tal y como se ha indicado anteriormente.
        </p>
        <p>Los terceros que podrían tener acceso a esta información son:</p>
        <p>Google Analytics:</p>
        <p>
          <a
            href="http://www.google.com/intl/en/policies/technologies/cookies"
            target="_blank"
          >
            http://www.google.com/intl/en/policies/technologies/cookies
          </a>
        </p>
        <p>
          Para consultar la política de privacidad acerca de las cookies de los
          referidos terceros, recomendamos acceder a sus condiciones legales a
          través de los anteriores enlaces.
        </p>
        <p>
          Publicitarias: permiten gestionar y adaptar el contenido al servicio
          solicitado, y los espacios publicitarios ofertados en su caso, en
          nuestros sitios web. Así podemos analizar comportamientos de
          navegación en Internet y mostrar al usuario los anuncios que mejor se
          adapten a sus intereses. Además, las cookies son necesarias para
          gestionar campañas publicitarias, mediante el seguimiento de diversas
          pautas como número de veces que se ha visto un anuncio, o para mejorar
          y gestionar la exposición de anuncios, evitando al usuario la
          publicidad que ya se le ha mostrado.
        </p>
        <p>
          También las cookies publicitarias permiten informar, optimizar y
          reportar nuestras impresiones de anuncios, otros usos de los servicios
          de publicidad, las interacciones con estas impresiones y servicios de
          publicidad repercuten en las visitas al sitio, y ofrecer publicidad
          basada en anteriores visitas que el usuario ha realizado a nuestra
          web.
        </p>
        <p>Control y desactivación de cookies</p>
        <p>
          Es posible desactivar y/o eliminar las cookies, siguiendo las
          indicaciones del navegador de Internet. Sin embargo, debe tenerse en
          cuenta que, en este caso, es posible que se reduzca considerablemente
          tu capacidad para utilizar nuestros servicios.
        </p>
        <p>Alternativas externas:</p>
        <p>
          ‘Opt-out’ (desactivación) para cada tipo de cookies: el uso de este
          sistema puede instalar en tu computadora una cookie “de rechazo” para
          hacer efectiva la petición de desactivación de cookies solicitada.
          Otras herramientas de terceros disponibles online: te permiten
          detectar todas las cookies de cada web visitada y gestionar su
          desactivación. Sistemas “opt-out” disponibles para Google Analytics:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
            https://tools.google.com/dlpage/gaoptout/{" "}
          </a>
          Fintech Software Solutions S.A.S no se hace responsable del contenido
          y veracidad de las políticas de privacidad de los terceros
          referenciadas en esta política de cookies. Para resolver cualquier
          duda relacionada, deberás contactar con nosotros a través del
          formulario de contacto. EDAD MÍNIMA Para acceder o utilizar nuestro
          portal web debes tener 16 años de edad o más. Si eres menor de 18 años
          o menor a la mayoría legal de tu país, tu uso de nuestro portal web
          debe estar bajo la supervisión de tus padres o tutor u otro adulto
          responsable. Si tenemos conocimiento de que un menor de edad nos ha
          proporcionado información sin autorización parental, eliminaremos
          dicha información y suprimiremos la cuenta de referido menor.
        </p>
        <h3>ACTUALIZACIONES</h3>
        <p>
          Podemos actualizar esta Política de Privacidad mediante la publicación
          de una versión actualizada en el Portal Web. Si realizamos alguna
          modificación que consideremos que pudiera afectar a tus derechos te lo
          notificaremos por email o mediante un aviso en nuestros sitios web.
        </p>
        <p>
          Te recomendamos que revises de forma periódica la Política de
          Privacidad para estar actualizado de todas las novedades. Asimismo,
          declaras que tu uso continuado de nuestros servicios, tras publicar o,
          en su caso, enviar un aviso acerca de nuestros cambios en esta
          Política de privacidad implica que el tratamiento de tus Datos
          Personales tendrá lugar conforme a la Política de privacidad
          actualizada.
        </p>
        <p>
          Si no estás de acuerdo con cualquiera de los cambios, gozas del pleno
          derecho a cerrar tu cuenta de usuario del portal web.
        </p>
        <h3>INFORMACIÓN DE CONTACTO</h3>
        <p>
          Si deseas hacer uso de cualquiera de tus derechos y si tienes alguna
          pregunta o queja sobre esta Política de Privacidad puedes contactar
          con nosotros en la dirección y/o formulario de contacto indicados en
          el apartado 1.
        </p>
        <p>
          Debes facilitarnos la mayor información posible sobre tu solicitud:
          nombre y apellidos, dirección de correo electrónico que utilizas para
          nuestro portal web y los motivos de tu solicitud, especificando, en su
          caso, el derecho que deseas ejercer. Asimismo, será necesario
          acompañar la documentación necesaria para sustentar la solicitud y dar
          trámite a la misma.
        </p>
        <div className="privacy-policy-container">
          <h2>POLÍTICA DE PRIVACIDAD PARA EMPRESAS O USUARIOS PROFESIONALES</h2>
          <p>
            La presente política de privacidad explica quién es el responsable
            de los datos de carácter personal de los representantes, empleados
            y/o usuarios de las empresas o entidades Clientes (también
            denominados, “Usuarios Profesionales” u “Ofertantes de Empleo”) que
            actúan en representación y/o utilizan los servicios, productos y/o
            herramientas online ofrecidas por Fintech Software Solutions S.A.S
            (en adelante los “Datos Personales” o “Datos Personales de los
            Clientes”) y cómo se recopilan, comparten y tratan los referidos
            Datos Personales, así como los derechos que tienen los
            representantes, empleados y/o usuarios de los Clientes (en adelante,
            los “Usuarios de la Empresa Cliente”) al respecto y cómo pueden
            ejercitarlos. Para Fintech Software Solutions S.A.S es fundamental
            mantener la privacidad y seguridad de los Datos Personales de los
            Clientes.
          </p>
          <h3>RESPONSABLE</h3>
          <p>
            El Responsable del tratamiento de los datos de los Usuarios de la
            Empresa Cliente recabados por medio de este sitio web-herramienta
            online, es Fintech Software Solutions S.A.S, cuyos datos son los
            siguientes:
          </p>
          <p>
            <b>Nombre:</b> Fintech Software Solutions S.A.S. (en adelante
            “Fintech Software Solutions S.A.S”)
          </p>
          <p>
            <b>Contacto:</b> formulario de contacto.
          </p>
          <h3>DATOS QUE SE RECOPILAN</h3>
          <p>
            Fintech Software Solutions S.A.S recopila Datos Personales de los
            Usuarios de la Empresa Cliente para poder proporcionar a sus
            Clientes o Usuarios Profesionales los servicios contratados de la
            mejor manera posible. En concreto, Fintech Software Solutions S.A.S
            puede recopilar lo siguiente:
          </p>
          <p>
            Información personal de los Usuarios de la Empresa Cliente,
            proporcionada en el momento de suscribirse o registrarse, de la
            forma que sea aplicable, para utilizar los servicios de Fintech
            Software Solutions S.A.S, crear las distintas cuentas de usuario,
            publicar material o solicitar información, entre otros. También es
            posible que se solicite determinada información al Cliente cuando
            Fintech Software Solutions S.A.S preste asesoramiento en el uso de
            los servicios. Dicha información personal podrá ser: nombre
            completo, cargo, dirección de correo electrónico profesional, número
            de teléfono profesional y, en su caso, contraseña, debiendo indicar
            el nombre de la Empresa Cliente. Detalles de las acciones que los
            usuarios de la Empresa Cliente llevan a cabo a través de nuestros
            sitios web y/o herramienta online y de la prestación de nuestros
            servicios, en caso de que ello resulte de aplicación. Detalles de
            las visitas a nuestros sitios web y/o herramienta online por parte
            de los usuarios incluyendo, pero no limitado a, datos de tráfico,
            dirección IP, datos de ubicación, duración de la actividad y otros
            datos de comunicación, sistema operativo, navegador web y los
            recursos a los que accedan. Por otro lado, Fintech Software
            Solutions S.A.S recopila Datos a través de cookies de la forma en
            que se describe más abajo en nuestra Política de Cookies.
          </p>
          <h3>FINALIDADES Y LEGITIMACIÓN</h3>
          <p>
            Fintech Software Solutions S.A.S trata los Datos Personales con un
            objetivo específico y sólo se tratan aquellos Datos que son
            pertinentes para cumplir ese objetivo y según lo especificado en la
            presente Política de Privacidad. En este sentido, los Datos
            Personales de los Usuarios de la Empresa Cliente podrán ser tratados
            para las siguientes finalidades, según sea de aplicación:
          </p>
          <p>
            prestación correcta de los servicios y para atender las consultas
            que pueda plantear el Cliente o para notificarle acerca de cambios
            en los servicios y proporcionarle información que sea relevante para
            el uso de los mismos.
          </p>
          <p>
            Gestionar la suscripción y moderar, de forma anónima, los
            comentarios facilitados, en su caso, en nuestro Blog de contenidos
            relacionados con los servicios, actividades y productos de nuestro
            grupo empresarial.
          </p>
          <p>
            Envío de comunicaciones comerciales, encuestas, boletines,
            invitaciones a webinars, para informar de nuestras novedades,
            productos, servicios y eventos, entre otros.
          </p>
          <p>
            Customizar, optimizar y mejorar la experiencia del Cliente con los
            sitios web y/o la herramienta online.
          </p>
          <p>
            Envío de alertas técnicas, reportes, actualizaciones, notificaciones
            de seguridad y cualquier otra comunicación relativa a los servicios.
          </p>
          <p>
            Atender las solicitudes de información y/o consultas efectuadas por
            el usuario a través del sitio web
          </p>
          <p>
            Envío de comunicaciones comerciales, boletines y/o promociones con
            publicidad de otros sitios web o productos de Fintech Software
            Solutions S.A.S y de otros socios o partners colaboradores
          </p>
          <p>
            Mejorar nuestros servicios mediante el estudio del comportamiento de
            los usuarios de la Empresa Cliente a través de cookies para adaptar
            el sitio web y/o herramienta online a sus necesidades y gustos. El
            usuario puede gestionar sus cookies siguiendo las instrucciones
            indicadas en nuestra Política de Cookies.
          </p>
          <p>
            Mostrar publicidad inteligente, en base a los datos de navegación
            que se recojen mediante el uso de cookies. Se mostrará publicidad
            personalizada adaptada a los gustos y preferencias del usuario,
            siempre que se cuente con su consentimiento para ello. No se tomarán
            decisiones automatizadas en base a dicho perfil que produzcan
            efectos jurídicos o significativos para el usuario. El perfil
            comercial que se elabore a través de las cookies podrá ser utilizado
            por sitio de terceros para mostrar publicidad personalizada. Es
            posible configurar las preferencias según lo indicado en nuestra
            Política de Cookies.
          </p>
          <p>
            para generar y compartir información agregada con fines analíticos y
            estadísticos sin identificar a los usuarios y que nos ofrece un
            mejor conocimiento de los usuarios de nuestros servicios en su
            conjunto y permite ofrecer un mejor servicio. Así como crear
            estadísticas que puedan ser utilizadas para cualquier propósito,
            siempre y cuando dichas estadísticas no identifiquen a ningún
            usuario de las Empresa Clientes ni contengan Datos Personales.
          </p>
          <p>prevención de abusos y fraudes en el uso de nuestros servicios.</p>
          <p>
            transferencia de datos a organismos y autoridades públicas, siempre
            y cuando sean requeridos de conformidad con las disposiciones
            legales y reglamentarias.
          </p>
          <p>
            La base legal para la recogida y tratamiento de los Datos Personales
            es la ejecución de un contrato en relación con las finalidades
            indicadas en el apartado a), b), c), d) y e) para realizar una
            correcta gestión y prestación de nuestros servicios.
          </p>
          <p>
            La base legal correspondiente a la finalidad indicada en el apartado
            f), g), h) e i) radica en el consentimiento de los Usuarios de la
            Empresa Cliente.
          </p>
          <p>
            Asimismo, la recogida y el tratamiento de Datos Personales para la
            finalidad indicada en el apartado j) y k) anterior radica en el
            interés legítimo de Fintech Software Solutions S.A.S como
            responsable del tratamiento.
          </p>
          <p>
            Adicionalmente, el tratamiento de los Datos Personales para la
            finalidad indicada en el apartado l) es necesario para el
            cumplimiento de obligaciones legales aplicables a Fintech Software
            Solutions S.A.S.
          </p>
          <h3>PLAZO DE CONSERVACIÓN DE LOS DATOS</h3>
          <p>
            Conservaremos los Datos Personales durante el tiempo que sea
            necesario para que Fintech Software Solutions S.A.S proporcione los
            servicios, hasta el momento en que el Cliente solicite la
            eliminación de su cuenta o de los Datos Personales; así como durante
            el tiempo necesario para cumplir las obligaciones legales.
          </p>
          <h3>DESTINATARIOS</h3>
          <p>
            Los Datos Personales podrán ser tratados por aquellos proveedores de
            servicios especializados -incluidas empresas del grupo de Fintech
            Software Solutions S.A.S-, que nos ayudan a proporcionar nuestros
            servicios y sólo cuando sea necesario, tales como servicios de
            hosting, infraestructura de sistemas, desarrollo de software,
            servicio de envío de notificaciones, soporte, software de
            video-entrevista, comercialización, marketing, entre otros.
          </p>
          <p>
            Estos proveedores pueden estar ubicados en jurisdicciones que no
            proporcionan los mismos niveles de seguridad que la jurisdicción en
            donde te encuentras, en cuyo caso, Fintech Software Solutions S.A.S
            implementará los mecanismos jurídicos necesarios para proteger los
            datos personales. A estos efectos, Fintech Software Solutions S.A.S
            ha suscrito los correspondientes contratos de encargo de tratamiento
            con cada uno de los proveedores que nos prestan servicios para
            asegurar que los Datos Personales del Cliente se traten con la
            debida confidencialidad, seguridad y únicamente para la finalidad
            para la que han sido compartidos, teniendo un acceso limitado a
            dicha información y actuando siguiendo las instrucciones de Fintech
            Software Solutions S.A.S.
          </p>
          <p>
            El tratamiento de los Datos Personales por parte de Fintech Software
            Solutions S.A.S implicará que los mismos sean transferidos a nivel
            internacional. Fintech Software Solutions S.A.S brindará los
            servicios en la nube a través de Amazon Web Services, empresa que se
            encuentra certificada por el Escudo de privacidad de la Unión
            Europea y Estados Unidos de América (UE-EE.UU).
          </p>
          <p>
            Los Datos Personales también podrán ser comunicados en los casos que
            exista una obligación legal para ello.
          </p>
          <h3>DERECHOS</h3>
          <p>
            En relación con los Datos Personales que Fintech Software Solutions
            S.A.S trata para la prestación de sus servicios, el Cliente/los
            Usuarios de la Empresa Cliente tienen los siguientes derechos:
          </p>
          <p>
            Acceder o recopilar sus datos: es posible solicitar una copia de los
            Datos Personales. Rectificar los datos: es posible solicitar a
            través del área privada la rectificación de los Datos Personales
            facilitados. Rechazar, limitar, oponerse o restringir el uso de
            datos: es posible solicitar que Fintech Software Solutions S.A.S
            deje de usar la totalidad o parte de los Datos Personales, salvo que
            dicho acceso deba efectuarse por motivos legítimos imperiosos o
            debido al ejercicio o defensa de posibles reclamaciones. Suprimir
            sus datos: es posible solicitar borrar o eliminar todos o parte de
            los Datos Personales. Solicitar la portabilidad de los datos: cuando
            sea técnicamente posible, se puede pedir la transmisión de los Datos
            Personales a otro responsable de tratamiento, sin que ello afecte a
            la licitud del tratamiento basado en el consentimiento previo a su
            retirada. Debe tenerse en cuenta que los referidos derechos están
            sujetos a determinadas limitaciones, tal y como establece la
            legislación aplicable. Las solicitudes individuales correspondientes
            al ejercicio de los anteriores derechos terminarán de tratarse
            dentro del plazo asignado por las regulaciones legales pertinentes,
            que comienza a partir del momento en que Fintech Software Solutions
            S.A.S confirme su solicitud. Debes tener en cuenta que Fintech
            Software Solutions S.A.S podrá cobrar un cargo por solicitudes
            posteriores que provengan de la misma persona, siempre que la
            legislación aplicable lo permita.
          </p>
          <p>
            Para hacer uso de los indicados derechos el Cliente y/o los Usuarios
            de la Empresa Cliente pueden dirigirse a Fintech Software Solutions
            S.A.S utilizando la información de contacto que aparece en el
            apartado 1 de esta política. Fintech Software Solutions S.A.S
            gestionará la solicitud conforme a la legislación aplicable.
          </p>
          <p>
            Si tras el contacto con Fintech Software Solutions S.A.S no se
            resuelve la consulta o queja, también es posible contactar con la
            autoridad de protección de datos y demás organismos públicos
            competentes para cualquier reclamación derivada del tratamiento de
            los Datos Personales.
          </p>
          <h3>SEGURIDAD</h3>
          <p>
            Fintech Software Solutions S.A.S se preocupa por garantizar la
            seguridad y confidencialidad de los Datos Personales. Por eso, se
            han adoptado medidas de seguridad y medios técnicos adecuados para
            evitar su pérdida, mal uso o su acceso sin autorización del Cliente.
            También Fintech Software Solutions S.A.S dispone de procedimientos
            de actuación ante cualquier sospecha de violación de la seguridad de
            los Datos Personales que tratamos. Fintech Software Solutions S.A.S
            realizará la pertinente notificación al Cliente y a la autoridad
            aplicable en caso de una violación de la seguridad de los Datos
            Personales, cuando así lo exija la ley. Una vez Fintech Software
            Solutions S.A.S haya recibido los Datos Personales, Fintech Software
            Solutions S.A.S utilizará procedimientos estrictos y medidas de
            seguridad para evitar el acceso no autorizado.
          </p>
          <p>
            Sin perjuicio de lo anterior, cabe mencionar que lamentablemente, la
            transmisión de información a través de Internet no es completamente
            segura. Aunque Fintech Software Solutions S.A.S ha adoptado medidas
            de seguridad y medios técnicos para proteger los Datos Personales,
            no puede garantizar la seguridad de los datos transmitidos a través
            de Internet, por lo tanto, cualquier transmisión queda bajo el
            propio riesgo del Cliente/los Usuarios de la Empresa Cliente.
          </p>
          <p>Última actualización: 15 de abril de 2021</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
