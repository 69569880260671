import { useState } from "react";

const useInitialState = () => {
    // To know if an user is logged in
    let isLogged;
    const userLogged = JSON.parse(localStorage.getItem('userInformation'));
    if(userLogged) {
        isLogged = true;
    } else {
        isLogged = false;
    }
    
    // To know if the user is talent or company
    let useRole = 'no';
    if(userLogged && userLogged.role) {
        useRole = userLogged.role;
    }
    const [role, setRole] = useState(useRole);

    // Flag to show only the logo in header
    const [showOnlyLogo, setShowOnlyLogo] = useState(false);
    const changeHeaderLayout = (layout) => { setShowOnlyLogo(layout) };

    return {
        isLogged,
        role,
        showOnlyLogo,
        changeHeaderLayout
    };
};

export default useInitialState;