// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let firebaseConfig = {};

if (process.env.REACT_APP_ENV === 'prod') {
  firebaseConfig = {
    apiKey: "AIzaSyCpW9VGFA15QdwKLLyaO0KXYifxJzL978Y",
    authDomain: "staffing-prod-698af.firebaseapp.com",
    projectId: "staffing-prod-698af",
    storageBucket: "staffing-prod-698af.appspot.com",
    messagingSenderId: "617214278894",
    appId: "1:617214278894:web:1812a7325547986dc9c7ff",
    measurementId: "G-X40PBN4X48"
  };
} else if (process.env.REACT_APP_ENV === 'dev') {
  firebaseConfig = {
    apiKey: "AIzaSyCO9RN6w34Gk-5q-6mQHrY9N9AQelNEetA",
    authDomain: "staffing-dev-6ca7a.firebaseapp.com",
    projectId: "staffing-dev-6ca7a",
    storageBucket: "staffing-dev-6ca7a.appspot.com",
    messagingSenderId: "584691380850",
    appId: "1:584691380850:web:930af60ed97e244bf5c8eb"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyC8bVwGxGHZ6XX6quTqJnJEfV_Fa6OmJvk",
    authDomain: "staffing-local.firebaseapp.com",
    projectId: "staffing-local",
    storageBucket: "staffing-local.appspot.com",
    messagingSenderId: "573102495186",
    appId: "1:573102495186:web:929e27adf019b3863fa40b",
    measurementId: "G-VLGM0MNG0H"
  };
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;