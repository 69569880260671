import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// ----- Custom ----- //
import useJobsRequests from '../../hooks/backend/useJobs';
import useCompany from '../../hooks/backend/useCompany';
import useRating from '../../hooks/backend/useRating';
import useHistoryRequest from '../../hooks/backend/useHistory';

// ----- Components ----- //
import LoadingComponent from '../../components/LoadingComponent';
import ProfileHeader from '../../components/profile/ProfileHeaderComponent';
import CompanyJobsProfile from '../../components/profile/CompanyJobsProfile';
import RatingModal from '../../components/rating/RatingModal';
import ModalComponent from '../../components/ModalComponent';

import '../../styles/pages/my-profile.scss';


function SpecificCompanyProfile() {

    // ========== States ============ //
    const [profileInformation, setProfileInformation] = useState({});
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [location, setLocation] = useState('');
    const [jobsList, setJobsList] = useState([]);
    const [rating, setRating] = useState(5);
    const [ratingInfo, setRatingInfo] = useState({});
    const [allowedToRate, setAllowedToRate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ========== Custom ============
    const { getCompanyProfileRequest } = useCompany();
    const { getJobs } = useJobsRequests();
    const { validateUserCompanyHistoryRequest } = useHistoryRequest();
    const { getCompanyRating, validateRating } = useRating();

    // ========== Routing and params ============ //
    const { companyUuid } = useParams();

    // ========== References ============ //
    const modalRef = useRef();
    const ratingRef = useRef();

    // ========== Backend request ========== //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        // Company profile
        async function getCompanyInformation() {
            try {
                const companyResponse = await getCompanyProfileRequest(companyUuid);
                setLocation(`${companyResponse.city.name}, ${companyResponse.city.state.name}, ${companyResponse.city.state.country.name}`);
                setProfileInformation(companyResponse);
            } catch (error) {
                console.error(error.message);
            }
        }

        // Returns the company rating
        async function getCompanyRatingFunction() {
            try {
                const companyResponse = await getCompanyRating(companyUuid);
                if (companyResponse.avgRating) {
                    setRating(companyResponse.avgRating);
                }
            } catch (error) {
                console.error(error.message);
            }
        }

        // Returns the company rating
        async function validateRatingFunction() {
            try {
                const ratingExist = await validateRating(companyUuid, JSON.parse(localStorage.getItem('userInformation')).user.uuid);
                if (ratingExist?.id) {
                    setRatingInfo(ratingExist);
                }
            } catch (error) {
                console.error(error.message);
            }
        }

        async function jobListFunction() {

            // Build filters object
            let filters = {
                limit: 4,
                oldest: 1,
                company: companyUuid
            }

            await getJobs(filters)
                .then(response => {
                    setJobsList(response.jobs);
                })
                .catch(console.error)
        };

        // Validate that an user and a company have worked together
        async function isAllowedToRateFunction() {
            const userUuid = JSON.parse(localStorage.getItem('userInformation')).user.uuid;
            await validateUserCompanyHistoryRequest(userUuid, companyUuid)
                .then(response => {
                    if (response.length !== 0) setAllowedToRate(true);
                })
                .catch(console.error)
        };

        async function fetchData() {
            try {
                await Promise.all([
                    !profileInformation.uuid ? getCompanyInformation() : Promise.resolve([]),
                    getCompanyRatingFunction(),
                    validateRatingFunction(),
                    jobListFunction(),
                    isAllowedToRateFunction()
                ])
                    .then(() => setIsLoading(false))
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                        setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
                        openModal();
                    })
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();


    }, [isInitialMount]);

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();
    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null
    };

    // Rating modal functions
    const openRatingModal = () => ratingRef.current.handleShow();
    const closeRatingModal = () => ratingRef.current.handleClose();

    return (
        <Fragment>

            {isLoading && <LoadingComponent />}
            <RatingModal
                ref={ratingRef}
                handleClose={closeRatingModal}
                name={profileInformation.name}
                ratingInfo={ratingInfo}
                qualifiedUuid={companyUuid}
            />

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <section id="profile-area" className="py40">
                <div className="container">
                    {/* <!-- Profile header --> */}
                    <ProfileHeader
                        type={'visitor'}
                        profileImageUrl={profileInformation.profileImageUrl}
                        name={profileInformation.name}
                        location={location}
                        description={profileInformation.description}
                        footer={{ website: profileInformation.website }}
                        verified={profileInformation.verifiedCompany}
                        rating={rating}
                        handleOpenRatingModal={openRatingModal}
                        isAllowedToRate={allowedToRate}
                    />

                    {/* <!-- Jobs --> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 company-jobs">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-center">
                                <div className="profile-cart box-shadow">
                                    <div className="container">
                                        <div className="row company-profile-jobs-cart-inside">
                                            <div className="row company-profile-jobs-header">
                                                <h4 className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    Empleos de {profileInformation.name}
                                                </h4>
                                            </div>

                                            <>
                                                {
                                                    jobsList &&
                                                    jobsList.map(job => {
                                                        return (
                                                            <CompanyJobsProfile
                                                                type={'visitor'}
                                                                job={job}
                                                                key={job.uuid}
                                                            />
                                                        )
                                                    })
                                                }
                                            </>

                                            <div className="row py10 profile-footer justify-content-end">
                                                <div className="col-12 align-center justify-content-end">
                                                    <a href="/jobs">
                                                        <p> <strong> Ver más...</strong></p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    )
}

export default SpecificCompanyProfile