import React, { Fragment } from 'react';
import StarRating from '../rating/StarRating'

function ProfileHeader(props) {

    const {
        type,
        profileImageUrl,
        name,
        location,
        description,
        footer,
        verified,
        openUpdateProfileModal,
        openApplicationModal,
        jobInformationFlag,
        rating,
        handleOpenRatingModal,
        isAllowedToRate
    } = props;

    const handleIconClick = () => {
        const url = footer.website.startsWith('http') ? footer.website : `https://${footer.website}`;
        window.open(url, "_blank");
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-center">
                            <div className="profile-cart box-shadow">
                                {
                                    type !== 'visitor' &&
                                    <div className="edit-profile-button">
                                        <a href="" onClick={event => {
                                            event.preventDefault();
                                            openUpdateProfileModal()
                                        }}>
                                            <i className="fa-solid fa-user-pen" />
                                        </a>
                                    </div>
                                }
                                <div className="container profile-area">
                                    <div className="row profile-cart-inside">
                                        <div
                                            className={`col-lg-12 col-md-12 col-sm-12 col-12 profile-image center-section ${type === 'visitor' && 'mt4'}`
                                            }
                                        >
                                            {
                                                profileImageUrl
                                                    ?
                                                    <img src={profileImageUrl} alt="company" />
                                                    :
                                                    <img
                                                        src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                                                        alt="Profile Picture Default"
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                            }

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 profile-header">
                                            <div className="row profile-header-info">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 center-section">
                                                    <div className="profile-name">
                                                        <div className="justify-center text-center mt-1">
                                                            <h4 className="justify-center text-center mt-1">
                                                                {name}
                                                            </h4>
                                                            {
                                                                type !== 'talent' && verified
                                                                    ?
                                                                    <i className="fa-solid fa-building-circle-check company-check-icon" style={{ color: "#ffb701" }}></i>
                                                                    :
                                                                    ''
                                                            }
                                                        </div>
                                                        <div className="profile-location justify-center">
                                                            <i className="fa-solid fa-location-dot"></i>
                                                            <h6>{location}</h6>
                                                        </div>
                                                        <ul className="justify-center">
                                                            <li className="star-rating-container">
                                                                <StarRating onRate={null} defaultValue={rating} />
                                                                {
                                                                    type === "visitor" && isAllowedToRate
                                                                        ?
                                                                        <i 
                                                                        className="fa-solid fa-pen" 
                                                                        style={{ color: "var(--main-theme-color)", cursor: "pointer" }}
                                                                        onClick={() => handleOpenRatingModal()}
                                                                        ></i>
                                                                        :
                                                                        null
                                                                }

                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='text-center'>
                                                {description}
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        type === 'talent'
                                            ?
                                            <div className="row py10 profile-footer">
                                                <div className="col-12 align-center justify-content-center">
                                                    <i className="fa-solid fa-briefcase"></i>
                                                    <p className='text-center'>{footer.lastCompany}</p>
                                                </div>
                                                {/* <div className="col-4 align-center justify-content-center">
                                                    <i className="fa-solid fa-school"></i>
                                                    <p className='text-center'>{footer.lastEducation}</p>
                                                </div> */}
                                            </div>
                                            :
                                            type === 'visitor' && jobInformationFlag
                                                ?
                                                <div className="row py10 profile-footer justify-content-end">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                                                        <a
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                openApplicationModal()
                                                            }}
                                                            className="btn btn-theme btn-new-job profile-footer-button"
                                                        >
                                                            <i className="fa-regular fa-envelope"></i>
                                                            Contactar
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        footer.website &&
                                                        <div className="row py10 profile-footer">
                                                            <div
                                                                className="col-12 center-section"
                                                                onClick={handleIconClick}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <i className="fa-solid fa-globe"></i>
                                                                <p>
                                                                    {footer.website}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default ProfileHeader;