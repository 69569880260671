import React, { Fragment } from 'react';
import { Col, Container, Row } from "react-bootstrap";

import JobListFilters from './jobs/JobListFilters';
import ApplicationFilters from './applications/ApplicationFilters';
import MyJobsFilters from './jobs/MyJobsFilters';
import TalentAvailabilityFilters from './TalentAvailability/TalentAvailabilityFilters';
import useAnalytics from '../hooks/useAnalytics';

import '../styles/components/lateralFilters.scss'

function LateralJobsFilter(props) {

    const jobListAnalyticsTracker = useAnalytics('Job List');

    return (
        <Fragment>
            <Container fluid id="fix-left">
                <Row>
                    <Col id='lateral-filter-container' className='box-shadow' xs={10} >
                        <Row id='lateral-filter-header'>
                            <h4>Filtrar empleos</h4>
                            <i
                                className="fa-sharp fa-solid fa-xmark fa-2xl lateral-filter-go-out"
                                onClick={() => props.setToggleLateralFilters(false)}
                            />
                        </Row>
                        <Row>
                            {
                                props.type === 'applicants'
                                    ?
                                    <ApplicationFilters
                                        searchValue={props.searchValue}
                                        setSearchValue={props.setSearchValue}
                                        sortByOldest={props.sortByOldest}
                                        setSortByOldest={props.setSortByOldest}
                                        statusValue={props.statusValue}
                                        setStatusValue={props.setStatusValue}
                                    />
                                    :
                                    props.type === 'jobApplicants'
                                        ?
                                        <ApplicationFilters
                                            searchValue={props.searchValue}
                                            setSearchValue={props.setSearchValue}
                                            sortByOldest={props.sortByOldest}
                                            setSortByOldest={props.setSortByOldest}
                                            statusValue={props.statusValue}
                                            setStatusValue={props.setStatusValue}
                                            statusRequest={props.statusRequest}
                                        />
                                        :
                                        props.type === 'my-jobs'
                                            ?
                                            <MyJobsFilters
                                                searchValue={props.searchValue}
                                                setSearchValue={props.setSearchValue}
                                                sortByOldest={props.sortByOldest}
                                                setSortByOldest={props.setSortByOldest}
                                                categoryValue={props.categoryValue}
                                                setCategoryValue={props.setCategoryValue}
                                                disabledJobs={props.disabledJobs}
                                                setDisabledJobs={props.setDisabledJobs}
                                            />
                                            :
                                            props.type === 'talent-availability'
                                                ?
                                                <TalentAvailabilityFilters
                                                    searchValue={props.searchValue}
                                                    setSearchValue={props.setSearchValue}
                                                    setCityValue={props.setCityValue}
                                                    category={props.category}
                                                />
                                                :
                                                <JobListFilters
                                                    searchValue={props.searchValue}
                                                    setSearchValue={props.setSearchValue}
                                                    sortByOldest={props.sortByOldest}
                                                    setSortByOldest={props.setSortByOldest}
                                                    categoryValue={props.categoryValue}
                                                    setCategoryValue={props.setCategoryValue}
                                                    companySizeValue={props.companySizeValue}
                                                    setCompanySizeValue={props.setCompanySizeValue}
                                                    companyValue={props.companyValue}
                                                    setCompanyValue={props.setCompanyValue}
                                                    setCityValue={props.setCityValue}
                                                    setRemoteValue={props.setRemoteValue}
                                                    jobListAnalyticsTracker={jobListAnalyticsTracker}
                                                />
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default LateralJobsFilter