import React, { useContext } from 'react'

import AppContext from '../context/AppContext';

import '../styles/components/footer.scss';

function Footer() {

    // Context
    const { isLogged } = useContext(AppContext);

    return (
        <div className="footer-container">
            {/* <!--Footer Start --> */}
            {
                !isLogged &&

                <footer id="footer">
                    <div className="container justify-center">
                        <div className="row footer-content">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div className="footer-list-wedget pl20 single-item-mt-3 responsive-mt-32">
                                    <div className="footer-heading">
                                        <h5>Links</h5>
                                    </div>
                                    <div className="footer-list pt20">
                                        <ul>
                                            <li><a href="#">Home </a></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                <div className="footer-list-wedget pl20 single-item-mt-3 responsive-mt-32">
                                    <div className="footer-heading">
                                        <h5>Nosotros</h5>
                                    </div>
                                    <div className="footer-list pt20">
                                        <ul>
                                            <li><a href="/#how-it-work-home4">Nosotros</a></li>
                                            <li><a href="/#service">Servicios</a></li>
                                            <li><a href="/contact-us"> Contáctanos</a></li>
                                            <li><a href="/privacy-policy"> Política de privacidad</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 justify-center">
                                <div className="footer-list-wedget pl20 responsive-mt-32">
                                    <div className="footer-heading">
                                        <h5>Información de Contacto</h5>
                                    </div>
                                    <div className="footer-contact-area footer-list pt20">
                                        <ul>
                                            <li>
                                                <i className="fa-solid fa-location-dot" /> Manizales, Caldas, Colombia
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-phone" /><a href="tel:573106224833">(+57) 3106224833</a>
                                            </li>
                                            <li>
                                                <i className="fa-solid fa-envelope" /><a href="mailto:soporte@datastorecolombia.co">soporte@datastorecolombia.co</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-img">
                        <img src="https://staffing-app.s3.amazonaws.com/landing/footer.svg" alt="footer" />
                    </div>
                </footer >
            }
            {/* <!--Footer End-- > */}

            {/* < !--Copyright Start-- > */}
            <div className="copy-right">
                <div className="container">
                    <div className="row align-items-center" style={{ width: '100%' }}>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-text">
                                <p>Fintech Software Solutions © 2024. All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-icon text-lg-right justify-center">
                                <ul>
                                    <li>
                                        <a href="https://www.instagram.com/talentorapido.co/" target='_blank' rel='noopener noreferrer'>
                                            <i className="fab fa-instagram icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:soporte@datastorecolombia.co" target='_blank' rel='noopener noreferrer'>
                                            <i className="fas fa-envelope icon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Copyright End --> */}
        </div >
    )
}

export default Footer