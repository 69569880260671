import React, { useState } from 'react';
import '../../styles/components/star-rating.scss'; // Asegúrate de que el archivo CSS esté importado

const StarRating = ({ onRate, defaultValue, inProcess }) => {
    const totalStars = 5;

    const [hoveredStar, setHoveredStar] = useState(null);
    const [selectedStar, setSelectedStar] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredStar(index);
    };

    const handleMouseLeave = () => {
        setHoveredStar(null);
    };

    const handleClick = (index) => {
        setSelectedStar(index);
        onRate(index + 1); // Rating is the index
    };

    const getFilledStarsPercentage = () => {
        if (inProcess) {
            const rating = hoveredStar !== null ? hoveredStar + 1 : (selectedStar !== null ? selectedStar + 1 : 0);
            if (rating === 0 && defaultValue) {
                return (defaultValue / totalStars) * 100;
            } else {
                return (rating / totalStars) * 100;
            }
        } else {
            return (defaultValue / totalStars) * 100;
        }
    };

    return (
        <div className="star-rating">
            {/* Subtract -3 to adjust de css */}
            <span className="filled-stars" style={{ width: `${getFilledStarsPercentage() - 3}%` }}>
                {Array(totalStars).fill().map((_, i) => (
                    <i
                        key={i}
                        className="fa fa-star"
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(i)}
                    ></i>
                ))}
            </span>
            <span className="empty-stars">
                {Array(totalStars).fill().map((_, i) => (
                    <i
                        key={i}
                        className="fa fa-star"
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(i)}
                    ></i>
                ))}
            </span>
        </div>
    );
};

export default StarRating;
