import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// ----- Components ----- //
import TimePreferencesModalSection from '../components/preferences/TimePreferencesModalSection';

// ----- Custom ----- //
import usePreferences from '../hooks/backend/usePreferences';

import '../styles/components/category-modal.scss';

const CreatePreferencesModal = forwardRef((props, ref) => {

    const {
        handleClose,
        categoryList,
        setCategoryList,
    } = props;

    // ========== States ============ //
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState({});
    const [isInitialMount, setIsInitialMount] = useState(true);

    const [categoryPreferencesList, setCategoryPreferencesList] = useState([]);

    // Days
    const [monday, setMonday] = useState({
        day: "Monday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [tuesday, setTuesday] = useState({
        day: "Tuesday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [wednesday, setWednesday] = useState({
        day: "Wednesday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [thursday, setThursday] = useState({
        day: "Thursday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [friday, setFriday] = useState({
        day: "Friday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [saturday, setSaturday] = useState({
        day: "Saturday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });
    const [sunday, setSunday] = useState({
        day: "Sunday",
        allDayFlag: true,
        availability: true,
        startHour: "09:10",
        finishHour: "22:10"
    });

    // ----- Tooltips ----- //
    const [TooltipMondayHour, setTooltipMondayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipTuesdayHour, setTooltipTuesdayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipWednesdayHour, setTooltipWednesdayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipThursdayHour, setTooltipThursdayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipFridayHour, setTooltipFridayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipSaturdayHour, setTooltipSaturdayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });
    const [TooltipSundayHour, setTooltipSundayHour] = useState({ status: false, content: 'La hora final debe ser mayor a la hora inicial' });

    // ========== References ============ //
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));

    // ========== Custom hooks ============
    const { createCategoryPreferenceRequest, createTimePreferenceRequest } = usePreferences();

    // ========== Backend request ============ //

    // --- Create preferences --- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function createCategoryPreferenceFunction(data) {
            await createCategoryPreferenceRequest(data)
                .then(response => console.log(response))
                .catch(error => console.error(error))
        }

        async function createTimePreferenceFunction(data) {
            await createTimePreferenceRequest(data)
                .then(response => console.log(response))
                .catch(error => console.error(error))
        }

        async function createPreferences() {
            try {
                if (request.timeRequest || request.categoryRequest) {
                    await Promise.all([
                        request.timeRequest && createTimePreferenceFunction(request.timeRequest),
                        request.categoryRequest && createCategoryPreferenceFunction(request.categoryRequest),
                    ])
                        .then(() => window.location.reload())
                        .catch(error => console.error(error))

                }
            } catch (error) {
                console.error(error.message);
            }
        }

        createPreferences();

    }, [null, [isInitialMount, request]])


    // ========== Functions ============ //
    const addCategoryPreference = (event) => {

        if (event.target.id) {
            const [selectedCategory, remainingCategories] = categoryList.reduce(
                ([selectedCategory, remainingCategories], currentCategory) => {
                    if (currentCategory.uuid === event.target.id) {
                        return [currentCategory, remainingCategories];
                    } else {
                        return [selectedCategory, [...remainingCategories, currentCategory]];
                    }
                },
                [null, []]
            );

            setCategoryPreferencesList([...categoryPreferencesList, selectedCategory]);
            setCategoryList(remainingCategories);
        }

    };

    const removeCategoryPreference = (event) => {

        if (event.target.id) {
            const [removedCategory, remainingCategories] = categoryPreferencesList.reduce(
                ([removedCategory, remainingCategories], currentCategory) => {
                    if (currentCategory.uuid === event.target.id) {
                        return [currentCategory, remainingCategories];
                    } else {
                        return [removedCategory, [...remainingCategories, currentCategory]];
                    }
                },
                [null, []]
            );

            setCategoryPreferencesList(remainingCategories);
            setCategoryList([...categoryList, removedCategory]);
        }

    };

    const handleCreatePreferences = (event) => {
        event.preventDefault();
        const userInformation = JSON.parse(localStorage.getItem('userInformation'));
        const userUuid = userInformation.user.uuid;

        // --- Category request --- //
        const categories = categoryPreferencesList.map(category => category.uuid);

        // --- Time request --- //
        let timePreferences = [];

        let errors = false;

        if (monday.availability) {
            if (!monday.allDayFlag && monday.finishHour < monday.startHour) {
                errors = true
                setTooltipMondayHour({ ...TooltipMondayHour, status: true });
            } else {
                delete monday.availability;
                timePreferences.push(monday);
            }
        }

        if (tuesday.availability) {
            if (!tuesday.allDayFlag && tuesday.finishHour < tuesday.startHour) {
                errors = true
                setTooltipTuesdayHour({ ...TooltipTuesdayHour, status: true });
            } else {
                delete tuesday.availability;
                timePreferences.push(tuesday);
            }
        }

        if (wednesday.availability) {
            if (!wednesday.allDayFlag && wednesday.finishHour < wednesday.startHour) {
                errors = true
                setTooltipWednesdayHour({ ...TooltipWednesdayHour, status: true });
            } else {
                delete wednesday.availability;
                timePreferences.push(wednesday);
            }
        }

        if (thursday.availability) {
            if (!thursday.allDayFlag && thursday.finishHour < thursday.startHour) {
                errors = true
                setTooltipThursdayHour({ ...TooltipThursdayHour, status: true });
            } else {
                delete thursday.availability;
                timePreferences.push(thursday);
            }
        }

        if (friday.availability) {
            if (!friday.allDayFlag && friday.finishHour < friday.startHour) {
                errors = true
                setTooltipFridayHour({ ...TooltipFridayHour, status: true });
            } else {
                delete friday.availability;
                timePreferences.push(friday);
            }
        }

        if (saturday.availability) {
            if (!saturday.allDayFlag && saturday.finishHour < saturday.startHour) {
                errors = true
                setTooltipSaturdayHour({ ...TooltipSaturdayHour, status: true });
            } else {
                delete saturday.availability;
                timePreferences.push(saturday);
            }
        }

        if (sunday.availability) {
            if (!sunday.allDayFlag && sunday.finishHour < sunday.startHour) {
                errors = true
                setTooltipSundayHour({ ...TooltipSundayHour, status: true });
            } else {
                delete sunday.availability;
                timePreferences.push(sunday);
            }
        }

        if (!errors) {
            setRequest({
                categoryRequest: { userUuid, categories },
                timeRequest: { userUuid, schedule: timePreferences }
            });
        }
    }


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preferencias</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <!-- Categories --> */}
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <h4>Categorías</h4>
                    </div>
                    <p>Escoge las categorías de tu preferencia</p>
                    <div className="category-preferences">
                        <ul onClick={addCategoryPreference}>
                            {
                                categoryList && categoryList.map(category => {
                                    if (category.uuid === 'e207272d-d465-4327-a80c-b76440cf7e03') return;
                                    return (
                                        <li
                                            key={category.uuid}
                                        >
                                            <p
                                                id={category.uuid}
                                            >
                                                {category.name}
                                            </p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {
                        categoryPreferencesList && categoryPreferencesList.length !== 0 &&
                        <>
                            <p>Tus categorías:</p>
                            <div className="category-preferences my-category-preferences">
                                <ul onClick={removeCategoryPreference}>
                                    {
                                        categoryPreferencesList.map(categoryPreference => {
                                            if (categoryPreference.uuid === 'e207272d-d465-4327-a80c-b76440cf7e03') return;
                                            return (
                                                <li key={categoryPreference.uuid} >
                                                    <p
                                                        id={categoryPreference.uuid}
                                                    >
                                                        {categoryPreference.name}
                                                    </p>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </>
                    }

                    {/* <!-- Time --> */}
                    <>

                        <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                            <h4>Horario</h4>
                        </div>
                        <p>Escoge el horario en el que podrías trabajar</p>

                        {/* <!-- Days --> */}
                        <TimePreferencesModalSection
                            title={'Lunes'}
                            day={monday}
                            setDay={setMonday}
                            TooltipStatus={TooltipMondayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Martes'}
                            day={tuesday}
                            setDay={setTuesday}
                            TooltipStatus={TooltipTuesdayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Miércoles'}
                            day={wednesday}
                            setDay={setWednesday}
                            TooltipStatus={TooltipWednesdayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Jueves'}
                            day={thursday}
                            setDay={setThursday}
                            TooltipStatus={TooltipThursdayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Viernes'}
                            day={friday}
                            setDay={setFriday}
                            TooltipStatus={TooltipFridayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Sábado'}
                            day={saturday}
                            setDay={setSaturday}
                            TooltipStatus={TooltipSaturdayHour}
                        />
                        <TimePreferencesModalSection
                            title={'Domingo'}
                            day={sunday}
                            setDay={setSunday}
                            TooltipStatus={TooltipSundayHour}
                        />
                    </>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={(event) => handleCreatePreferences(event)}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
})

export default CreatePreferencesModal