import api from '../../libs/API'
import useAuthentication from './useAuth';


const useRating = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Complete information
    const getTalentRating = async (talentUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const talentRating = await api.get(`/rating/user/${talentUuid}`, { headers });
            return talentRating.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getTalentRating(talentUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Country
    const getCompanyRating = async (companyUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const companyRating = await api.get(`/rating/company/${companyUuid}`, { headers });
            return companyRating.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCompanyRating(companyUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Specific
    const getSpecificRating = async (ratingId, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const response = await api.get(`/rating/${Number(ratingId)}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getSpecificRating(ratingId, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Specific
    const validateRating = async (qualifiedUuid, qualifierUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const params = { qualifiedUuid, qualifierUuid }
            const rating = await api.get(`/rating`, { headers, params });
            return rating.data;
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => validateRating(qualifiedUuid, qualifierUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create rating
    const createRatingRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/rating', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createRatingRequest(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update rating
    const updateRatingRequest = async (ratingId, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/rating/${ratingId}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateRatingRequest(ratingId, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };


    return { getTalentRating, getCompanyRating, getSpecificRating, createRatingRequest, updateRatingRequest,validateRating };
};

export default useRating;