import api from '../../libs/API'
import useAuthentication from './useAuth';

const usePreferences = () => {

    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // ===== Categories ===== //
    // Get the preferences of specific user
    const getFilteredUsersList = async (filters, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const { limit, page, category, jobUuid, day, city } = filters;

            // Filters
            let query = { limit };

            // Offset
            if (page) query.offset = (page - 1) * limit;

            if (category) query.category = category;
            if (day) query.day = day;
            if (city) query.city = city;
            if (jobUuid) query.jobUuid = jobUuid;

            // --- Request --- //
            const users = await api.get(`/users/preferences`, { params: query, headers });
            return users.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getFilteredUsersList(filters, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get the preferences of specific user
    const getUserCategoryPreferencesRequest = async (userUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const categoriesPreferences = await api.get(`/users/preferences/categories/${userUuid}`, { headers });
            return categoriesPreferences.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getUserCategoryPreferencesRequest(userUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create category request
    const createCategoryPreferenceRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/users/preferences/categories', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await createCategoryPreferenceRequest(data, true);
                    } catch (error) {
                        console.error(error.message);
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Delete category request
    const deleteCategoryPreferenceRequest = async (id, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.delete(`/users/preferences/categories/${id}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await deleteCategoryPreferenceRequest(id, true);
                    } catch (error) {
                        console.error(error.message);
                    }

                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };


    // ===== Time ===== //
    // Get the preferences of specific user
    const getUserTimePreferencesRequest = async (userUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const timePreferences = await api.get(`/users/preferences/time/${userUuid}`, { headers });
            return timePreferences.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getUserTimePreferencesRequest(userUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create time request
    const createTimePreferenceRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/users/preferences/time', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await createTimePreferenceRequest(data, true);
                    } catch (error) {
                        console.error(error.message);
                    }

                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update time request
    const updateTimePreferenceRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch('/users/preferences/time', { schedule: data }, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await updateTimePreferenceRequest(data, true);
                    } catch (error) {
                        console.error(error.message);
                    }

                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Delete time request
    const deleteTimePreferenceRequest = async (id, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.delete(`/users/preferences/time/${id}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await deleteTimePreferenceRequest(id, true);
                    } catch (error) {
                        console.error(error.message);
                    }

                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    return {
        createCategoryPreferenceRequest,
        createTimePreferenceRequest,
        getUserCategoryPreferencesRequest,
        getUserTimePreferencesRequest,
        getFilteredUsersList,
        updateTimePreferenceRequest,
        deleteCategoryPreferenceRequest,
        deleteTimePreferenceRequest
    };

};

export default usePreferences;