import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// Components
import TooltipComponent from '../TooltipComponent';
import ModalComponent from '../ModalComponent';

import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useEmailsRequests from '../../hooks/backend/useEmails';
import useHistoryRequests from '../../hooks/backend/useHistory';
import useDate from '../../hooks/useDates';
import useAnalytics from '../../hooks/useAnalytics';

const UpdateApplicationStatusModal = forwardRef((props, ref) => {

    const {
        handleClose,
        selectedApplication,
        editApplicationFlag,
        description
    } = props;

    // ========== Custom hooks ============
    const { updateStatusRequest } = useApplicantsRequests();
    const { sendRejectedEmailRequest, sendInterviewEmailRequest, sendHiredEmailRequest } = useEmailsRequests();
    const { createJobHistoryRecord } = useHistoryRequests();
    const { getTodayDateYMD } = useDate();
    const applicationsAnalyticsTracker = useAnalytics('Applications');

    // ========== States ============
    const [show, setShow] = useState(false);
    const [reason, setReason] = useState('');
    const [request, setRequest] = useState({});
    const [rejectConfirmation, setRejectConfirmation] = useState(false);

    const [companyApplicationStatusList, setCompanyApplicationStatusList] = useState([
        {
            id: 'rejected_by_company',
            className: 'text-danger',
            name: 'Rechazar aplicación'
        },
        {
            id: 'in_contact',
            className: 'text-primary',
            name: 'Solicitar contacto'
        },
        {
            id: 'hired',
            className: 'text-success',
            name: 'Contratar'
        }
    ]);

    const [talentApplicationStatusList, setTalentApplicationStatusList] = useState([
        {
            id: 'rejected_by_user',
            className: 'text-danger',
            name: 'Rechazar'
        },
        {
            id: 'in_contact',
            className: 'text-primary',
            name: 'Aceptar'
        },
    ]);

    const [statusSelected, setStatusSelected] = useState();

    // ----- Tooltips ----- //
    const [TooltipStatus, setTooltipStatus] = useState({ status: false, content: 'Este campo debe estar seleccionado' });

    // ----- Modal ----- //
    const [modalInformation, setModalInformation] = useState({
        title: 'Estado actualizado',
        content: 'El estado de la aplicación ha sido actualizado correctamente',
        button2Content: 'Volver',
        button2Redirect: `/my-applications`,
    });

    // ========== References ============
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));
    const form = useRef(null);
    const modalRef = useRef();


    // ========== Backend request ============
    useEffect(() => {

        if (request.status) {
            async function updateApplicationStatusFunction() {

                // Make request to backend
                await updateStatusRequest(selectedApplication.id, request)
                    .then(async () => {

                        if (request.status === 'rejected_by_company') {
                            applicationsAnalyticsTracker({ action: 'company_update_application_rejected', label: selectedApplication.id });
                            const data = { id: selectedApplication.id, rejectedBy: 'company' };
                            await sendRejectedEmailRequest(data)
                                .then((response) => {
                                    openModal()
                                    handleClose()
                                })
                        }

                        else if (request.status === 'rejected_by_user') {
                            applicationsAnalyticsTracker({ action: 'talent_reject_offer', label: selectedApplication.id });
                            const data = { id: selectedApplication.id, rejectedBy: 'talent' };
                            await sendRejectedEmailRequest(data)
                                .then((response) => {
                                    openModal()
                                    handleClose()
                                })
                        }

                        else if (request.status === 'in_contact') {
                            const data = { id: selectedApplication.id };
                            await sendInterviewEmailRequest(data)
                                .then((response) => {
                                    openModal()
                                    handleClose()
                                })
                        }

                        else if (request.status === 'hired') {
                            applicationsAnalyticsTracker({ action: 'company_update_application_hired', label: selectedApplication.id });
                            const emailData = { id: selectedApplication.id };
                            const applicationInfo = selectedApplication.application;
                            const historyData = {
                                userUuid: applicationInfo.user_uuid,
                                companyUuid: applicationInfo.job.companyUuid,
                                jobUuid: applicationInfo.job_uuid,
                                companyName: JSON.parse(localStorage.getItem('userInformation')).user.name,
                                position: applicationInfo.job.title,
                                description: applicationInfo.job.description,
                                remote: applicationInfo.job.remote,
                                startDate: getTodayDateYMD(true),
                                actualJobFlag: true,
                                type: applicationInfo.job.type
                            };
                            if (applicationInfo.job.cityId) historyData.cityId = applicationInfo.job.cityId;

                            const [emailResponse, jobHistoryResponse] = await Promise.all([
                                sendHiredEmailRequest(emailData),
                                createJobHistoryRecord(historyData),
                            ]);

                            openModal();
                            handleClose();
                        }
                    })
                    .catch(() => {
                        setModalInformation({
                            title: 'Error inesperado',
                            content: 'Ha ocurrido un error inesperado por favor recargue la página',
                            button2Content: 'Recargar',
                            button2Redirect: `reload`,
                        })
                        openModal()
                    })
            };

            // Validate first the rejection
            if (request.status.includes('rejected') && !rejectConfirmation) {
                setModalInformation({
                    title: 'Recharás una oferta',
                    content: "¿Estás seguro que quieres cambiar el estado a 'Rechazado'?",
                    button1Content: 'Volver',
                    button1Redirect: `close`,
                    button2Content: 'Si',
                    button2Redirect: `rejected`,
                });
                openModal();
            } else {
                updateApplicationStatusFunction();
            }

        } else {
            return;
        }

    }, [request, rejectConfirmation]);


    // ========== Functions ============
    const handleUpdateApplicationStatus = (event) => {
        event.preventDefault();

        // Validate if any status has been selected
        if (statusSelected === 'Estado' || !statusSelected) {
            setTooltipStatus({ ...TooltipStatus, status: true });
        } else {
            // Build request object
            let request = { status: statusSelected, description: reason };
            // if (reason) request.description = reason;

            // Send request
            setRequest(request);
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if ((!/[\]}>[<{]/.test(inputValue))) {
            setReason(inputValue);
        }
    };

    const handleStatusChange = (event) => {
        setTooltipStatus({ ...TooltipStatus, status: false });
        setStatusSelected(event.target.value);
    };

    const statusToShow = () => {

        // Reject any offer
        if (editApplicationFlag) {
            return (
                <option key={'rejected_by_user'} value={'rejected_by_user'} className='text-danger'>
                    Retirar oferta
                </option>
            );
        }

        if (selectedApplication.application?.status === 'requested_by_company') {
            return (
                talentApplicationStatusList && talentApplicationStatusList.map((statusObject) => {
                    return (
                        <option key={statusObject.id} value={statusObject.id} className={statusObject.className}>
                            {statusObject.name}
                        </option>
                    )
                }
                )
            );
        } else {
            return (
                companyApplicationStatusList && companyApplicationStatusList.map((statusObject) => {
                    if (selectedApplication.application && selectedApplication.application.status === 'requested_by_company' && statusObject.id === 'in_contact') return;
                    return (
                        <option key={statusObject.id} value={statusObject.id} className={statusObject.className}>
                            {statusObject.name}
                        </option>
                    )
                }
                )
            );
        }
    }

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        if (modalInformation.button1Redirect === 'close') {
            closeModal();
        }
    }

    function handleRedirectToButtonTwo() {
        // Reload page
        if (modalInformation.button2Redirect === 'reload') {
            window.location.reload()
        } else if (modalInformation.button2Redirect === 'rejected') { // Confirm rejection
            setRejectConfirmation(true);
        } else {
            window.location.href = modalInformation.button2Redirect
        }
    }

    return (
        <Fragment>
            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={handleRedirectToButtonTwo}
            />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Estado actual: {selectedApplication.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="aboutYourself"
                        ref={form}
                    >
                        <Form.Control
                            as="select"
                            name='status'
                            value={statusSelected}
                            onChange={handleStatusChange}
                            className='form-control form-select'>
                            <option>Estado</option>
                            {statusToShow()}
                        </Form.Control>
                        <TooltipComponent
                            attributeName={'status'}
                            showTrigger={TooltipStatus.status}
                            tooltipText={TooltipStatus.content}
                        />
                        <Form.Label className='mt4'>Cuéntale al aplicante la razón de tu decisión</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="reason"
                            rows={3}
                            onChange={handleInputChange}
                            value={reason}
                            autoFocus />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-cancel' variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={(event) => handleUpdateApplicationStatus(event)}
                    >
                        Actualizar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    )
})

export default UpdateApplicationStatusModal;