import React, { useState, useContext, useEffect, useRef } from 'react'

import TooltipComponent from '../components/TooltipComponent';
import ModalComponent from '../components/ModalComponent';

import AppContext from '../context/AppContext';

import useFirebaseAuth from '../hooks/useFirebaseAuth';

import '../styles/components/auth.scss'

function ResetPassword() {

    const form = useRef(null);
    const modalRef = useRef();

    const { changeHeaderLayout } = useContext(AppContext);

    const [email, setEmail] = useState('');
    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Correo enviado',
        content: 'El correo ha sido enviado, te recomendamos revisar y seguir los pasos',
        button2Content: 'Ingresar',
        button2Redirect: '/login',
    });
    // Tooltips
    const [TooltipEmail, setTooltipEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });


    const { passwordReset } = useFirebaseAuth();

    useEffect(() => {
        changeHeaderLayout(true);
    }, []);

    // ------- Functions ---------

    const handleChange = (event) => {
        setTooltipEmail({ ...TooltipEmail, status: false });
        const inputValue = event.target.value;
        if ((!/[\])}>[<{(]/.test(inputValue))) {
            setEmail(inputValue);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(form.current);
        const data = { email: formData.get('email') };

        // Email
        if (!data.email && data.email === '') {
            setTooltipEmail({ ...TooltipEmail, status: true });
        } else {
            // Make the petition to the backend
            await passwordReset(data.email)
                .then(response => console.log(response))
                .catch(error => console.log(error));
            openModal()
        }
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() { window.location.href = modalInformation.button1Redirect }
    function handleRedirectToButtonTwo() { window.location.href = modalInformation.button2Redirect }

    return (
        <section id="recover-area" className="py100">
            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton2={modalInformation.button2Content}
                redirectButton2={handleRedirectToButtonTwo}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="login-area">
                            <div className="login-form-area">
                                <div className="form-area-heading text-center">
                                    <h3>Recuperar contraseña</h3>
                                    <h6>Ingresa tu correo electrónico para recuperar tu contraseña</h6>
                                </div>
                                <form action="#" ref={form}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="form-group">
                                                <input
                                                    type='email'
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name='email'
                                                    onChange={handleChange}
                                                    value={email}
                                                />
                                                <TooltipComponent
                                                    attributeName={'email'}
                                                    showTrigger={TooltipEmail.status}
                                                    tooltipText={TooltipEmail.content}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="login-button">
                                                <button
                                                    type="submit"
                                                    className="btn btn-theme"
                                                    onClick={handleSubmit}
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword