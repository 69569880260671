import React, { Fragment, useEffect, useState, useRef } from 'react';

import '../../styles/pages/jobs-list.scss';

// Components
import LoadingComponent from '../../components/LoadingComponent';
import TalentJobsCart from '../../components/jobs/TalentJobsCart';
import JobListFilters from '../../components/jobs/JobListFilters';
import PaginationComponent from '../../components/PaginationComponent';
import LateralFilters from '../../components/LateralFilters';
import FilterButtonComponent from '../../components/FilterButtonComponent';
import ModalComponent from '../../components/ModalComponent';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';
import useAnalytics from '../../hooks/useAnalytics';

const INITIAL_PAGE = 1;
const INITIAL_CATEGORY = 'e207272d-d465-4327-a80c-b76440cf7e03';
const JOBS_PER_PAGE = 10;

function JobsList() {

  // ========== Custom hooks ============
  const { getJobs } = useJobsRequests();
  const jobListAnalyticsTracker = useAnalytics('Job list');

  // ========== States ============
  const [jobsList, setJobsList] = useState([]);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [toggleLateralFilters, setToggleLateralFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // ----- Filter states ----- //
  const [searchValue, setSearchValue] = useState('');
  const [sortByOldest, setSortByOldest] = useState(1);
  const [categoryValue, setCategoryValue] = useState(INITIAL_CATEGORY);
  const [companySizeValue, setCompanySizeValue] = useState(0);
  const [companyValue, setCompanyValue] = useState(0);
  const [cityValue, setCityValue] = useState('');
  const [remoteValue, setRemoteValue] = useState(0);

  // Pagination
  const [page, setPage] = useState(INITIAL_PAGE);
  const [totalJobs, setTotalJobs] = useState(0);

  // Modal
  const [modalInformation, setModalInformation] = useState({
    title: 'Error interno',
    content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
    button2Content: 'Volver',
    button2Redirect: '',
  });

  // ========== References ============ //
  const modalRef = useRef();

  // Variable to save matches
  let searchedJobs = [];

  // Filter logic
  if (!searchValue.length >= 1) {
    searchedJobs = jobsList;
  } else {
    searchedJobs = jobsList.filter(job => {
      const jobText = job.title.toLowerCase();
      const searchText = searchValue.toLowerCase();
      return jobText.includes(searchText);
    });
  }


  // ========== Backend request ==========  //
  // ----- Job List ----- //
  useEffect(() => {

    if (isInitialMount) {
      setIsInitialMount(false);
      return;
    }

    async function jobListFunction() {
      // Build filters object
      let filters = {
        limit: JOBS_PER_PAGE,
        page,
        oldest: sortByOldest
      }

      // Category
      if (categoryValue === INITIAL_CATEGORY) {
        delete filters.category;
      } else {
        filters['category'] = categoryValue;
      }

      // Companies
      if (companyValue === 0) {
        delete filters.company;
      } else {
        filters['company'] = companyValue;
      }

      // Category
      if (cityValue === '0') {
        delete filters.city;
      } else {
        filters['city'] = cityValue;
      }

      // Company size
      if (companySizeValue === '0') {
        delete filters.companySize;
      } else {
        filters['companySize'] = companySizeValue;
      }

      if (remoteValue === 0) {
        delete filters.remote;
      } else {
        filters['remote'] = remoteValue;
        delete filters.city;
      }

      await getJobs(filters)
        .then(response => {
          setIsLoading(false);
          setJobsList(response.jobs);
          setTotalJobs(response.totalJobs);
          if (response.totalJobs <= 10) {
            setPage(1);
          }
          // Close lateral filters if its open
          setToggleLateralFilters(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
          setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
          openModal();
        })
    };
    jobListFunction()
  }, [page, sortByOldest, categoryValue, companySizeValue, cityValue, remoteValue, companyValue, isInitialMount]);


  // ========== Functions ==========  //
  // Close the lateral filter if the page width is more than 992px
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 992)
        setToggleLateralFilters(false);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Pagination handler
  const handlePageChange = (currentPage) => {
    jobListAnalyticsTracker({ action: 'talent_click_new_job_page', label: currentPage });
    setPage(currentPage);
  };

  // Modal functions
  const openModal = () => modalRef.current.handleShow();
  const closeModal = () => modalRef.current.handleClose();
  function handleRedirectToButtonTwo() {
    window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null
  };

  return (
    <Fragment>

      {isLoading && <LoadingComponent />}

      <ModalComponent
        ref={modalRef}
        title={modalInformation.title}
        body={modalInformation.content}
        handleClose={closeModal}
        textButton1={modalInformation.button1Content}
        textButton2={modalInformation.button2Content}
        redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
      />

      <section id="blog-grid-area" className="py40">
        <div className="container">
          <div className="row">
            {/* Job filters */}
            <div className="job-filters col-lg-4 col-md-12 col-sm-12 col-12">
              <JobListFilters
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                sortByOldest={sortByOldest}
                setSortByOldest={setSortByOldest}
                categoryValue={categoryValue}
                setCategoryValue={setCategoryValue}
                companySizeValue={companySizeValue}
                setCompanySizeValue={setCompanySizeValue}
                companyValue={companyValue}
                setCompanyValue={setCompanyValue}
                setCityValue={setCityValue}
                setRemoteValue={setRemoteValue}
                jobListAnalyticsTracker={jobListAnalyticsTracker}
              />
            </div>

            {/* Job cart */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {
                    jobsList && searchedJobs.map(job => {
                      return (
                        <TalentJobsCart jobInfo={job} key={job.uuid} jobListAnalyticsTracker={jobListAnalyticsTracker} />
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <PaginationComponent
                totalPages={Math.ceil(totalJobs / JOBS_PER_PAGE)}
                currentPage={page}
                handlePageChange={handlePageChange}
              />
            </div>

            {
              toggleLateralFilters ?
                <LateralFilters
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  sortByOldest={sortByOldest}
                  setSortByOldest={setSortByOldest}
                  categoryValue={categoryValue}
                  setCategoryValue={setCategoryValue}
                  companySizeValue={companySizeValue}
                  setCompanySizeValue={setCompanySizeValue}
                  companyValue={companyValue}
                  setCompanyValue={setCompanyValue}
                  setCityValue={setCityValue}
                  setRemoteValue={setRemoteValue}
                  setToggleLateralFilters={setToggleLateralFilters}
                />
                :
                ''
            }

            <FilterButtonComponent
              setToggleLateralFilters={setToggleLateralFilters}
            />

          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default JobsList