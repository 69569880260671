import React, { Fragment, useState, useRef, useEffect } from 'react';

// Components
import RegisterStep1 from '../components/auth/RegisterStep1';
import RegisterStep2User from '../components/auth/RegisterStep2User';
import RegisterStep3User from '../components/auth/RegisterStep3User';
import RegisterStep2Company from '../components/auth/RegisterStep2Company';
import RegisterStep3Company from '../components/auth/RegisterStep3Company';
import CompleteNameSignUpModal from '../components/auth/CompleteNameSignUpModal,';
import ModalComponent from '../components/ModalComponent';
import LoadingComponent from '../components/LoadingComponent';

// Custom Hooks
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useAuthentication from '../hooks/backend/useAuth';
import useAnalytics from '../hooks/useAnalytics';
import useTalentRequests from '../hooks/backend/useTalent';
import useHistoryRequests from '../hooks/backend/useHistory';
import useEducationRequests from '../hooks/backend/useEducation';

function SignUp() {

    // ========== References ============ //
    const modalRef = useRef();
    const completeNameModalRef = useRef();

    // ========== Custom ============ //
    const { createUserWithEmail, signInWithGoogle, deleteAccount } = useFirebaseAuth();
    const { signUpCompanyRequest, signUpTalentRequest } = useAuthentication();
    const { getTalentInfoFromCV } = useTalentRequests();
    const { createBatchJobHistoryRecords } = useHistoryRequests();
    const { createBatchEducationRecords } = useEducationRequests();
    const signUpAnalyticsTracker = useAnalytics('Sign up');

    // ========== States ============ //
    const [userRole, setUserRole] = useState(null);
    const [firebaseToken, setFirebaseToken] = useState('');
    const [objectToSend, setObjectToSend] = useState({});
    const [objectToComplete, setObjectToComplete] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [step2Completed, setStep2Completed] = useState(null);
    const [cvUrl, setCvUrl] = useState('');
    const [cvTalentInformation, setCvTalentInformation] = useState({});

    // --- Tooltips --- //
    const [TooltipFirstName, setTooltipFirstName] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [TooltipLastName, setTooltipLastName] = useState({ status: false, content: 'Este campo no debe estar vacío' });


    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Usuario ya existe',
        content: 'La cuenta que ingresaste fue encontrada en nuestro sistema, te recomendamos ingresar con esta cuenta',
        button1Content: 'Cancelar',
        button2Content: 'Ingresar',
        button1Redirect: '',
        button2Redirect: '/login',
    });

    // ======= Request to backend ===========
    // Register user
    useEffect(() => {
        async function sendDataToBackend() {
            if (objectToSend.firebaseId && firebaseToken) {
                // ---- Company ---- //
                if (userRole === 'company') {
                    try {
                        setIsLoading(true);
                        await signUpCompanyRequest(objectToSend, firebaseToken)
                            .then(response => {
                                setIsLoading(false);
                                signUpAnalyticsTracker({ action: 'company_step2_completed', label: response.name });
                                setStep2Completed({
                                    uuid: response.uuid,
                                    companyName: response.name,
                                    email: response.email
                                })
                            })
                            .catch(error => {
                                setIsLoading(false);
                                setModalInformation({
                                    title: 'Error interno',
                                    content: error.message,
                                    button1Content: '',
                                    button2Content: 'Intentar otra vez',
                                    button1Redirect: '',
                                    button2Redirect: '',
                                });
                                openModal();
                            });
                    } catch (error) {
                        console.error("Error: ", error.message);
                    }
                } else {
                    // ---- Talent ---- //
                    try {
                        setIsLoading(true);
                        await signUpTalentRequest(objectToSend, firebaseToken)
                            .then(async response => {

                                const userUuid = response.uuid;
                                const fullName = `${response.firstName} ${response.middleName ? response.middleName : ""} ${response.lastName}`;

                                signUpAnalyticsTracker({ action: 'talent_step2_completed', label: fullName });
                                setStep2Completed({
                                    uuid: response.uuid,
                                    fullName,
                                    email: response.email,
                                    username: response.username,
                                    permissionToBeContactedFlag: response.permissionToBeContactedFlag
                                });

                                await Promise.all([
                                    cvTalentInformation.education?.length !== 0
                                    && createBatchEducationRecords({ userUuid, records: cvTalentInformation.education }),
                                    cvTalentInformation.workExperience?.length !== 0
                                    && createBatchJobHistoryRecords({ userUuid, records: cvTalentInformation.workExperience }),
                                ]);
                                setIsLoading(false);
                            })
                            .catch(async error => {
                                setIsLoading(false);
                                setModalInformation({
                                    title: 'Error interno',
                                    content: JSON.parse(error.message).message,
                                    button1Content: '',
                                    button2Content: 'Intentar otra vez',
                                    button1Redirect: '',
                                    button2Redirect: '',
                                });
                                if (JSON.parse(error.message).statusCode === 400) {
                                    await deleteAccount();
                                }
                                openModal();
                            });
                    } catch (error) {
                        console.error("Error: ", error.message);
                    }

                }
            }
        }
        sendDataToBackend()
    }, [firebaseToken, objectToSend]);

    // CV process
    useEffect(() => {
        async function getTalentInfoFromCVFunction() {
            try {
                const talentCVData = await getTalentInfoFromCV(cvUrl);
                const updatedCvInfo = { ...talentCVData?.cvInfo, cvUrl };
                setCvTalentInformation(updatedCvInfo);
                return talentCVData;
            } catch (error) {
                console.error("Error getting info from the CV: ", error);
            }
        };

        cvUrl && getTalentInfoFromCVFunction();

    }, [cvUrl]);



    const registerUser = async (type, data, user) => {
        // Sign up with Google or email
        switch (type) {
            // Google case
            case 'google':
                signInWithGoogle()
                    .then(async response => {
                        // Verify if is new user
                        if (response._tokenResponse.isNewUser) {
                            setFirebaseToken(response._tokenResponse.idToken);
                            // Verify if is a company or a talent
                            if (user === 'company') {
                                signUpAnalyticsTracker({ action: 'company_google_sign_up', label: 'Sign up with Google' });
                                setObjectToSend({
                                    name: response.user.displayName,
                                    email: response.user.email,
                                    firebaseId: response.user.uid
                                });
                            } else {
                                signUpAnalyticsTracker({ action: 'talent_google_sign_up', label: 'Sign up with Google' });
                                if (!response.user.firstName || !response.user.lastName) {
                                    setObjectToComplete({
                                        firstName: '',
                                        middleName: '',
                                        lastName: '',
                                        email: response.user.email,
                                        firebaseId: response.user.uid
                                    });
                                    openCompleteNameModal()
                                } else {
                                    setObjectToSend({
                                        firstName: response.user.firstName,
                                        lastName: response.user.lastName,
                                        email: response.user.email,
                                        permissionToBeContactedFlag: response.user.permissionToBeContactedFlag,
                                        firebaseId: response.user.uid
                                    });
                                }

                            }
                        } else {
                            openModal()
                        }
                    })
                    .catch(error => console.error(error.message));
                break;

            // Email case
            case 'email':
                createUserWithEmail(data.email, data.password)
                    .then(async response => {
                        setFirebaseToken(response._tokenResponse.idToken);
                        // Verify if is a company or a talent
                        if (user === 'company') {
                            signUpAnalyticsTracker({ action: 'company_email_sign_up', label: 'Sign up with email' });
                            setObjectToSend({
                                name: data.companyName,
                                email: data.email,
                                firebaseId: response.user.uid
                            });
                        } else {
                            signUpAnalyticsTracker({ action: 'talent_email_sign_up', label: 'Sign up with email' });
                            setObjectToSend({
                                firstName: data.firstName,
                                middleName: data.middleName,
                                lastName: data.lastName,
                                email: data.email,
                                permissionToBeContactedFlag: data.permissionToBeContactedFlag,
                                firebaseId: response.user.uid
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
                            openModal()
                        }
                    });
                break;

            default:
                break;
        }
    }

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        if (id === 'firstName') {
            setTooltipFirstName({ ...TooltipFirstName, status: false });
        }

        if (id === 'lastName') {
            setTooltipLastName({ ...TooltipLastName, status: false });
        }

        setObjectToComplete(prevProfileData => ({ ...prevProfileData, [id]: value }));
    }

    const handleSubmit = (e) => {

        let flag = true;
        if (!objectToComplete.firstName || !objectToComplete.firstName === '') {
            setTooltipFirstName({ ...TooltipFirstName, status: true });
            flag = false;
        }

        if (!objectToComplete.lastName || !objectToComplete.lastName === '') {
            setTooltipLastName({ ...TooltipLastName, status: true });
            flag = false;
        }

        if (flag) {
            if (objectToComplete.middleName === '') delete objectToComplete.middleName;
            setObjectToSend(objectToComplete);
            closeCompleteNameModal()
        }

    }

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();
    const openCompleteNameModal = () => completeNameModalRef.current.handleShow();
    const closeCompleteNameModal = () => completeNameModalRef.current.handleClose();

    function handleRedirectToButtonTwo() { window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null }

    return (
        <Fragment>
            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />
            {isLoading && <LoadingComponent />}

            <CompleteNameSignUpModal
                ref={completeNameModalRef}
                handleClose={closeCompleteNameModal}
                objectToComplete={objectToComplete}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                TooltipFirstName={TooltipFirstName}
                TooltipLastName={TooltipLastName}
            />

            {
                // Select type of user
                !userRole ? <RegisterStep1 setUserRole={setUserRole} />
                    :
                    userRole === 'talent'
                        ?
                        // Complete primary info
                        !step2Completed ?
                            <RegisterStep2User
                                stepCompleted={setStep2Completed}
                                signUp={registerUser}
                                setCvUrl={setCvUrl}
                                cvTalentInformation={cvTalentInformation}
                            />
                            :
                            // Complete additional info
                            <RegisterStep3User userInformation={step2Completed} cvTalentInformation={cvTalentInformation} />
                        :
                        // Complete primary info
                        !step2Completed ?
                            <RegisterStep2Company stepCompleted={setStep2Completed} signUp={registerUser} />
                            :
                            // Complete additional info
                            <RegisterStep3Company companyInformation={step2Completed} />
            }
        </Fragment>
    )
};

export default SignUp;