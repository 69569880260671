import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';
import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useDate from '../../hooks/useDates';
import useRating from '../../hooks/backend/useRating';
import useAnalytics from '../../hooks/useAnalytics';

// Components
import LoadingComponent from '../../components/LoadingComponent';
import ModalComponent from '../../components/ModalComponent';
import JobApplication from '../../components/jobs/JobApplication';
import StarRating from '../../components/rating/StarRating'

import '../../styles/pages/job-details.scss';

function JobDetails() {


    // ========== References ============
    const modalRef = useRef();
    const applicationModalRef = useRef();

    // ========== Custom hooks ============
    const { getJob, getJobType, getJobs } = useJobsRequests();
    const { verifyApplicantRequest } = useApplicantsRequests();
    const { convertIsoToNormalFormat } = useDate();
    const { getCompanyRating } = useRating();
    const jobDetailsAnalyticsTracker = useAnalytics('Job details');

    // ========== States ============
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [jobDetails, setJobDetails] = useState({});
    const [jobsSuggestions, setJobsSuggestions] = useState([]);
    const [alreadyApplied, setAlreadyApplied] = useState(false);
    const [applicationId, setApplicationId] = useState(0);
    const [companyRating, setCompanyRating] = useState(5);
    const [isLoading, setIsLoading] = useState(true);
    const [wageFrequencyList, setWageFrequencyList] = useState([
        {
            id: 'hourly',
            name: 'Por hora'
        },
        {
            id: 'weekly',
            name: 'Semanal'
        },
        {
            id: 'fortnightly',
            name: 'Quincenal'
        },
        {
            id: 'monthly',
            name: 'Mensual'
        },
        {
            id: 'yearly',
            name: 'Anual'
        },
    ]);

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ========== Routing and params ============
    const { jobUuid } = useParams();
    const navigate = useNavigate();

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        const token = localStorage.getItem('accessToken');

        const payload = JSON.parse(atob(token.split('.')[1]));
        const uuid = payload.sub;

        async function verifyApplicantFunction() {
            return await verifyApplicantRequest(jobUuid, uuid)
                .then(response => {
                    if (response.length !== 0) {
                        jobDetailsAnalyticsTracker({ action: 'talent_already_applied', label: response[0].id });
                        setAlreadyApplied(true);
                        setApplicationId(response[0].id);
                    }
                    return response;
                })
                .catch(console.error)
        };

        async function getJobFunction() {
            return await getJob(jobUuid)
                .then(response => {
                    jobDetailsAnalyticsTracker({ action: 'job_views', label: jobUuid });
                    setJobDetails(response);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    setModalInformation({ ...modalInformation, content: error.message });
                    openModal();
                });
        }

        async function fetchData() {
            await Promise.all([
                verifyApplicantFunction(),
                getJobFunction()
            ])
                .then(async (response) => {
                    try {
                        const companyUuid = response[1]?.company?.uuid;
                        const companyResponse = await getCompanyRating(companyUuid);
                        if (companyResponse.avgRating) {
                            setCompanyRating(companyResponse.avgRating);
                        }
                    } catch (error) {
                        console.error(error.message);
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
                    openModal();
                })
        };

        fetchData();
    }, [isInitialMount]);

    // Job suggestions
    useEffect(() => {
        if (jobDetails.job_category) {
            async function getJobSuggestions() {
                await getJobs({ limit: 5, page: 1, category: jobDetails.job_category[0].category.uuid })
                    .then(response => {
                        const suggestions = response.jobs.filter(job => job.uuid !== jobDetails.uuid);
                        setJobsSuggestions(suggestions);

                        if (suggestions.length !== 0) jobDetailsAnalyticsTracker({ action: 'talent_got_jobs_recomendations', label: suggestions.length });
                        return suggestions;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            getJobSuggestions();
        } else {
            return;
        }

    }, [jobDetails])


    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    const openApplicationModal = () => applicationModalRef.current.handleShow();
    const closeApplicationModal = () => applicationModalRef.current.handleClose();

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null
    };

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />
            {isLoading && <LoadingComponent />}

            {
                jobDetails.uuid &&
                <JobApplication
                    ref={applicationModalRef}
                    handleClose={closeApplicationModal}
                    title={jobDetails.title}
                    jobUuid={jobDetails.uuid}
                />
            }

            <section id="job-details-main-area" className="py40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="job-details-area">
                                <div className="job-main-details box-shadow ">
                                    <div className="job-details-company-image">
                                        {
                                            jobDetails.company && jobDetails.company.profile_image_url
                                                ?
                                                <img src={jobDetails.company.profile_image_url} alt="company" />
                                                :
                                                <img
                                                    src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                                                    alt="Profile Picture Default"
                                                    style={{ objectFit: 'contain' }}
                                                />
                                        }
                                    </div>
                                    <div className="job-main-details-text">
                                        <div className="job-main-details-heading">
                                            <h2>{jobDetails.title}</h2>
                                        </div>
                                        <div className="job-main-details-author">
                                            <div className="job-author-view">
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            {(jobDetails.job_category && jobDetails.job_category.length !== 0) && jobDetails.job_category[0].category.name}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="fa-solid fa-eye"></i>
                                                            {jobDetails.applicantsNumber === 1 ? `${jobDetails.applicantsNumber} Aplicante` : `${jobDetails.applicantsNumber} Aplicantes`}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="job-main-details-paragraph">
                                            <p>{jobDetails.description}</p>
                                            {
                                                jobDetails.requirements &&
                                                <div>
                                                    <h3>Requisitos del empleo</h3>
                                                    <p>{jobDetails.requirements}</p>
                                                </div>
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="apply-job-area">
                                        <ul>
                                            <li>{convertIsoToNormalFormat(jobDetails.createdAt)}</li>
                                            <li>
                                                {
                                                    !alreadyApplied
                                                        ?
                                                        <a
                                                            href=""
                                                            className="btn btn-apply"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                jobDetailsAnalyticsTracker({ action: 'talent_click_apply_button', label: jobUuid });
                                                                openApplicationModal();
                                                            }}
                                                        >
                                                            Aplicar
                                                        </a>
                                                        :
                                                        <a
                                                            href=""
                                                            className="see-application"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                navigate(`/application/${applicationId}`)
                                                            }}>
                                                            <p>
                                                                Ver mi aplicación...
                                                            </p>
                                                        </a>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Company information */}
                                <div className="company-author box-shadow">
                                    <ul>
                                        <li className="media">
                                            <div className="company-img">
                                                {
                                                    jobDetails.company && jobDetails.company.profile_image_url
                                                        ?
                                                        <img src={jobDetails.company.profile_image_url} alt="company" />
                                                        :
                                                        <img
                                                            src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                                                            alt="Profile Picture Default"
                                                            style={{ objectFit: 'contain' }}
                                                        />
                                                }
                                                <div>
                                                    <a
                                                        href={`/company/${jobDetails.company_uuid}`}
                                                        className="btn btn-theme btn-apply"
                                                        onClick={() => jobDetailsAnalyticsTracker({ action: 'talent_visit_company_profile', label: jobDetails.company_uuid })}
                                                    >
                                                        Ver perfil
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="company-body">
                                                <div className="company-header">
                                                    <div className="company-name">
                                                        <h4>{jobDetails.company?.name}</h4>
                                                        <ul className="justify-center">
                                                            <li className="star-rating-container">
                                                                <StarRating onRate={null} defaultValue={companyRating} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="company-description">
                                                    {
                                                        jobDetails.company?.description ?
                                                            jobDetails.company?.description.length >= 200 ?
                                                                <p>
                                                                    {jobDetails.company.description.slice(0, 200)}
                                                                    <a className='text-primary' href=""> ...más</a>
                                                                </p>
                                                                :
                                                                <p>
                                                                    {jobDetails.company?.description}
                                                                </p>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Additional info --> */}
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 additional-info">
                            <div className="left-head-job right-side">
                                <div className="left-job-page box-shadow">
                                    <div className="left-job">
                                        <h4>Información adicional</h4>
                                        <ul>
                                            {/* Wage */}
                                            {
                                                jobDetails.wageValue && jobDetails.wageValue !== 0
                                                    ?
                                                    <li>
                                                        <span>
                                                            <i className="fa fa-wallet"></i>
                                                        </span>
                                                        <p>
                                                            {`${jobDetails.wageCurrency?.symbol} ${Number(jobDetails.wageValue).toLocaleString('es-CO', { minimumFractionDigits: 0 })} ${jobDetails.wageCurrency?.abbreviation} - ${wageFrequencyList.find(item => item.id === jobDetails.wageType)?.name}`}
                                                        </p>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            <li>
                                                <span>
                                                    <i className="fa-solid fa-location-dot"></i>
                                                </span>
                                                <p>
                                                    {
                                                        jobDetails.remote ?
                                                            'Remoto'
                                                            :
                                                            `${jobDetails.city?.name}, ${jobDetails.city?.state.country.name}`
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <span>
                                                    <i className="fa-solid fa-briefcase"></i>
                                                </span>
                                                <p>{getJobType[jobDetails.type]}</p>
                                            </li>
                                            {
                                                jobDetails.startDate &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-calendar"></i>
                                                    </span>
                                                    <p className="m0"><b>Fecha de inicio: </b> </p>
                                                    <p>
                                                        {jobDetails.startDate}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                jobDetails.finishDate &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-calendar"></i>
                                                    </span>
                                                    <p className="m0"><b>Termina: </b></p>
                                                    <p>
                                                        {jobDetails.finishDate}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                jobDetails.startHour &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-clock"></i>
                                                    </span>
                                                    <p className="m0"><b>Hora inicial: </b></p>
                                                    <p>
                                                        {jobDetails.startHour}
                                                    </p>
                                                </li>

                                            }
                                            {
                                                jobDetails.finisHour &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-clock"></i>
                                                    </span>
                                                    <p className="m0"><b>Hora final: </b></p>
                                                    <p>
                                                        {jobDetails.finishHour}
                                                    </p>
                                                </li>

                                            }
                                        </ul>
                                    </div>
                                </div>
                                {/* <!-- Suggestions --> */}
                                {
                                    jobsSuggestions.length !== 0 &&
                                    <div className="left-job-page box-shadow">
                                        <div className="left-job">
                                            <h4>Otros empleos</h4>
                                            <div
                                                onClick={(event) => {
                                                    jobDetailsAnalyticsTracker({ action: 'talent_click_jobs_recomendations', label: event.target.id });
                                                    navigate(`/jobs/${event.target.id}`)
                                                }}
                                            >
                                                {
                                                    jobsSuggestions.map(job => {
                                                        return (
                                                            <div
                                                                className="other-job"
                                                                key={job.uuid}
                                                                id={job.uuid}
                                                            >
                                                                <a
                                                                    href=""
                                                                    className='align-center'
                                                                    id={job.uuid}
                                                                >
                                                                    <div className="job-img">
                                                                        {
                                                                            job['company.profile_image_url']
                                                                                ?
                                                                                <img src={job['company.profile_image_url']} alt="company" id={job.uuid} />
                                                                                :
                                                                                <img
                                                                                    src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                                                                                    alt="Profile Picture Default"
                                                                                    style={{ objectFit: 'contain' }}
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div className="job-details-content" id={job.uuid}>
                                                                        <p id={job.uuid}>
                                                                            <b id={job.uuid}> {job.title} </b>
                                                                        </p>
                                                                        <p id={job.uuid}> {getJobType[job.type]} </p>
                                                                    </div>
                                                                </a>
                                                                <div className='other-job-arrow'>
                                                                    <i className="fa-solid fa-chevron-right"></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default JobDetails