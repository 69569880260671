import api from '../../libs/API'
import useAuthentication from './useAuth';

const useCompanyPreferences = () => {

    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // ===== Categories ===== //
    // Get the preferences of specific company
    const getFilteredCompaniesList = async (filters, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const { limit, page, category } = filters;

            // Filters
            let query = { limit };

            // Offset
            if (page) query.offset = (page - 1) * limit;

            if (category) query.category = category;

            // --- Request --- //
            const companies = await api.get(`/companies/preferences`, { params: query, headers });
            return companies.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getFilteredCompaniesList(filters, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get the preferences of specific company
    const getCompanyCategoryPreferencesRequest = async (companyUuid, alreadyCalled) => {
        try {
            // --- Headers --- //
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // --- Request --- //
            const categoriesPreferences = await api.get(`/companies/preferences/categories/${companyUuid}`, { headers });
            return categoriesPreferences.data;

        } catch (error) {
            // --- Handle error --- //
            console.error(error.response.data.message);
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCompanyCategoryPreferencesRequest(companyUuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create category request
    const createCategoryPreferenceRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/companies/preferences/categories', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await createCategoryPreferenceRequest(data, true);
                    } catch (error) {
                        console.error(error.message);
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Delete category request
    const deleteCategoryPreferenceRequest = async (id, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.delete(`/companies/preferences/categories/${id}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken()
                        return await deleteCategoryPreferenceRequest(id, true);
                    } catch (error) {
                        console.error(error.message);
                    }

                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    return {
        createCategoryPreferenceRequest,
        getCompanyCategoryPreferencesRequest,
        getFilteredCompaniesList,
        deleteCategoryPreferenceRequest
    };

};

export default useCompanyPreferences;