import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import ModalComponent from '../../components/ModalComponent';
import MyJobsFilters from '../../components/jobs/MyJobsFilters';
import MyJobsCart from '../../components/jobs/MyJobsCart';
import JobModal from '../../components/jobs/JobModal';
import DisableJobModal from '../../components/jobs/DisableJobModal';
import PaginationComponent from '../../components/PaginationComponent';
import LateralFilters from '../../components/LateralFilters';
import FilterButtonComponent from '../../components/FilterButtonComponent';
import LoadingComponent from '../../components/LoadingComponent';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';


import '../../styles/pages/my-jobs.scss'

const INITIAL_PAGE = 1;
const JOBS_PER_PAGE = 10;
const INITIAL_CATEGORY = 'e207272d-d465-4327-a80c-b76440cf7e03';

function MyJobs() {

    // ========== References ============
    const modalRef = useRef();
    const jobModalRef = useRef();
    const updateJobModalRef = useRef();
    const disableJobModalRef = useRef();

    // ========== Routing and params ============
    const navigate = useNavigate();

    // ========== Custom hooks ============
    const { getJobs } = useJobsRequests();

    // ========== States ============
    const [jobsList, setJobsList] = useState([]);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [toggleLateralFilters, setToggleLateralFilters] = useState(false);
    const [jobToUpdate, setJobToUpdate] = useState({});
    const [jobToDisable, setJobToDisable] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ----- Filter states ----- //
    const [searchValue, setSearchValue] = useState('');
    const [sortByOldest, setSortByOldest] = useState(1);
    const [categoryValue, setCategoryValue] = useState(INITIAL_CATEGORY);
    const [disabledJobs, setDisabledJobs] = useState(0);

    // Pagination
    const [page, setPage] = useState(INITIAL_PAGE);
    const [totalJobs, setTotalJobs] = useState(0);

    // Variable to save matches
    let searchedJobs = [];

    // Filter logic
    if (!searchValue.length >= 1) {
        searchedJobs = jobsList;
    } else {
        searchedJobs = jobsList.filter(job => {
            const jobText = job.title.toLowerCase();
            const searchText = searchValue.toLowerCase();
            return jobText.includes(searchText);
        });
    }

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function jobListFunction() {

            const userInformation = JSON.parse(localStorage.getItem('userInformation'));
            const uuid = userInformation.user.uuid;

            // Build filters object
            let filters = {
                limit: JOBS_PER_PAGE,
                page,
                oldest: sortByOldest,
                company: uuid
            }

            // Category
            if (categoryValue === INITIAL_CATEGORY) {
                delete filters.category;
            } else {
                filters['category'] = categoryValue;
            }

            // Disabled jobs
            if (disabledJobs) {
                filters['active'] = true;
            }

            await getJobs(filters)
                .then(response => {
                    sessionStorage.removeItem('jobInformation')
                    setJobsList(response.jobs);
                    setTotalJobs(response.totalJobs);
                    if (response.totalJobs <= 10) {
                        setPage(1);
                    };
                    setIsLoading(false);
                    // Close lateral filters if its open
                    setToggleLateralFilters(false);
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
                    openModal();
                })
        };
        jobListFunction()
    }, [page, sortByOldest, categoryValue, disabledJobs, isInitialMount]);

    // ========== Functions ==========  //
    // Close the lateral filter if the page width is more than 992px
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992)
                setToggleLateralFilters(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const findJob = (jobUuid) => jobsList.find(job => job.uuid === jobUuid);

    // --- Handlers --- //
    const seeApplicantHandler = (jobUuid) => {
        // Find job to save in session storage
        const jobToSave = findJob(jobUuid);

        // Save in session storage
        sessionStorage.setItem('jobInformation', JSON.stringify(jobToSave));

        // Redirect based on the number of applicants
        if (jobToSave.applicantsNumber === 0) {
            navigate(`/talent-availability/${jobToSave['job_category.category.uuid']}`)
        } else {
            navigate(`/applicants/${jobToSave['uuid']}`)
        }
    }

    const updateJobHandler = (jobUuid) => {
        const jobToUpdate = findJob(jobUuid);
        setJobToUpdate(jobToUpdate);
        openUpdateJobModal();
    };

    const disableJobHandler = (jobUuid) => {
        const jobToDisable = findJob(jobUuid);
        setJobToDisable(jobToDisable);
        openDisableJobModal();
    };

    const handleCreateJob = (event) => {
        event.preventDefault();
        const profileInformation = JSON.parse(localStorage.getItem('userInformation')).user;
        if (profileInformation.verifiedCompany) {
            openJobModal();
        } else {
            setModalInformation({
                title: 'Esperando activación',
                content: 'Antes de crear un empleo debes esperar que se verifique tu empresa. Este proceso suele tardar menos de 24 horas',
                button2Content: 'Volver',
                button2Redirect: '',
            });
            openModal();
        }
    }

    // Pagination handler
    const handlePageChange = (currentPage) => {
        setPage(currentPage);
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    const openJobModal = () => jobModalRef.current.handleShow();
    const closeJobModal = () => jobModalRef.current.handleClose();

    const openUpdateJobModal = () => updateJobModalRef.current.handleShow();
    const closeUpdateJobModal = () => updateJobModalRef.current.handleClose();

    const openDisableJobModal = () => disableJobModalRef.current.handleShow();
    const closeDisableJobModal = () => disableJobModalRef.current.handleClose();

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
    }

    return (
        <Fragment>

            {isLoading && <LoadingComponent />}

            <JobModal
                ref={jobModalRef}
                handleClose={closeJobModal}
            />

            <JobModal
                ref={updateJobModalRef}
                handleClose={closeUpdateJobModal}
                jobInfo={jobToUpdate}
            />

            <DisableJobModal
                ref={disableJobModalRef}
                handleClose={closeDisableJobModal}
                jobInfo={jobToDisable}
            />

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <section id="blog-grid-area" className="py40">
                <div className="container">
                    <div className="row">
                        {/* Job Filters */}
                        <div className="job-filters col-lg-3 col-md-12 col-sm-12 col-12">
                            <MyJobsFilters
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                sortByOldest={sortByOldest}
                                setSortByOldest={setSortByOldest}
                                categoryValue={categoryValue}
                                setCategoryValue={setCategoryValue}
                                disabledJobs={disabledJobs}
                                setDisabledJobs={setDisabledJobs}
                            />
                        </div>

                        {/* <!-- Jobs list --> */}
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="row" style={{ paddingRight: '0px' }}>
                                <div className="row my-jobs-header">
                                    <h4 className="col-lg-6 col-md-6 col-sm-7 col-7">Mis empleos</h4>
                                    <div className="col-lg-6 col-md-6 col-sm-5 col-5">
                                        <a
                                            href="#"
                                            className="btn btn-theme btn-new-job"
                                            onClick={event => handleCreateJob(event)}
                                        >
                                            <i style={{ marginRight: '4px' }} className="fas fa-solid fa-plus"></i>
                                            Crear
                                        </a>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        jobsList.length !== 0 &&
                                        searchedJobs.map(job => {
                                            return (
                                                <div
                                                    className="col-lg-6 col-md-6 col-sm-6 col-6 mt4"
                                                    key={job.uuid}
                                                >
                                                    <MyJobsCart
                                                        uuid={JSON.parse(localStorage.getItem('userInformation')).uuid}
                                                        jobUuid={job.uuid}
                                                        image={job['company.profile_image_url']}
                                                        status={job.status}
                                                        applicantsNumber={job.applicantsNumber}
                                                        date={job.createdAt}
                                                        title={job.title}
                                                        description={job.description}
                                                        updateJobHandler={updateJobHandler}
                                                        disableJobHandler={disableJobHandler}
                                                        seeApplicantHandler={seeApplicantHandler}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                {/* <!-- pagination start --> */}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt4">
                                    <PaginationComponent
                                        totalPages={Math.ceil(totalJobs / JOBS_PER_PAGE)}
                                        currentPage={page}
                                        handlePageChange={handlePageChange}
                                    />
                                </div>
                                {/* <!-- pagination end --> */}
                            </div>
                        </div>

                        {
                            toggleLateralFilters ?
                                <LateralFilters
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    sortByOldest={sortByOldest}
                                    setSortByOldest={setSortByOldest}
                                    categoryValue={categoryValue}
                                    setCategoryValue={setCategoryValue}
                                    disabledJobs={disabledJobs}
                                    setDisabledJobs={setDisabledJobs}
                                    setToggleLateralFilters={setToggleLateralFilters}
                                    type={'my-jobs'}
                                />
                                :
                                ''
                        }

                        <FilterButtonComponent
                            setToggleLateralFilters={setToggleLateralFilters}
                        />

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default MyJobs