import React, { Fragment, useState, useEffect } from 'react'

// ----- Components ----- //
import LoadingComponent from '../../components/LoadingComponent';
import ApplicationFilters from '../../components/applications/ApplicationFilters';
import ApplicationCart from '../../components/applications/ApplicationCart';
import PaginationComponent from '../../components/PaginationComponent';
import LateralFilters from '../../components/LateralFilters';
import FilterButtonComponent from '../../components/FilterButtonComponent';

// ----- Custom ----- //
import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useAnalytics from '../../hooks/useAnalytics';

import '../../styles/pages/my-applications.scss';

// ----- Constants ----- //
const INITIAL_PAGE = 1;
const APPLICANTS_PER_PAGE = 10;

function MyApplications() {

    // ========== States ============
    const [toggleLateralFilters, setToggleLateralFilters] = useState(false);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [applicantList, setApplicantList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // ----- Filter states ----- //
    const [searchValue, setSearchValue] = useState('');
    const [sortByOldest, setSortByOldest] = useState(1);
    const [statusValue, setStatusValue] = useState('Todas');

    // Pagination
    const [page, setPage] = useState(INITIAL_PAGE);
    const [totalApplications, setTotalApplications] = useState(0);

    // ----- Name filter logic ----- //

    // Variable to save matches
    let searchedApplicants = [];
    if (!searchValue.length >= 1) {
        searchedApplicants = applicantList;
    } else {
        searchedApplicants = applicantList.filter(applicant => {
            const applicantText = applicant.job.title.toLowerCase();
            const searchText = searchValue.toLowerCase();
            return applicantText.includes(searchText);
        });
    }

    // ========== Custom ============
    const { getUserApplicationsRequest } = useApplicantsRequests();
    const myApplicationsAnalyticsTracker = useAnalytics('My applications');

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function applicationsFunction() {

            // Get user uuid
            const userInformation = JSON.parse(localStorage.getItem('userInformation'));
            const userUuid = userInformation.user.uuid;

            // Build filters object
            let filters = {
                limit: APPLICANTS_PER_PAGE,
                page,
                oldest: sortByOldest
            }

            if (statusValue) {
                if (statusValue === 'Todas') {
                    delete filters.status;
                } else {
                    filters.status = statusValue;
                }
            };

            await getUserApplicationsRequest(filters, userUuid)
                .then(response => {
                    setIsLoading(false);
                    setApplicantList(response.applications);
                    setTotalApplications(response.totalApplications)
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                });
        };
        applicationsFunction();

    }, [page, sortByOldest, statusValue, isInitialMount]);

    // ========== Functions ==========  //

    // Pagination handler
    const handlePageChange = (currentPage) => {
        setPage(currentPage);
    };

    return (
        <Fragment>

            {isLoading && <LoadingComponent />}

            <section id="applicant-grid-area" className="py40">
                <div className="container">
                    <div className="row">
                        {/* <!-- Applicant Filters --> */}
                        <div className="applicant-filters col-lg-3 col-md-12 col-sm-12 col-12">
                            <ApplicationFilters
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                sortByOldest={sortByOldest}
                                setSortByOldest={setSortByOldest}
                                statusValue={statusValue}
                                setStatusValue={setStatusValue}
                                myApplicationsAnalyticsTracker={myApplicationsAnalyticsTracker}
                            />
                        </div>

                        {/* <!-- Applicants information --> */}
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <div className="row">
                                    <div className="row my-applicants-header">
                                        <h4 className="col-lg-12 col-md-12 col-sm-12 col-12">Aplicaciones</h4>
                                    </div>
                                    {
                                        applicantList.length !== 0 &&
                                        searchedApplicants.map(application => {
                                            return (
                                                <div
                                                    key={application.id}
                                                    className="col-lg-6 col-md-6 col-sm-6 col-6"
                                                >
                                                    <ApplicationCart
                                                        image={application.job.company.profile_image_url}
                                                        status={application.status}
                                                        date={application.updatedAt}
                                                        title={application.job.title}
                                                        description={application.description}
                                                        applicationId={application.id}
                                                        jobUuid={application.job_uuid}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <PaginationComponent
                                totalPages={Math.ceil(totalApplications / APPLICANTS_PER_PAGE)}
                                currentPage={page}
                                handlePageChange={handlePageChange}
                            />
                        </div>

                        {
                            toggleLateralFilters ?
                                <LateralFilters
                                    type={'applicants'}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    sortByOldest={sortByOldest}
                                    setSortByOldest={setSortByOldest}
                                    statusValue={statusValue}
                                    setStatusValue={setStatusValue}
                                    setToggleLateralFilters={setToggleLateralFilters}
                                />
                                :
                                ''
                        }

                        <FilterButtonComponent
                            setToggleLateralFilters={setToggleLateralFilters}
                        />

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default MyApplications