import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// ----- Custom ----- //
import useRating from '../../hooks/backend/useRating';

// ----- Components ----- //
import ModalComponent from '../ModalComponent';
import TooltipComponent from '../TooltipComponent';
import StarRating from './StarRating';

const RatingModal = forwardRef((props, ref) => {

    const {
        handleClose,
        ratingInfo,
        name,
        qualifiedUuid
    } = props;

    // ========== Custom hooks ============
    const { createRatingRequest, updateRatingRequest } = useRating();

    // ========== States ============
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState({});
    const [updateRequest, setUpdateRequest] = useState({});
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('userInformation')).role);

    // ----- Input content ----- //
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingDescription, setRatingDescription] = useState('');

    // ----- Tooltips ----- //
    const [TooltipRatingValue, setTooltipRatingValue] = useState({ status: false, content: 'Este campo no debe estar vacío' });

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor recarga la página',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // =======min initial Date====== //
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // ========== References ============ //
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));
    const form = useRef(null);
    const modalRef = useRef();

    useEffect(() => {
        if (ratingInfo) {
            setRatingValue(ratingInfo.value ? Number(ratingInfo.value) : null);
            setRatingDescription(ratingInfo.description || '');
        } else {
            setRatingValue(null);
            setRatingDescription('');
        }
    }, [ratingInfo]);

    // ========== Backend request ============ //
    // Create rating record
    useEffect(() => {

        if (request.qualifiedUuid && request.qualifierUuid && request.value) {
            async function createRatingFunction() {
                // Make request to backend
                await createRatingRequest(request)
                    .then(() => {
                        handleClose();
                        setModalInformation({
                            title: 'Calificación creada correctamente',
                            content: `Tu calificación a ${name} ha sido creada correctamente.`,
                            button2Content: 'Volver',
                            button2Redirect: `/company/${request.qualifiedUuid}`,
                        });
                        openModal();
                    })
                    .catch(error => {
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal();
                    })
            }
            createRatingFunction()
        } else {
            return;
        }

    }, [request]);

    // Update rating record
    useEffect(() => {

        if (updateRequest.value || request.description) {
            async function updateRatingFunction() {
                // Make request to backend
                await updateRatingRequest(ratingInfo.id, updateRequest)
                    .then(() => {
                        handleClose();
                        setModalInformation({
                            title: 'Calificación actualizada correctamente',
                            content: `Tu calificación a ${name} ha sido actualizada correctamente.`,
                            button2Content: 'Volver',
                            button2Redirect: `/${userRole === 'company' ? 'talent' : 'company'}/${qualifiedUuid}`,
                        });
                        openModal();
                    })
                    .catch(error => {
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal();
                    })
            }
            updateRatingFunction()
        } else {
            return;
        }

    }, [updateRequest]);

    // ========== Functions ============
    // ----- Input changes ----- //
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        (!/[\]}>[<{]/.test(inputValue)) && setRatingDescription(inputValue);
    }

    // ----- Handle rating creation ----- //
    const handleCreateRating = (event) => {
        event.preventDefault();

        if (ratingValue) {
            // Send request
            const data = {
                qualifiedUuid,
                qualifierUuid: JSON.parse(localStorage.getItem('userInformation')).user.uuid,
                value: ratingValue,
                description: ratingDescription,
                qualifiedType: userRole === 'company' ? 'user' : 'company',
                qualifierType: userRole,
                description: ratingDescription ? ratingDescription : ''
            }
            setRequest(data);
        } else {
            setTooltipRatingValue({ ...TooltipRatingValue, status: true });
        }
    };

    // ----- Handle rating update ----- //
    const handleUpdateRating = (event) => {
        event.preventDefault();

        // --- Validate empty fields --- //
        if (ratingValue) {
            const initialObject = { value: Number(ratingInfo.value), description: ratingInfo.description || '' };
            const actualObject = { value: ratingValue, description: ratingDescription };

            const objectToSend = compareObjects(initialObject, actualObject);
            if (Object.keys(objectToSend).length !== 0) {
                // Send request
                setUpdateRequest(objectToSend);
            }
        } else {
            setTooltipRatingValue({ ...TooltipRatingValue, status: true });
        }
    };

    const compareObjects = (obj1, obj2) => {
        let result = {};
        for (let prop in obj2) {
            if (!obj1.hasOwnProperty(prop) || obj1[prop] !== obj2[prop]) {
                result[prop] = obj2[prop];
            }
        }
        return result;
    }

    const handleRating = (rating) => {
        setRatingValue(rating)
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
    }

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {
                        ratingInfo && ratingInfo.value
                            ?
                            <Modal.Title className='modal-title'>Actualizar calificación de <b>{name}</b></Modal.Title>
                            :
                            <Modal.Title className='modal-title'>Calificar a <b>{name}</b></Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="aboutYourself"
                        ref={form}
                    >
                        <div className="row">
                            {/* <!-- Rating value --> */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="form-group align-center justify-center">
                                    <StarRating
                                        onRate={handleRating}
                                        defaultValue={ratingValue}
                                        inProcess={true}
                                        name="rating-value"
                                    />
                                    <TooltipComponent
                                        attributeName={'rating-value'}
                                        showTrigger={TooltipRatingValue.status}
                                        tooltipText={TooltipRatingValue.content}
                                    />
                                </div>
                            </div>

                            {/* <!-- Rating description --> */}
                            <div className="form-floating col-lg-12 col-md-12 col-sm-12 col-12">
                                <textarea
                                    className="form-control"
                                    id="rating-description"
                                    name='rating-description'
                                    onChange={handleInputChange}
                                    value={ratingDescription}
                                    style={{ height: '80px' }}
                                >
                                </textarea>
                                <label
                                    style={{ paddingLeft: '1.25rem' }}
                                    htmlFor="rating-description"
                                >
                                    Comenta el motivo de tu calificación
                                </label>

                            </div>
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn-cancel'
                        variant="secondary"
                        onClick={() => {
                            handleClose();
                            setRatingValue(undefined);
                        }}>
                        Cancelar
                    </Button>
                    {
                        ratingInfo && ratingInfo.id
                            ?
                            <Button
                                className='btn-theme'
                                variant="primary"
                                onClick={(event) => handleUpdateRating(event)}
                            >
                                Actualizar
                            </Button>
                            :
                            <Button
                                className='btn-theme'
                                variant="primary"
                                onClick={(event) => handleCreateRating(event)}
                            >
                                Crear
                            </Button>

                    }
                </Modal.Footer>
            </Modal>
        </Fragment >
    )
})

export default RatingModal