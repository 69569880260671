import React from 'react';
import Pagination from 'react-bootstrap/Pagination';


function PaginationComponent({ totalPages, currentPage, handlePageChange }) {

    // ========== Functions ==========  //
    const getItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    id={number}
                    active={number == currentPage}
                    onClick={event => {
                        handlePageChange(event.target.id)
                    }}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    }

    return (
        <div className='center-section'>
            <Pagination>{getItems()}</Pagination>
        </div>
    )
}

export default PaginationComponent