import React from "react";
import TimePicker from 'react-time-picker';

function HourSelector(props) {
    const { date, setDay, startHourFlag, label, name } = props;

    const handleHourChange = (time) => {
        if (startHourFlag) {
            setDay({ ...date, startHour: time });
        } else {
            setDay({ ...date, finishHour: time });
        }
    }

    return (
        <div name={name}>
            {
                label && <label>{label}</label>
            }
            <TimePicker
                onChange={time => handleHourChange(time)}
                value={startHourFlag ? date.startHour : date.finishHour}
                disableClock
                clearIcon={null}
            />
        </div>
    );
}

export default HourSelector;