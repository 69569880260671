import api from '../../libs/API'
import useAuthentication from './useAuth';


const useApplicantsRequests = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Get the applicants of an specific
    const getApplicationRequest = async (applicationId, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.get(`/applicants/${applicationId}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getApplicationRequest(applicationId, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get the applicants of an specific
    const getApplicantsByJobRequest = async (filters, jobUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        // Get filters
        const { limit, page, oldest, status } = filters;

        // Filters
        let queryParams = { limit };

        // Offset
        if (page) queryParams.offset = (page - 1) * limit;

        // Order by newest by default
        if (oldest) queryParams.newest = oldest;

        // Status
        if (status) queryParams.status = status;

        try {
            const response = await api.get(`/applicants/job/${jobUuid}`,
                {
                    params: queryParams,
                    headers
                }
            );
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getApplicantsByJobRequest(filters, jobUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create an application for a job
    const createApplicantRequest = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/applicants', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createApplicantRequest(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Verify if an applicant already apply to one job
    const verifyApplicantRequest = async (jobUuid, userUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.get('/applicants/verifyApplication',
                {
                    params: { jobUuid, userUuid },
                    headers
                }
            );
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await verifyApplicantRequest(jobUuid, userUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get the applications of an user
    const getUserApplicationsRequest = async (filters, userUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        // Get filters
        const { limit, page, oldest, status } = filters;

        // Filters
        let queryParams = { limit };

        // Offset
        if (page) queryParams.offset = (page - 1) * limit;

        // Order by newest by default
        if (oldest) queryParams.newest = oldest;

        // Status
        if (status) queryParams.status = status;

        try {
            const response = await api.get(`/applicants/user/${userUuid}`, { params: queryParams, headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getUserApplicationsRequest(filters, userUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get the applications of an user
    const getStatusRequest = async (type, uuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        let params = {};
        params[type] = uuid;

        try {
            const response = await api.get(`/applicants/status`, { params, headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getStatusRequest(type, uuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update the status of an application
    const updateStatusRequest = async (applicationId, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/applicants/${applicationId}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateStatusRequest(applicationId, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    return {
        createApplicantRequest,
        getApplicationRequest,
        getStatusRequest,
        getUserApplicationsRequest,
        getApplicantsByJobRequest,
        updateStatusRequest,
        verifyApplicantRequest
    }

}

export default useApplicantsRequests;