import { useState } from 'react';
import forge from 'node-forge';
import useS3Request from './useS3Request';
import IS_DISABLE_ENCRYPT from '../constants/encryptMode';

const useCryptoService = () => {
    const [publicKey, setPublicKey] = useState(null);
    const { getPublicKey } = useS3Request();

    const fetchPublicKey = async () => {
        try {
            const response = await getPublicKey();
            const arrayBuffer = response.Body;
            const textDecoder = new TextDecoder('utf-8');

            const decodedString = textDecoder.decode(arrayBuffer);

            setPublicKey(decodedString);

            return decodedString;
        } catch (error) {
            console.error('Error fetching public key from API:', error);
            throw error;
        }
    };

    const encryptData = async (data) => {
        try {
            let currentPublicKey = publicKey;
            if (!currentPublicKey) {
                currentPublicKey = await fetchPublicKey();
            }

            const publicKeyObj = forge.pki.publicKeyFromPem(currentPublicKey);
            const dataToEncrypt = forge.util.createBuffer(data);
            const encryptedData = publicKeyObj.encrypt(dataToEncrypt.getBytes());
            return encryptedData;
        } catch (error) {
            console.error('Error encrypting data:', error);
            throw error;
        }
    };

    const encryptSelectedFields = async (data, fieldsToEncrypt) => {
        const newData = { ...data };
        for (const field of fieldsToEncrypt) {
            if (newData[field]) {
                newData[field] = IS_DISABLE_ENCRYPT ? await newData[field] : await encryptData(newData[field]);
            }
        }
        return newData;
    };

    return {
        publicKey,
        encryptData,
        encryptSelectedFields,
    };
};

export default useCryptoService;