import React, { Fragment, useEffect, useState } from 'react';

// Custom
import useTalentRequests from '../../hooks/backend/useTalent';

function TalentAvailabilityFilters(props) {

    const {
        // States values
        searchValue,
        setSearchValue,
        setCityValue,
        category
    } = props;

    // ========== Custom hooks ============
    const { getTalentCitiesRequest } = useTalentRequests();

    // ========== States ============
    const [citiesList, setCitiesList] = useState([
        {
            city: {
                id: 0,
                name: "Todas"
            }
        }
    ]);
    const [isInitialMount, setIsInitialMount] = useState(true);

    // ========== Backend request ==========  //
    // ----- Job Filters ----- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function talentFiltersFunction() {
            await getTalentCitiesRequest(category)
                .then(response => {
                    if (response) {
                        setCitiesList(citiesList.concat(response));
                    }
                })
                .catch(console.error);
        };

        if (citiesList.length === 1) {
            talentFiltersFunction();
        }
    }, [isInitialMount]);

    return (
        <Fragment>
            {/* Search by job title start */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Nombre del talento</h4>
                    <form action="#" className="mt4">
                        <div className="blog-search-option">
                            <input
                                type="text"
                                placeholder="Nombre..."
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <button className="button" type="submit"> <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Search by job title end */}

            {/* Location */}
            <div className="left-blog-page box-shadow">
                <div className="left-tags blog-tags">
                    <div className="popular-tag left-side-tags left-blog">
                        <h4>Ciudades</h4>
                        <ul>
                            {
                                citiesList.length !== 1 && citiesList.map((city) => {

                                    return (
                                        <li
                                            key={city.city.id}
                                            onClick={event => {
                                                event.preventDefault();
                                                setCityValue(event.target.id)
                                            }}
                                        >
                                            <a id={city.city.id} href="">{city.city.name}</a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentAvailabilityFilters;