import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// Custom
import useOptions from '../../hooks/backend/useSelectorOptions';
import useJobs from '../../hooks/backend/useJobs';
import useDates from '../../hooks/useDates';

// Components
import LoadingComponent from '../LoadingComponent';
import TooltipComponent from '../TooltipComponent';
import ModalComponent from '../ModalComponent';
import MyDatePicker from '../DatePicker';

import '../../styles/components/new-job.scss'

const JobModal = forwardRef((props, ref) => {

    const {
        handleClose,
        jobInfo
    } = props;

    // ========== Custom hooks ============
    const { getCountries, getStates, getCities, getCurrencyOptions, getCategoryOptions } = useOptions();
    const { createJobRequest, updateJobRequest, updateJobCategoryRequest } = useJobs();
    const { convertCompleteDateToDMY } = useDates();

    // ========== States ============
    const [show, setShow] = useState(false);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [request, setRequest] = useState({});
    const [updateRequest, setUpdateRequest] = useState({});
    const [isRemote, setIsRemote] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toggleWage, setToggleWage] = useState(false);
    const [profileExists, setProfileExists] = useState(false)

    // ----- Input content ----- //
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [jobRequirements, setJobRequirements] = useState('');
    const [wageValue, setWageValue] = useState('');

    // Selectors
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedJobType, setSelectedJobType] = useState('');
    const [selectedWageFrequency, setSelectedWageFrequency] = useState('');
    const [selectedCurrencyType, setSelectedCurrencyType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');

    // Lists
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [employmentTypesList, setEmploymentTypesList] = useState([
        {
            id: 'occasional',
            name: 'Ocasional'
        },
        {
            id: 'seasonal',
            name: 'Por temporada'
        },
        {
            id: 'internships',
            name: 'Prácticas'
        },
        {
            id: 'part',
            name: 'Medio tiempo'
        },
        {
            id: 'full',
            name: 'Tiempo completo'
        },
    ]);
    const [wageFrequencyList, setWageFrequencyList] = useState([
        {
            id: 'hourly',
            name: 'Por hora'
        },
        {
            id: 'weekly',
            name: 'Semanal'
        },
        {
            id: 'fortnightly',
            name: 'Quincenal'
        },
        {
            id: 'monthly',
            name: 'Mensual'
        },
        {
            id: 'yearly',
            name: 'Anual'
        },
    ]);
    const [currencyList, setCurrencyList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    // ----- Tooltips ----- //
    const [TooltipJobTitle, setTooltipJobTitle] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [TooltipJobDescription, setTooltipJobDescription] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [TooltipJobRequirements, setTooltipJobRequirements] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [TooltipJobType, setTooltipJobType] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipCountry, setTooltipCountry] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipRegion, setTooltipRegion] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipCity, setTooltipCity] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipWageFrequency, setTooltipWageFrequency] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipCurrencyType, setTooltipCurrencyType] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipWageValue, setTooltipWageValue] = useState({ status: false, content: 'Este campo no debe estar vacío' });
    const [TooltipCategory, setTooltipCategory] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
    const [TooltipDate, setTooltipDate] = useState({ status: false, content: 'La fecha inicial debe ser menor que la fecha final' });

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor recarga la página',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // =======min initial Date====== //
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // ========== References ============ //
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));
    const form = useRef(null);
    const modalRef = useRef();

    // ========== Backend request ============ //
    // Get options
    useEffect(() => {

        // Countries
        async function getCountryList() {
            try {
                const countryResponse = await getCountries();
                setCountryList(countryResponse);
            } catch (error) {
                console.error(error.message);
            }
        };

        // States
        async function getStatesList() {
            if (selectedCountry !== "País") {
                try {
                    const statesResponse = await getStates(selectedCountry);
                    setStateList(statesResponse);
                } catch (error) {
                    setStateList([]);
                    console.error(error.message);
                }
            }
        }

        // Cities
        async function getCitiesList() {
            if (selectedRegion !== "Dpto") {
                try {
                    const citiesResponse = await getCities(selectedRegion);
                    setCityList(citiesResponse);
                } catch (error) {
                    console.error(error.message);
                }
            }
        }

        // Currencies
        async function getCurrencyList() {
            if (selectedCurrencyType !== "Moneda") {
                try {
                    const currenciesResponse = await getCurrencyOptions();
                    setCurrencyList(currenciesResponse);
                } catch (error) {
                    console.error(error.message);
                }
            }
        }

        // Categories
        async function getCategoryList() {
            if (selectedCategory !== "Categoría") {
                try {
                    const categoriesResponse = await getCategoryOptions();
                    setCategoryList(categoriesResponse);
                } catch (error) {
                    console.error(error.message);
                }
            }
        }

        async function fetchData() {
            try {
                await Promise.all([
                    getCountryList(),
                    selectedCountry ? getStatesList() : Promise.resolve([]),
                    selectedRegion ? getCitiesList() : Promise.resolve([]),
                    selectedCurrencyType ? Promise.resolve([]) : getCurrencyList(),
                    selectedCategory ? Promise.resolve([]) : getCategoryList()
                ])
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();
    }, [selectedCountry, selectedRegion]);

    // Create job
    useEffect(() => {

        if (request.companyUuid) {
            async function createJobFunction() {
                setIsLoading(true);
                // Make request to backend
                await createJobRequest(request)
                    .then(() => {
                        setIsLoading(false);
                        handleClose();
                        setModalInformation({
                            title: 'Empleo creado correctamente',
                            content: `El empleo <strong>${request.title}</strong> ha sido creado correctamente. ¡Puedes buscar posibles aplicantes o esperar a que ellos apliquen a tu empleo!`,
                            button1Content: 'Esperar',
                            button1Redirect: `/my-jobs`,
                            button2Content: 'Buscar',
                            button2Redirect: `/talent-availability/${selectedCategory}`,
                        });
                        openModal();
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal();
                    })
            }
            createJobFunction()
        } else {
            return;
        }

    }, [request]);

    // Update job and jobs categories
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        if (jobInfo?.uuid && Object.keys(updateRequest).length !== 0) {
            async function updateJobFunction() {
                delete updateRequest.category;
                setIsLoading(true);
                // Make request to backend
                await updateJobRequest(jobInfo.uuid, updateRequest)
                .then(() => {
                        setIsLoading(false);
                        handleClose();
                        setModalInformation({
                            title: 'Empleo actualizado correctamente',
                            content: `El empleo <strong>${jobTitle}</strong> ha sido actualizado correctamente. ¡Puedes buscar posibles aplicantes o esperar a que ellos apliquen a tu empleo!`,
                            button1Content: 'Esperar',
                            button1Redirect: `/my-jobs`,
                            button2Content: 'Buscar',
                            button2Redirect: `/talent-availability/${request.categories}`,
                        });
                        openModal();
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.error(error)
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal();
                    })
            }

            async function updateJobCategoryFunction(categoryUuid) {
                delete updateRequest.category;
                // Make request to backend
                await updateJobCategoryRequest(jobInfo['job_category.id'], { category: categoryUuid })
                    .then(() => window.location.reload())
                    .catch(error => {
                        console.error(error)
                        setModalInformation(prevState => ({
                            ...prevState,
                            content: error.message
                        }));
                        openModal()
                    })
            }

            async function updateData() {
                try {
                    await Promise.all([
                        updateRequest.category && updateJobCategoryFunction(updateRequest.category),
                        updateJobFunction(),
                    ])
                } catch (error) {
                    console.error(error.message);
                }
            }
            updateJobFunction();
            updateData();
        } else {
            return;
        }

    }, [updateRequest]);

    // ========== Functions ============

    // Update states
    useEffect(() => {

        // --- Update job? --- //
        if (jobInfo) {
            // Basic information
            jobInfo.title && setProfileExists(true);
            jobInfo.title && setJobTitle(jobInfo.title);
            jobInfo.description && setJobDescription(jobInfo.description);
            jobInfo.requirements && setJobRequirements(jobInfo.requirements);

            // Location
            if (jobInfo.remote || jobInfo['city.id'] === null) {
                jobInfo.remote && setIsRemote(true);
            } else {
                jobInfo['city.id'] && setSelectedCity(jobInfo['city.id']);
                jobInfo['city.state.id'] && setSelectedRegion(jobInfo['city.state.id']);
                jobInfo['city.state.country.id'] && setSelectedCountry(jobInfo['city.state.country.id']);
                setIsRemote(false);
            }

            // Job type
            jobInfo.type && setSelectedJobType(jobInfo.type);
            jobInfo.startDate && setInitialDate(new Date(jobInfo.startDate + "T00:00:00"));
            jobInfo.finishDate && setFinalDate(new Date(jobInfo.finishDate + "T00:00:00"));

            // Wage
            if (jobInfo.wageFlag === 0) {
                setToggleWage(true);
            } else {
                setToggleWage(false);
                jobInfo.wageType && setSelectedWageFrequency(jobInfo.wageType);
                jobInfo.wageValue && setWageValue(jobInfo.wageValue);
                jobInfo.wage_currency_id && setSelectedCurrencyType(jobInfo.wage_currency_id);
            }

            jobInfo['job_category.category.uuid'] && setSelectedCategory(jobInfo['job_category.category.uuid']);
        } else {
            return;
        }

    }, [jobInfo]);

    // ----- Input changes ----- //
    const handleInputChange = (event) => {
        const inputName = event.target.name;
        const inputValue = event.target.value;

        switch (inputName) {
            case 'job-title':
                setTooltipJobTitle({ ...TooltipJobTitle, status: false });
                (!/[\]}>[<{]/.test(inputValue)) && setJobTitle(inputValue);
                break;
            case 'job-description':
                setTooltipJobDescription({ ...TooltipJobDescription, status: false });
                (!/[\]}>[<{]/.test(inputValue)) && setJobDescription(inputValue);
                break;
            case 'job-requirements':
                setTooltipJobRequirements({ ...TooltipJobRequirements, status: false });
                (!/[\]}>[<{]/.test(inputValue)) && setJobRequirements(inputValue);
                break;
            case 'wage-value':
                setTooltipWageValue({ ...TooltipWageValue, status: false });
                const numericValue = inputValue.replace(/\D/g, ''); // Delete all characters
                setWageValue(numericValue);
                break;
            default:
                break;
        }
    }

    // ----- Selectors handler ----- //
    const handleCountryChange = (event) => {
        setTooltipCountry({ ...TooltipCountry, status: false });
        setSelectedCountry(event.target.value);
    };

    const handleRegionChange = (event) => {
        setTooltipRegion({ ...TooltipRegion, status: false });
        setSelectedRegion(event.target.value);
    };

    const handleCityChange = (event) => {
        setTooltipCity({ ...TooltipCity, status: false });
        setSelectedCity(event.target.value);
    };

    const handleJobTypeChange = (event) => {
        setTooltipJobType({ ...TooltipJobType, status: false });
        setSelectedJobType(event.target.value);
    };

    const handleWageFrequencyChange = (event) => {
        setTooltipWageFrequency({ ...TooltipWageFrequency, status: false });
        setSelectedWageFrequency(event.target.value);
    };

    const handleCurrencyTypeChange = (event) => {
        setTooltipCurrencyType({ ...TooltipCurrencyType, status: false });
        setSelectedCurrencyType(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setTooltipCategory({ ...TooltipCategory, status: false });
        setSelectedCategory(event.target.value);
    };

    // ----- Form validation ----- //
    const formValidation = () => {

        let flag = true;

        // Job title
        if (!jobTitle && jobTitle === '') {
            setTooltipJobTitle({ ...TooltipJobTitle, status: true });
            flag = false;
        };

        // Job description
        if (!jobDescription && jobDescription === '') {
            setTooltipJobDescription({ ...TooltipJobDescription, status: true });
            flag = false;
        };

        // Job requirements
        if (!jobRequirements && jobRequirements === '') {
            setTooltipJobRequirements({ ...TooltipJobRequirements, status: true });
            flag = false;
        };

        // Location
        if (!isRemote) {
            // Country
            if (selectedCountry === '' || selectedCountry === 'País') {
                setTooltipCountry({ ...TooltipCountry, status: true });
                flag = false;
            };

            // Region
            if (selectedRegion === '' || selectedRegion === 'Depto') {
                setTooltipRegion({ ...TooltipRegion, status: true });
                flag = false;
            };

            // City
            if (selectedCity === '' || selectedCity === 'Ciudad') {
                setTooltipCity({ ...TooltipCity, status: true });
                flag = false;
            };
        }

        // Job type
        if (selectedJobType === '' && selectedJobType === 'Tipos') {
            setTooltipJobType({ ...TooltipJobType, status: true });
            flag = false;
        };

        // Wage
        if (!toggleWage) {
            // Frequency
            if (selectedWageFrequency === '' || selectedWageFrequency === 'Frecuencia') {
                setTooltipWageFrequency({ ...TooltipWageFrequency, status: true });
                flag = false;
            };

            // Currency type
            if (selectedCurrencyType === '' || selectedCurrencyType === 'Moneda') {
                setTooltipCurrencyType({ ...TooltipCurrencyType, status: true });
                flag = false;
            };

            // Wage value
            if (wageValue === '' || wageValue === '0') {
                setTooltipWageValue({ ...TooltipWageValue, status: true });
                flag = false;
            };
        };

        // Category
        if (selectedCategory === '' || selectedCategory === 'Categoría') {
            setTooltipCategory({ ...TooltipCategory, status: true });
            flag = false;
        };

        // Date
        if (selectedJobType) {
            if (initialDate) {
                if (finalDate && initialDate > finalDate) {
                    setTooltipDate({ ...TooltipDate, status: true });
                    flag = false;
                }
            } else {
                setTooltipDate({ ...TooltipDate, status: true });
                flag = false;
            }
        }

        return flag;
    };

    // ----- Handle job creation ----- //
    const handleCreateJob = (event) => {
        event.preventDefault();

        // --- Validate empty fields --- //
        const formValidationFlag = formValidation();

        if (formValidationFlag) {

            // Get user uuid
            const userInformation = JSON.parse(localStorage.getItem('userInformation'));
            const companyUuid = userInformation.user.uuid;

            // Build request object
            let request = {
                companyUuid,
                title: jobTitle,
                description: jobDescription,
                requirements: jobRequirements,
                remote: isRemote,
                type: selectedJobType,
                wageFlag: !toggleWage,
                categories: [selectedCategory]
            }

            // ----- Build request object ----- //

            // == Location == // 
            if (!isRemote) {
                request.cityId = selectedCity;
            }

            // == Dates == //
            if (initialDate) request.startDate = convertCompleteDateToDMY(initialDate);
            if (finalDate) request.finishDate = convertCompleteDateToDMY(finalDate);

            // == Wage == // 
            if (!toggleWage) {
                request.wageType = selectedWageFrequency;
                request.wageCurrencyId = selectedCurrencyType;
                request.wageValue = wageValue;
            }

            // Send request
            setRequest(request);
        }
    };

    // ----- Handle job update ----- //
    const handleUpdateJob = (event) => {
        event.preventDefault();

        // --- Validate empty fields --- //
        const formValidationFlag = formValidation();

        if (formValidationFlag) {


            // Build request object
            let request = {
                title: jobTitle,
                description: jobDescription,
                requirements: jobRequirements,
                remote: isRemote,
                type: selectedJobType,
                wageFlag: !toggleWage,
            }

            // ----- Fields validation ----- //

            // == Location == // 
            if (!isRemote) {
                request.cityId = selectedCity;
            }

            // == Dates == //
            if (initialDate < finalDate) request.startDate = convertCompleteDateToDMY(initialDate);
            if (finalDate > initialDate) request.finishDate = convertCompleteDateToDMY(finalDate);

            // == Wage == // 
            if (!toggleWage) {
                request.wageType = selectedWageFrequency;
                request.wageCurrencyId = selectedCurrencyType;
                request.wageValue = wageValue;
            }

            // Fix format
            // 0 => false && 1 => true
            jobInfo.remote == 0 ? jobInfo.remote = false : jobInfo.remote = true;
            jobInfo.wageFlag === 0 ? jobInfo.wageFlag = false : jobInfo.wageFlag = true;

            const objectToUpdate = compareObjects(jobInfo, request);

            if (selectedCategory && selectedCategory !== jobInfo['job_category.category.uuid']) {
                objectToUpdate.category = selectedCategory;
            }

            // Send request
            setUpdateRequest(objectToUpdate);
        }
    };

    const compareObjects = (obj1, obj2) => {
        let result = {};
        for (let prop in obj2) {
            if (!obj1.hasOwnProperty(prop) || obj1[prop] !== obj2[prop]) {
                result[prop] = obj2[prop];
            }
        }
        return result;
    }

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
    }

    return (
        <Fragment>

            {isLoading && <LoadingComponent />}

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {
                        jobInfo && jobInfo.uuid
                            ?
                            <Modal.Title className='modal-title'>Actualizar oferta de empleo</Modal.Title>
                            :
                            <Modal.Title className='modal-title'>Nueva oferta de empleo</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3 job-modal-inputs"
                        controlId="aboutYourself"
                        ref={form}
                    >
                        <div className="row">
                            {/* <!-- Job title --> */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name='job-title'
                                        onChange={handleInputChange}
                                        value={jobTitle}
                                        placeholder="Título del empleo*"
                                        required
                                    />
                                    <TooltipComponent
                                        attributeName={'job-title'}
                                        showTrigger={TooltipJobTitle.status}
                                        tooltipText={TooltipJobTitle.content}
                                    />
                                </div>
                            </div>

                            {/* <!-- Job description --> */}
                            <div className="form-floating col-lg-12 col-md-12 col-sm-12 col-12">
                                <textarea
                                    className="form-control"
                                    id="job-description"
                                    name='job-description'
                                    onChange={handleInputChange}
                                    value={jobDescription}
                                    style={{ height: '80px' }}
                                    required
                                >
                                </textarea>
                                <label
                                    style={{ paddingLeft: '1.25rem' }}
                                    htmlFor="job-description"
                                >
                                    Descripción del puesto*
                                </label>
                                <TooltipComponent
                                    attributeName={'job-description'}
                                    showTrigger={TooltipJobDescription.status}
                                    tooltipText={TooltipJobDescription.content}
                                />
                            </div>

                            {/* <!-- Job requirements --> */}
                            <div className="form-floating col-lg-12 col-md-12 col-sm-12 col-12  mt4">
                                <textarea
                                    className="form-control"
                                    id="job-requirements"
                                    name='job-requirements'
                                    onChange={handleInputChange}
                                    value={jobRequirements}
                                    style={{ height: '80px' }}
                                    required
                                >
                                </textarea>
                                <label style={{ paddingLeft: '1.25rem' }} htmlFor="job-requirements">
                                    Requerimientos del puesto*
                                </label>
                                <TooltipComponent
                                    attributeName={'job-requirements'}
                                    showTrigger={TooltipJobRequirements.status}
                                    tooltipText={TooltipJobRequirements.content}
                                />
                            </div>

                            {/* <!-- Location --> */}
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 mt4 border-top'>
                                <h4>Ubicación*</h4>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 mt4 border-top'>
                                <div className="form-check">
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="checkbox"
                                        id={1}
                                        name='remote'
                                        label='Remoto'
                                        value={isRemote}
                                        checked={isRemote}
                                        onChange={() => setIsRemote(!isRemote)}
                                        disabled={profileExists}
                                    />
                                </div>
                            </div>
                            {
                                !isRemote &&
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 mt4">
                                        <div className="form-group">
                                            <Form.Control
                                                as="select"
                                                name='country'
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                disabled={profileExists}
                                                className='form-control form-select'>
                                                <option>País</option>
                                                {countryList && countryList.map((country) => (
                                                    <option key={country.id} value={country.id}> {country.name} </option>
                                                ))}
                                            </Form.Control>
                                            <TooltipComponent
                                                attributeName={'country'}
                                                showTrigger={TooltipCountry.status}
                                                tooltipText={TooltipCountry.content}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 mt4">
                                        <div className="form-group">
                                            <Form.Control
                                                as="select"
                                                name='region'
                                                value={selectedRegion}
                                                onChange={handleRegionChange}
                                                disabled={profileExists}
                                                className='form-control form-select'>
                                                <option>Dpto</option>
                                                {stateList && stateList.map((region) => (
                                                    <option key={region.id} value={region.id}> {region.name} </option>
                                                ))}
                                            </Form.Control>
                                            <TooltipComponent
                                                attributeName={'region'}
                                                showTrigger={TooltipRegion.status}
                                                tooltipText={TooltipRegion.content}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 mt4">
                                        <div className="form-group">

                                            <Form.Control
                                                as="select"
                                                name='city'
                                                value={selectedCity}
                                                onChange={handleCityChange}
                                                disabled={profileExists}
                                                className='form-control form-select'>
                                                <option>Ciudad</option>
                                                {cityList && cityList.map((city) => (
                                                    <option key={city.id} value={city.id}> {city.name} </option>
                                                ))}
                                            </Form.Control>
                                            <TooltipComponent
                                                attributeName={'city'}
                                                showTrigger={TooltipCity.status}
                                                tooltipText={TooltipCity.content}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <!-- Type of job --> */}
                            <div className="col-lg-12 col-md-12 col-sm-12 border-top">
                                <h4>Tipo de empleo*</h4>
                                <div className="form-group">
                                    <Form.Control
                                        as="select"
                                        name='employmentType'
                                        value={selectedJobType}
                                        onChange={handleJobTypeChange}
                                        className='form-control form-select'>
                                        <option>Tipos</option>
                                        {employmentTypesList && employmentTypesList.map((type) => (
                                            <option key={type.id} value={type.id}> {type.name} </option>
                                        ))}
                                    </Form.Control>
                                    <TooltipComponent
                                        attributeName={'employmentType'}
                                        showTrigger={TooltipJobType.status}
                                        tooltipText={TooltipJobType.content}
                                    />
                                </div>
                            </div>
                            {/* Dates */}
                            {
                                selectedJobType && selectedJobType !== "Tipos" &&
                                <div className="row">
                                    <div name='initialDate' className="col-lg-6 col-md-6 col-sm-6 mb4">
                                        <MyDatePicker
                                            selectedDate={initialDate}
                                            setSelectedDate={setInitialDate}
                                            placeholder={'Fecha inicial*'}
                                            minDate={today}
                                        />
                                    </div>
                                    <TooltipComponent
                                        attributeName={'initialDate'}
                                        showTrigger={TooltipDate.status}
                                        tooltipText={TooltipDate.content}
                                    />
                                    <div className="col-lg-6 col-md-6 col-sm-6 mb4">
                                        <MyDatePicker
                                            selectedDate={finalDate}
                                            setSelectedDate={setFinalDate}
                                            placeholder={'Fecha final'}
                                            minDate={today}
                                        />
                                    </div>
                                </div>
                            }


                            {/* <!-- Wage --> */}
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 border-top'>
                                <h4>Pago</h4>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 border-top'>
                                <div className="form-check">
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="checkbox"
                                        id={1}
                                        name='wageFlag'
                                        label='No incluir'
                                        value={toggleWage}
                                        checked={toggleWage}
                                        onChange={() => setToggleWage(!toggleWage)}
                                    />
                                </div>
                            </div>
                            {
                                !toggleWage &&
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                            <Form.Control
                                                as="select"
                                                name='wageFrequency'
                                                value={selectedWageFrequency}
                                                onChange={handleWageFrequencyChange}
                                                className='form-control form-select'>
                                                <option>Frecuencia</option>
                                                {wageFrequencyList && wageFrequencyList.map((wageFrequency) => (
                                                    <option key={wageFrequency.id} value={wageFrequency.id}> {wageFrequency.name} </option>
                                                ))}
                                            </Form.Control>
                                            <TooltipComponent
                                                attributeName={'wageFrequency'}
                                                showTrigger={TooltipWageFrequency.status}
                                                tooltipText={TooltipWageFrequency.content}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                            <Form.Control
                                                as="select"
                                                name='currencyType'
                                                value={selectedCurrencyType}
                                                onChange={handleCurrencyTypeChange}
                                                className='form-control form-select'>
                                                <option>Moneda</option>
                                                {currencyList && currencyList.map((currency) => (
                                                    <option key={currency.id} value={currency.id}> {currency.abbreviation} </option>
                                                ))}
                                            </Form.Control>
                                            <TooltipComponent
                                                attributeName={'currencyType'}
                                                showTrigger={TooltipCurrencyType.status}
                                                tooltipText={TooltipCurrencyType.content}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name='wage-value'
                                                onChange={handleInputChange}
                                                value={Number(wageValue).toLocaleString('es-CO')}
                                                placeholder="Valor"
                                            />
                                            <TooltipComponent
                                                attributeName={'wage-value'}
                                                showTrigger={TooltipWageValue.status}
                                                tooltipText={TooltipWageValue.content}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 border-top">
                                <h4>Categoría*</h4>
                                <div className="form-group">
                                    <Form.Control
                                        as="select"
                                        name='category'
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                        className='form-control form-select'>
                                        <option>Categoría</option>
                                        {categoryList && categoryList.map((category) => (
                                            category.uuid !== 'e207272d-d465-4327-a80c-b76440cf7e03'
                                                ?
                                                <option key={category.uuid} value={category.uuid}>
                                                    {category.name}
                                                </option>
                                                :
                                                ''
                                        ))}
                                    </Form.Control>
                                    <TooltipComponent
                                        attributeName={'category'}
                                        showTrigger={TooltipCategory.status}
                                        tooltipText={TooltipCategory.content}
                                    />
                                </div>
                            </div>

                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-cancel' variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    {
                        jobInfo && jobInfo.uuid
                            ?
                            <Button
                                className='btn-theme'
                                variant="primary"
                                onClick={(event) => handleUpdateJob(event)}
                            >
                                Actualizar
                            </Button>
                            :
                            <Button
                                className='btn-theme'
                                variant="primary"
                                onClick={(event) => handleCreateJob(event)}
                            >
                                Crear
                            </Button>

                    }
                </Modal.Footer>
            </Modal>
        </Fragment >
    )
})

export default JobModal