import React, { useState, useRef, useEffect, useCallback } from 'react';

import {
  TextField,
  MenuItem,
} from '@mui/material';

// Custom hooks
import useS3Request from '../../hooks/useS3Request';
import useOptions from '../../hooks/backend/useSelectorOptions';
import useTalent from '../../hooks/backend/useTalent';
import useAnalytics from '../../hooks/useAnalytics';

// Components
import ModalComponent from '../ModalComponent';

import '../../styles/components/auth.scss';

const validateInput = (type, value) => {
  switch (type) {
    case 'text':
      return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
    case 'number':
      return (/^[0-9]*$/.test(value));
    case 'email':
      return (!/[\])}>[<{(]/.test(value));
    default:
      break;
  }
};

function RegisterStep3User(props) {

  // Custom hooks
  const { uploadFile } = useS3Request();
  const { updateTalentProfile } = useTalent();
  const { getCountries, getStates, getCities } = useOptions();
  const signUpAnalyticsTracker = useAnalytics('Sign up');

  // References
  const modalRef = useRef();
  const form = useRef(null);

  // ========== States ============
  const [userInformation, setUserInformation] = useState(props.userInformation);
  // Modal
  const [modalInformation, setModalInformation] = useState({
    title: 'Error en la carga de archivo',
    content: 'El contenido cargado no es una imagen',
    button2Content: 'Volver'
  });

  // errors
  const [errorEmail, setErrorEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorUsername, setErrorUsername] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorPhoneNumber, setErrorPhoneNumber] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorAge, setErrorAge] = useState({ status: false, content: 'Las contraseñas no coinciden' });
  const [errorAboutUser, setErrorAboutUser] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorCountry, setErrorCountry] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorRegion, setErrorRegion] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorCity, setErrorCity] = useState({ status: false, content: 'Este campo debe estar seleccionado' });

  // Input content
  const [email, setEmail] = useState(userInformation.email);
  // const [username, setUserName] = useState(userInformation.username);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [age, setAge] = useState('');
  const [aboutUser, setAboutUser] = useState('');

  const [profileImageFile, setProfileImageFile] = useState('');
  const [cvFile, setCVFile] = useState('');
  const [cvUrl, setCVUrl] = useState('');
  const [isInitialMount, setIsInitialMount] = useState(true);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [talentRequest, setTalentRequest] = useState({});


  const updateTalentProfileFunction = useCallback(async () => {
    try {
      const response = await updateTalentProfile(talentRequest);
      signUpAnalyticsTracker({ action: 'talent_step_3_completed', label: userInformation.uuid });
      window.location.href = 'jobs';
    } catch (error) {
      console.error(error);
    }
  }, [talentRequest, updateTalentProfile, signUpAnalyticsTracker, userInformation.uuid]);


  // ======= Request to backend ===========
  useEffect(() => {

    // Countries
    async function getCountryList() {
      try {
        const countryResponse = await getCountries();
        setCountryList(countryResponse);
      } catch (error) {
        console.error(error.message);
      }
    };

    // States
    async function getStatesList() {
      if (selectedCountry !== "País") {
        try {
          const statesResponse = await getStates(selectedCountry);
          setStateList(statesResponse);
        } catch (error) {
          setStateList([]);
          console.error(error.message);
        }
      }
    }

    // Cities
    async function getCitiesList() {
      if (selectedRegion !== "Departamento") {
        try {
          const citiesResponse = await getCities(selectedRegion);
          setCityList(citiesResponse);
        } catch (error) {
          console.error(error.message);
        }
      }
    }

    async function fetchData() {
      try {
        await Promise.all([
          getCountryList(),
          selectedCountry ? getStatesList() : Promise.resolve([]),
          selectedRegion ? getCitiesList() : Promise.resolve([]),
        ]);
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchData();
  }, [selectedCountry, selectedRegion]);

  // ---- Fill data ---- //
  useEffect(() => {
    if (props.cvTalentInformation && Object.keys(props.cvTalentInformation).length !== 0) {
      const cvInfo = props.cvTalentInformation;
      cvInfo.phoneNumber && setPhoneNumber(cvInfo.phoneNumber);
      cvInfo.about && setAboutUser(cvInfo.about);
      cvInfo.cvUrl && setCVUrl(cvInfo.cvUrl);
    }
  }, [props.cvTalentInformation]);

  // Update talent info
  // useEffect(() => {

  //   if (isInitialMount) {
  //     setIsInitialMount(false);
  //     return;
  //   }

  //   if (talentRequest.email) {
  //     async function updateTalentProfileFunction() {
  //       await updateTalentProfile(talentRequest)
  //         .then(response => {
  //           signUpAnalyticsTracker({ action: 'talent_step_3_completed', label: userInformation.uuid });
  //           window.location.href = 'jobs';
  //         })
  //         .catch(console.error);
  //     }

  //     async function updateData() {
  //       try {
  //         await updateJobFunction();
  //       } catch (error) {
  //         console.error(error.message);
  //       }
  //     }
  //     updateData();
  //   }

  // }, [talentRequest]);

  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
      return;
    }

    if (talentRequest.email) {
      updateTalentProfileFunction();
    }
  }, [talentRequest]);

  // ========== Functions ============
  const handleCountryChange = (event) => {
    setErrorCountry({ ...errorCountry, status: false });
    setSelectedCountry(event.target.value);
  };

  const handleRegionChange = (event) => {
    setErrorRegion({ ...errorRegion, status: false });
    setSelectedRegion(event.target.value);
  };

  const handleCityChange = (event) => {
    setErrorCity({ ...errorCity, status: false });
    setSelectedCity(event.target.value);
  };

  const handleFileChange = async (event) => {
    const eventId = event.target.id;
    const file = event.target.files[0];

    if (eventId === 'cv') {
      if (/\bpdf\b/i.test(file.type)) {
        setCVFile(file);
      } else {
        setModalInformation({
          ...modalInformation,
          content: `El archivo ${file.name} no es admitido. Sólo admitimos formatos en PDF`
        });
        openModal();
      }
    } else if (eventId === 'profile-photo') {
      if (/\bimage\b/i.test(file.type)) {
        setProfileImageFile(file);
      } else {
        setModalInformation({
          ...modalInformation,
          content: `El archivo ${file.name} no es una imagen`
        });
        openModal();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const data = {
      email: formData.get('email'),
      username: formData.get('username'),
      country: formData.get('country'),
      region: formData.get('region'),
      city: formData.get('city'),
      phoneNumber: formData.get('phoneNumber'),
      age: Number(formData.get('age')),
      aboutUser: formData.get('aboutUser'),
      profileImageUrl: '',
      cvUrl: '',
    };

    const validateForm = formValidation(data, formData);

    if (validateForm) {
      // Make the petition to the backend
      const objectToSend = {
        email: data.email,
        age: data.age,
        // username: data.username,
        description: data.aboutUser,
        cityId: data.city,
        phoneNumber: data.phoneNumber,
        profileCompletedFlag: true
      }

      // Save in S3
      if (!profileImageFile) {
        objectToSend.profileImageUrl = "https://staffing-app.s3.amazonaws.com/profile-images/avatar.png";
      } else {
        await uploadFile(profileImageFile, 'profile-images')
          .then((response) => objectToSend.profileImageUrl = response)
          .catch(console.error);
      }
      cvFile && await uploadFile(cvFile, 'cv')
        .then((response) => objectToSend.cvUrl = response)
        .catch(console.error);

      if (cvUrl) objectToSend.cvUrl = cvUrl;

      setTalentRequest(objectToSend);
    }
  };

  const formValidation = (data) => {

    let flag = true;

    // Email
    if (!data.email && data.email === '') {
      setErrorEmail({ ...errorEmail, status: true });
      flag = false;
    };

    // Username
    if (!data.username && data.username === '') {
      setErrorUsername({ ...errorUsername, status: true });
      flag = false;
    };

    // Country
    if (data.country === 'País' || data.country === '') {
      setErrorCountry({ ...errorCountry, status: true });
      flag = false;
    };

    // Region
    if (data.region === 'Departamento' || data.region === '') {
      setErrorRegion({ ...errorRegion, status: true });
      flag = false;
    };

    // City
    if (data.city === 'Ciudad' || data.city === '') {
      setErrorCity({ ...errorCity, status: true });
      flag = false;
    };

    // AboutUser
    if (!data.aboutUser && data.aboutUser === '') {
      setErrorAboutUser({ ...errorAboutUser, status: true });
      flag = false;
    };

    // Age
    if (!data.age && data.age === '') {
      setErrorAge({ ...errorAge, status: true });
      flag = false;
    } else if (data.age <= 17) {
      setErrorAge({ content: 'Debes ser mayor de edad para registrarte en nuestra plataforma', status: true });
      flag = false;
    };

    // Phone number
    if (!data.phoneNumber && data.phoneNumber === '') {
      setErrorPhoneNumber({ ...errorPhoneNumber, status: true });
      flag = false;
    };

    return flag;
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'email':
        setErrorEmail({ ...errorEmail, status: false });
        validateInput('email', inputValue) && setEmail(inputValue);
        break;
      // case 'username':
      //   setErrorUsername({ ...errorUsername, status: false });
      //   validateInput('email', inputValue) && setUserName(inputValue);
      //   break;
      case 'phoneNumber':
        setErrorPhoneNumber({ ...errorPhoneNumber, status: false });
        validateInput('number', inputValue) && setPhoneNumber(inputValue);
        break;
      case 'age':
        setErrorAge({ ...errorAge, status: false });
        validateInput('number', inputValue) && setAge(inputValue);
        break;
      case 'aboutUser':
        setErrorAboutUser({ ...errorAboutUser, status: false });
        validateInput('email', inputValue) && setAboutUser(inputValue);
        break;
      default:
        break;
    }
  }

  // Modal functions
  const openModal = () => modalRef.current.handleShow();
  const closeModal = () => modalRef.current.handleClose();

  return (
    <section id="login-area" className="py40">
      <ModalComponent
        ref={modalRef}
        title={modalInformation.title}
        body={modalInformation.content}
        handleClose={closeModal}
        textButton2={modalInformation.button2Content}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-area">
              <div className="login-form-area">
                <div className="form-area-heading text-center">
                  {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end">
                    
                  </div> */}
                  <h3>¡Completa tu registro!</h3>
                  <p>Un último paso y quedas listo.</p>
                </div>
                <form action="#" className="form-floating" ref={form}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          disabled
                          id="fullName"
                          size="small"
                          name="fullName"
                          label="Nombre completo"
                          autoComplete='given-name'
                          variant="outlined"
                          value={userInformation.fullName}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="email"
                          size="small"
                          name="email"
                          label="Correo electrónico"
                          autoComplete='email'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={email}
                          helperText={errorEmail.status && errorEmail.content}
                          error={errorEmail.status}
                        />
                      </div>
                    </div>

                    {/* Country */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          select
                          id="country"
                          name='country'
                          size="small"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          label='País'
                          helperText={errorCountry.status && errorCountry.content}
                          error={errorCountry.status}
                        >
                          <MenuItem value="">
                            <em>País</em>
                          </MenuItem>
                          {countryList.map((country) => (
                            <MenuItem key={country.id} value={country.id}> {country.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    {/* Region */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          select
                          id="region"
                          name='region'
                          size="small"
                          value={selectedRegion}
                          onChange={handleRegionChange}
                          label='Dpto'
                          helperText={errorRegion.status && errorRegion.content}
                          error={errorRegion.status}
                        >
                          <MenuItem value="">
                            <em>Departamento</em>
                          </MenuItem>
                          {stateList.map((region) => (
                            <MenuItem key={region.id} value={region.id}> {region.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    {/* City */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          select
                          id="city"
                          name='city'
                          size="small"
                          value={selectedCity}
                          onChange={handleCityChange}
                          label='Ciudad'
                          helperText={errorCity.status && errorCity.content}
                          error={errorCity.status}
                        >
                          <MenuItem value="">
                            <em>Ciudad</em>
                          </MenuItem>
                          {cityList.map((city) => (
                            <MenuItem key={city.id} value={city.id}> {city.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="phoneNumber"
                          size="small"
                          name="phoneNumber"
                          label="Número de teléfono"
                          autoComplete='phoneNumber'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={phoneNumber}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          helperText={errorPhoneNumber.status && errorPhoneNumber.content}
                          error={errorPhoneNumber.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <TextField
                          fullWidth
                          id="age"
                          size="small"
                          name="age"
                          label="Edad"
                          autoComplete='age'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={age}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          helperText={errorAge.status && errorAge.content}
                          error={errorAge.status}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <TextField
                        fullWidth
                        multiline
                        id="about-user"
                        size="small"
                        name="aboutUser"
                        label="Descripción*"
                        placeholder='Acá puedes escribir sobre ti: quién eres, tus principales logros y qué te gusta hacer'
                        variant="outlined"
                        onChange={handleInputChange}
                        value={aboutUser}
                        rows={2}
                        // maxRows={Infinity}
                        helperText={errorAboutUser.status && errorAboutUser.content}
                        error={errorAboutUser.status}
                      />
                    </div>

                    {
                      !cvUrl &&
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt4">
                        <label htmlFor="cv" className="form-label" style={{ 'marginBottom': '0.12rem' }}>
                          Hoja de vida
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="cv"
                          onChange={handleFileChange}
                        />

                      </div>
                    }
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt4">
                      <label htmlFor="profile-photo" className="form-label" style={{ 'marginBottom': '0.12rem' }}>
                        Foto de perfil
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="profile-photo"
                        name='profile-photo'
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="update-profile-button">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          onClick={handleSubmit}
                        >
                          Actualizar perfil
                        </button>
                      </div>
                      <div className="omit-button-container">
                        <a
                          href="/jobs"
                          className="btn"
                          onClick={() => signUpAnalyticsTracker({ action: 'talent_step_3_omitted', label: userInformation.uuid })}
                        >
                          Omitir este paso
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterStep3User