import React from 'react';
import Form from 'react-bootstrap/Form';

// ----- Components ----- //
import HourSelector from '../TimeSelector';
import TooltipComponent from '../TooltipComponent';

function TimePreferencesModalSection(props) {

    const { title, day, setDay, TooltipStatus } = props;

    return (
        <div className='row mb-2'>
            <div className='col-lg-6 col-md-6 col-sm-6 col-6 border-top'>
                <h5> <strong>{title}</strong></h5>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-6 col-6 border-top'>
                <div className="form-check">
                    <Form.Check
                        style={{ paddingLeft: '0.1em' }}
                        type="checkbox"
                        id={1}
                        name={`${day.day}-flag`}
                        label='Todo el día'
                        value={day.allDayFlag}
                        checked={day.allDayFlag}
                        onChange={() => setDay({ ...day, allDayFlag: !day.allDayFlag })}
                    />
                </div>
            </div>
            {
                !day.allDayFlag &&
                <>

                    {
                        day.availability &&
                        <div className="row">
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                <HourSelector
                                    date={day}
                                    setDay={setDay}
                                    startHourFlag={true}
                                    label={"Hora inicial"}
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                <HourSelector
                                    date={day}
                                    setDay={setDay}
                                    startHourFlag={false}
                                    label={"Hora final"}
                                    name={`${day.day}-tooltip`}

                                />
                            </div>
                            <TooltipComponent
                                attributeName={`${day.day}-tooltip`}
                                showTrigger={TooltipStatus.status}
                                tooltipText={TooltipStatus.content}
                            />
                        </div>
                    }
                    <div className='row'>
                        <div className="form-check center-section" style={{ marginTop: '8px' }}>
                            <Form.Check
                                style={{ paddingLeft: '0.1em' }}
                                type="checkbox"
                                id={1}
                                name={`${day.day}-availability`}
                                label='No disponible'
                                value={!day.availability}
                                checked={!day.availability}
                                onChange={() => setDay({ ...day, availability: !day.availability })}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default TimePreferencesModalSection