import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputFileUpload from './InputFileUpload';

import '../styles/components/modal.scss';

const ModalComponent = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const {
        handleClose,
        title,
        body,
        redirectButton1,
        redirectButton2,
        redirectButton3,
        textButton1,
        textButton2,
        textButton3,
        // Input file
        showInputFile,
        handleInputChange
    } = props;

    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='modal-title'>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body><div dangerouslySetInnerHTML={{ __html: body }} /></Modal.Body>
                {showInputFile && <InputFileUpload handleInputChange={handleInputChange} />}
                <Modal.Footer>
                    {
                        textButton1 &&
                        <Button className='btn-cancel modal-buttons' variant="secondary" onClick={() => {
                            handleClose();
                            redirectButton1 && redirectButton1()
                        }}>
                            {textButton1}
                        </Button>
                    }{textButton3 &&
                        <Button className='btn-go-to-jobs modal-buttons' variant="secondary" onClick={() => {
                            handleClose();
                            redirectButton3 && redirectButton3()
                        }}>
                            {textButton3}
                        </Button>
                    }
                    <Button className='btn-theme modal-buttons' variant="primary" onClick={() => {
                        handleClose()
                        redirectButton2 && redirectButton2()
                    }}>
                        {textButton2}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default ModalComponent;