import React, { Fragment } from 'react';

function TalentAvailabilityHeader(props) {

    const { jobList, handleJobClick, selectedJob, openJobModal, handleCreateJob } = props;

    return (
        <Fragment>
            <div className="talent-availability-header-container col-lg-10 col-md-10 col-sm-10 col-10">
                <div className="row talent-availability-header-row">
                    {
                        jobList && jobList
                            .sort((job1, job2) => {
                                if (selectedJob === job1.uuid) return -1;
                                if (selectedJob === job2.uuid) return 1;
                                return 0;
                            })
                            .map(job => {
                                return (
                                    <div
                                        className="col"
                                        key={job.uuid}
                                        onClick={() => handleJobClick(job.uuid, job['job_category.category.uuid'])}
                                        id={job.uuid}
                                    >
                                        <div
                                            className={`talent-availability-header-job-filter box-shadow ${selectedJob === job.uuid && 'talent-availability-header-job-selected'}`}
                                        >
                                            <strong>{job.title}</strong>
                                            <p> {job['job_category.category.name']} - {job.applicantsNumber} apli.</p>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2 col-2 justify-content-end align-center'>
                <button
                    className="btn btn-theme btn-talent-availability"
                    onClick={event => handleCreateJob(event)}
                >
                    <i className="fas fa-solid fa-plus" />
                </button>
            </div>
        </Fragment>
    )
}

export default TalentAvailabilityHeader