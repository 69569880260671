import React from 'react';

import '../styles/components/filter-button.scss';

function FilterButtonComponent({ setToggleLateralFilters }) {
    return (
        <button
            className="filter-fixed-button"
            onClick={(event) => {
                event.preventDefault()
                setToggleLateralFilters(true);
            }}
        >
            <div
                className="roundedFixedBtn"
            >
                <i className="fa-solid fa-filter"></i></div>
        </button>
    )
}

export default FilterButtonComponent;