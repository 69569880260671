import React, { Fragment } from 'react';

import CompanyProfile from './company/CompanyProfile';
import TalentProfile from './talent/TalentProfile';

import '../styles/pages/my-profile.scss';

function MyProfile() {

    return (
        <Fragment>
            {
                JSON.parse(localStorage.getItem('userInformation')).role === 'company'
                    ?
                    <CompanyProfile />
                    :
                    <TalentProfile />
            }
        </Fragment>
    )
}

export default MyProfile