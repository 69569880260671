import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useEmailsRequests from '../../hooks/backend/useEmails';

function TalentAvailabilityCart(props) {

    const {
        talentUuid,
        image,
        name,
        description,
        location,
        handleVisitProfile,
        handleRequestContact
    } = props

    const navigate = useNavigate();

    // ========== States ============
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [request, setRequest] = useState({});

    // ========== Custom hooks ============
    const { sendProfileViewedEmailRequest } = useEmailsRequests();

    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function sendProfileViewedEmailFunction() {
            if (request.id) {

                await sendProfileViewedEmailRequest(request)
                    .then(() => {
                        navigate('/my-profile');
                    })
                    .catch(console.error())
            }
        }

        sendProfileViewedEmailFunction();
    }, [request]);

    // ========== Functions ==========  //


    return (
        <Fragment>
            <div className="applicant-items box-shadow">
                <div className="applicant-imgs ">
                    { image ?
                        <a href="">
                            <img src={image} alt="cover" />
                        </a>
                        :
                        <img
                            src="https://staffing-app.s3.amazonaws.com/profile-images/avatar.png"
                            alt="Profile Picture Default"
                            style={{ borderRadius: '10px 10px 0 0',
                                maxHeight: '250px',
                                width: '100%',
                                objectFit: 'cover' }}
                        />
                    }
                </div>
                <div className="applicant-info" style={{ 'paddingTop': '20px' }}>
                    <div className="meta-area talent-availability-location">
                        <ul>
                            <li><i style={{ marginRight: '2px' }} className="fa-solid fa-location-dot" />
                            </li>
                            <li>
                                <p>{location}</p>
                            </li>
                        </ul>
                    </div>
                    <h4>{name}</h4>
                    <p className='applicant-info-description'>
                        {
                            description ?
                                window.innerWidth > 1500 ?
                                    `${description.slice(0, 200)}...`
                                    :
                                    window.innerWidth < 900 ?
                                        `${description.slice(0, 100)}...`
                                        :
                                        description
                                :
                                ''
                        }
                    </p>
                    <div className='applicant-info-buttons'>
                        <a
                            href=""
                            onClick={(event) => handleVisitProfile(event, talentUuid)}
                            className="btn btn-theme btn-card mt4"
                        >
                            Ver perfil
                        </a>
                        <a
                            href=""
                            onClick={(event) => handleRequestContact(event, talentUuid)}
                            className="btn btn-theme btn-card mt4"
                        >
                            Contactar
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentAvailabilityCart