import React, { useContext, useEffect } from 'react';

import AppContext from '../../context/AppContext';

import useAnalytics from '../../hooks/useAnalytics';

import '../../styles/components/auth.scss';

const applicantImage = 'https://staffing-app.s3.amazonaws.com/profile-images/register-applicant.jpg';
const companyImage = 'https://staffing-app.s3.amazonaws.com/profile-images/register-company.jpg';

function RegisterStep1({ setUserRole }) {
    const { changeHeaderLayout } = useContext(AppContext);

    const signUpAnalyticsTracker = useAnalytics('Sign up');

    useEffect(() => {
        changeHeaderLayout(true);
    }, []);

    return (
        <section id="register-area">
            <div className="container register-area-container">
                <h2 style={{ 'textAlign': 'center' }}>¿Cómo podemos ayudarte hoy?</h2>
                <div className="choose-user">
                    <a
                        className="talent"
                        onClick={() => {
                            setUserRole('talent');
                            signUpAnalyticsTracker({ action: 'talent_clicks', label: 'Clicks on I am a talent' });
                        }}
                    >
                        <img src={applicantImage} alt="Talent" />
                        <div
                            className="user-text"
                        >
                            <h3>Soy talento</h3>
                            <p>Busco nuevos desafíos</p>
                        </div>
                    </a>
                    <a
                        className="company"
                        onClick={() => {
                            setUserRole('company');
                            signUpAnalyticsTracker({ action: 'company_clicks', label: 'Clicks on I am a company' });
                        }}
                    >
                        <img src={companyImage} alt="Company" />
                        <div
                            className="user-text"
                        >
                            <h3>Busco talento</h3>
                            <p>Busco talento disponible</p>
                        </div>
                    </a>
                </div>
                <div className="already-register">
                    <p>¿Ya tienes una cuenta?</p>
                    <a href="/login">Ingresa</a>
                </div>
            </div>
        </section>
    )
}

export default RegisterStep1;