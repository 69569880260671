import { Overlay, Tooltip } from 'react-bootstrap';

import React from 'react'

function TooltipComponent({ attributeName, showTrigger, placement, tooltipText }) {
    return (
        <Overlay
            target={() => document.querySelector(`[name=${attributeName}]`)}
            show={showTrigger}
            placement={placement || "top"}
            delay={{ show: 0, hide: 400 }}
        >
            <Tooltip >{tooltipText}</Tooltip>
        </Overlay>
    )
}

export default TooltipComponent