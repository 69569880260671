import api from '../../libs/API'

let isRefreshing = false;
let tokenVar = '';
let counter = 0;

const tokenExpiration = (token) => {
    if (token) {
        try {
            const decodedJwt = JSON.parse(atob(token.split('.')[1]));

            // Token expiration 
            if (decodedJwt.exp * 1000 < Date.now()) {
                tokenVar = '';
                return null; // Token expired
            } else {
                return true;
            }
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
}


const refreshFunction = async () => {

    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    // Get params
    const refresh = localStorage.getItem('refreshToken');
    const userInformation = JSON.parse(localStorage.getItem('userInformation'));
    if (counter > 30) {
        throw new Error("Error");
    }
    console.log(`Is Refreshing ${counter}`, isRefreshing);
    counter++

    if (!tokenExpiration(tokenVar)) {
        if (tokenVar === refresh) {
            return tokenVar;
        } else {
            try {
                const uuid = userInformation.user.uuid;
                headers.Authorization = `Bearer ${refresh}`;

                if (isRefreshing) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    return refreshFunction();
                } else {
                    isRefreshing = true;
                    const response = await api.post('/auth/refresh', { uuid }, { headers });

                    const { accessToken, refreshToken } = response.data;
                    console.log("Refresh: ", { accessToken, refreshToken })

                    // Save in local storage
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    isRefreshing = false;
                    tokenVar = refreshToken;
                    return true;
                }

            } catch (error) {
                console.error(error.response.data.message);
                if (error.response.data.message === 'Expired' || error.response.data.message === 'Refresh token not found') {
                    localStorage.clear();
                    window.location.reload();
                }
                throw new Error(error.response.data.message);
            };
        }

    } else {
        return true;
    }
};

export default refreshFunction;