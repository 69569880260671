import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function MyDatePicker(props) {
    const { selectedDate, setSelectedDate, placeholder, minDate } = props;

    return (
        <DatePicker
            className="form-control"
            style={{ maxWidth: '250px' }}
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            placeholderText={placeholder}
            minDate={minDate}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        />
    );
}

export default MyDatePicker;
