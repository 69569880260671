import api from '../../libs/API'
import useAuthentication from './useAuth';

const useEducationRequests = () => {

    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Get user education records
    const getUserEducationRequest = async (userUuid, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.get(`/education/user/${userUuid}`, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await getUserEducationRequest(userUuid, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create a education record
    const createEducationRecord = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/education', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createEducationRecord(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Create a education record
    const createBatchEducationRecords = async (data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.post('/education/batch', data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await createBatchEducationRecords(data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update a education record
    const updateEducationRecord = async (educationRecordId, data, alreadyCalled) => {

        // Add token to headers
        headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

        try {
            const response = await api.patch(`/education/${educationRecordId}`, data, { headers });
            return response.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        await refreshToken();
                        return await updateEducationRecord(educationRecordId, data, true);
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    const getEducationType = {
        school: 'Escolar',
        university: 'Universitaria',
        master: 'Maestría',
        doctorate: 'Doctorado',
        diploma: 'Diplomado',
        course: 'Curso',
        certification: 'Certificación',
        seminar: 'Seminario',
        workshop: 'Taller',
        bootcamp: 'Bootcamp',
        selfTaught: 'Autodidacta',
        technicalTraining: 'Formación técnica',
        exchangeProgram: 'Intercambio',
        other: 'Otro'
    };

    const educationTypesList = [
        {
            id: 'school',
            name: 'Escolar'
        },
        {
            id: 'university',
            name: 'Universitaria'
        },
        {
            id: 'masters',
            name: 'Maestría'
        },
        {
            id: 'doctorate',
            name: 'Doctorado'
        },
        {
            id: 'diploma',
            name: 'Diplomado'
        },
        {
            id: 'course',
            name: 'Curso'
        },
        {
            id: 'certification',
            name: 'Certificación'
        },
        {
            id: 'seminar',
            name: 'Seminario'
        },
        {
            id: 'workshop',
            name: 'Taller'
        },
        {
            id: 'bootcamp',
            name: 'Bootcamp'
        },
        {
            id: 'selfTaught',
            name: 'Autodidacta'
        },
        {
            id: 'technicalTraining',
            name: 'Formación técnica'
        },
        {
            id: 'exchangeProgram',
            name: 'Programa de intercambio'
        },
        {
            id: 'other',
            name: 'Otro'
        }
    ];



    return { getUserEducationRequest, createEducationRecord, createBatchEducationRecords, updateEducationRecord, getEducationType, educationTypesList }

};

export default useEducationRequests;