import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';


function FreeJobsFilter(props) {

    const { searchValue, setSearchValue, categoryRef, categoryList, categoryValue, setCategoryValue, citiesList, setCityValue, setRemoteValue } = props;

    return (
        <Fragment>
            {/* Search by job title start */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Título del empleo</h4>
                    <form action="#" className="mt4">
                        <div className="blog-search-option">
                            <input
                                type="text"
                                placeholder="Título..."
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <button className="button" type="submit"> <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Search by job title end */}

            {/* Categories */}
            <div className="left-blog-page box-shadow">
                <Form
                    className="left-blog"
                    ref={categoryRef}
                >
                    <h4>Categorías</h4>
                    {
                        categoryList && categoryList.map(category => {
                            return (
                                <div
                                    className="form-check mt4"
                                    key={category.uuid}
                                >
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="radio"
                                        id={category.uuid}
                                        name={`category-${category.name}`}
                                        label={category.name}
                                        value={category.uuid}
                                        checked={categoryValue === category.uuid}
                                        onChange={event => {
                                            setCategoryValue(event.target.value)
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </Form>
            </div>

            {/* Location */}
            <div className="left-blog-page box-shadow">
                <div className="left-tags blog-tags">
                    <div className="popular-tag left-side-tags left-blog">
                        <h4>Ciudades</h4>
                        <ul>
                            {citiesList &&
                                citiesList.map((city) => {
                                    if (city.id !== null && city.remote !== 1) {
                                        return (
                                            <li
                                                key={city.id}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setCityValue(event.target.id);
                                                    setRemoteValue(0);
                                                }}
                                            >
                                                <a id={city.id} href="">{city.name}</a>
                                            </li>
                                        );
                                    }
                                    return;
                                })}
                            {citiesList &&
                                citiesList.map((city) => {
                                    if (city.id === null && city.remote === 1) {
                                        return (
                                            <li
                                                key={city.remote}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setRemoteValue(city.remote);
                                                }}
                                            >
                                                <a id={city.remote} href="">Remoto</a>
                                            </li>
                                        );
                                    }
                                    return;
                                })}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FreeJobsFilter;